import {
    Checkbox,
    DefaultButton,
    IBasePickerStyles,
    IStackTokens,
    ITag,
    ITextStyles,
    MessageBarType,
    Panel,
    PanelType,
    PrimaryButton,
    Stack,
    TagPicker,
    Text,
    TextField,
} from '@fluentui/react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { BusySpinnerOverlay } from '../busyIndicators/BusySpinnerOverlay';
import { postToApi } from '../helper/ApiHelper';
import { useStoreActions, useStoreState } from '../store/Store';
import { IGroup } from '../types/IGroup';
import { IPagedRequest } from '../types/IPagedRequest';
import { IUser } from '../types/IUser';
import { IUserGroup } from '../types/IUserGroup';
import { IPaginationResult } from '../types/IPaginationResult';

// Styled components
const BodyWrapper = styled.div`
    display: flex;
    flex: 1;
    padding: 20px;
`;

const PanelHeader = styled.div`
    padding: 0 0 20px 20px;
    width: 100%;
`;

const StackContainer = styled.div`
    display: flex;
    gap: 20px;
    flex: 1;
    flex-direction: column;
`;

/**
 * Panel component to either create or edit a group.
 *
 * @returns {FunctionComponent} Manage Groups Panel.
 */
export const ManageGroupPanel: FunctionComponent = () => {
    /** Access to translations. */
    const { t } = useTranslation();
    /** Store state of the EditUserPanel. */
    const panelPurpose = useStoreState((state) => state.GroupsModel.panelPurpose);
    /** Action to update the state oft the EditUserPanel. */
    const updatePanelPurpose = useStoreActions((actions) => actions.GroupsModel.updatePanelPurpose);
    /** Updates the global notification. */
    const updateNotification = useStoreActions((actions) => actions.GlobalNotificationModel.updateNotification);
    /** Fetches groups in the global state. */
    const fetchGroups = useStoreActions((actions) => actions.GroupsModel.fetchGroups);
    /** Global state of users. */
    const groups = useStoreState<IGroup[]>((state) => state.GroupsModel.groups);
    /** Whether this component is busy or not. */
    const [isBusy, setIsBusy] = useState<boolean>(false);
    /** Whether the predictability threshold is enabled. */
    const [isThresholdEnabled, setIsThresholdEnabled] = useState<boolean>(false);
    /** The selected tags for the users. */
    const [selectedUserTags, setSelectedUserTags] = useState<ITag[]>();
    /** The value of the group name Textfield. */
    const [groupNameValue, setGroupNameValue] = useState<string>();

    const [group, setGroup] = useState<IGroup>();
    /** Sets the state for the predictability threshold. */
    const [predictabilityThreshold, setPredictabilityThreshold] = useState<number>();
    /** Whether the panel is in create mode. */
    const isCreateMode = panelPurpose.mode === 'CREATE';
    /** Whether the panel is in read mode. */
    const isReadMode = panelPurpose.mode === 'READ';
    /** Whether the panel is in update mode. */
    const isUpdateMode = panelPurpose.mode === 'UPDATE';
    /** Fetch the requested users on load.*/
    useEffect(() => {
        if (panelPurpose.entityId) {
            const group = groups.find((group) => group.id === panelPurpose.entityId);
            setGroupNameValue(group?.label);
            setGroup(group);
            setSelectedUserTags(
                group?.userGroups?.map((ug) => {
                    const tag: ITag = { key: buildTagKey(ug), name: ug.userName ?? ug.userId.toString() };
                    return tag;
                })
            );
            setPredictabilityThreshold(group?.predictabilityThreshold != null ? Math.round(group.predictabilityThreshold * 100) : undefined);
            setIsThresholdEnabled(group?.predictabilityThreshold != null);
        }
    }, [groups, panelPurpose.entityId]);

    /**
     * The method to build a tag key from a userGroup.
     *
     * @param {IUserGroup} userGroup A single user tag.
     * @returns {string} The key for a tag.
     */
    const buildTagKey = (userGroup: IUserGroup): string => {
        return `id${userGroup.id}groupId${userGroup.groupId}userId${userGroup.userId}`;
    };

    /**
     * The method to parse a tag key to a userGroup.
     *
     * @param {string} tagKey The key for a tag.
     * @returns {IUserGroup}  A single user tag.
     */
    const parseTagKey = (tagKey: string): IUserGroup | undefined => {
        const regex = RegExp('id([0-9]+)groupId([0-9]+)userId([0-9]+)');
        const test = regex.exec(tagKey);
        if (test) {
            const id = parseInt(test[1]);
            const groupId = parseInt(test[2]);
            const userId = parseInt(test[3]);
            if ((id || id === 0) && (groupId || groupId === 0) && userId) {
                const ug: IUserGroup = {
                    groupId: groupId,
                    id: id,
                    userId: userId,
                };
                return ug;
            }
        }
        return undefined;
    };

    /**
     * The method to check whether user tags are already used.
     *
     * @param {ITag} tag A single user tag.
     * @param {ITag[]} tagList The used user tags.
     * @returns {ITag[]} returns the tag list compare in.
     */
    const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
        if (!tagList || !tagList.length || tagList.length === 0) {
            return false;
        }
        return tagList.some((compareTag) => compareTag.key === tag.key);
    };

    /**
     * Method to do paged request for users.
     *
     * @param {string} filterText The string to search for.
     * @returns {Promise<IUser[]>} The pagination result of users.
     */
    const search = async (filterText?: string): Promise<IUser[]> => {
        const body: IPagedRequest = {
            filterText: filterText ?? '',
            itemsPerPage: 20,
            pageToDeliver: 1,
            sortDirection: 1,
            sortPropertyName: 'Id',
        };
        try {
            const objects = await postToApi<IPaginationResult<IUser>>(`User/Paged`, body);
            return objects.results;
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    /** Method to create the group in the backend and display error or success message. */
    const onCreate = async () => {
        setIsBusy(true);
        try {
            const userGroups: IUserGroup[] =
                selectedUserTags?.map((tag) => {
                    const userGroup = parseTagKey(tag.key.toString());
                    if (userGroup) {
                        return userGroup;
                    }
                    throw Error('Could not parse tag.');
                }) ?? [];
            const body: IGroup = {
                id: 0,
                label: groupNameValue ?? '',
                userGroups: userGroups,
                predictabilityThreshold: predictabilityThreshold != null ? predictabilityThreshold / 100 : undefined,
            };
            await postToApi<IGroup>(`Group`, body);
            fetchGroups();
            onClose();
            updateNotification({ message: t('Group_Creation_Success'), type: MessageBarType.success });
        } catch (error) {
            updateNotification({ message: t('Group_Creation_Error'), type: MessageBarType.error });
        } finally {
            setIsBusy(false);
        }
    };

    /** Method to update the group in the backend and display error or success message. */
    const onUpdate = async () => {
        if (panelPurpose.entityId == null || group == null) {
            console.warn('Group id or group is null');
            return;
        }
        setIsBusy(true);
        try {
            const userGroups: IUserGroup[] =
                selectedUserTags?.map((tag) => {
                    const userGroup = parseTagKey(tag.key.toString());
                    if (userGroup) {
                        return userGroup;
                    }
                    throw Error('Could not parse tag.');
                }) ?? [];
            const body: IGroup = {
                id: panelPurpose.entityId,
                label: groupNameValue ?? '',
                userGroups: userGroups,
                predictabilityRate: group?.predictabilityRate,
                predictabilityThreshold: predictabilityThreshold != null ? predictabilityThreshold / 100 : undefined,
            };
            await postToApi<IGroup>(`Group/Update`, body);
            fetchGroups();
            onClose();
            updateNotification({ message: t('Group_Update_Success'), type: MessageBarType.success });
        } catch (error) {
            updateNotification({ message: t('Group_Update_Error'), type: MessageBarType.error });
        } finally {
            setIsBusy(false);
        }
    };

    /**
     * Resets local states and closes panel.
     */
    const reset = () => {
        setGroupNameValue(undefined);
        setSelectedUserTags([]);
        setPredictabilityThreshold(undefined);
        setIsThresholdEnabled(false);
    };

    /** Method that calls the reset method and closes the panel */
    const onClose = () => {
        reset();
        updatePanelPurpose({ isOpen: false, mode: 'CREATE' });
    };

    /**
     * Transforms users to tags.
     *
     * @param {string} filterText The text to filter for.
     * @param {ITag[]} tagList The array of tags.
     * @returns {ITag[]} The promise containing the result.
     */
    const filterSuggestedTags = (filterText: string, tagList?: ITag[]): Promise<ITag[]> => {
        const usersPromise = search(filterText);
        const resultPromise = usersPromise.then((users) => {
            return users
                .map((user) => {
                    const newTag: ITag = { key: buildTagKey({ groupId: panelPurpose.entityId ?? 0, id: 0, userId: user.id }), name: user.displayName ?? '' };
                    return newTag;
                })
                .filter((tag) => !listContainsTagList(tag, tagList));
        });

        return resultPromise;
    };

    /** Stack tokens for elements inside a row. */
    const columnStackTokens: IStackTokens = {
        childrenGap: 31,
    };

    /** Styles for the tag picker. */
    const tagPickerStyles: Partial<IBasePickerStyles> = {
        root: {
            width: '100%',
        },
    };

    /** The styles of the user picker label. */
    const textStyles: ITextStyles = {
        root: {
            fontWeight: 'bold',
            fontSize: 11,
            paddingBottom: 5,
        },
    };

    /**
     * Header of the panel.
     *
     * @returns {Element} The header element.
     */
    const onRenderHeader = () => {
        let headerText;
        if (isReadMode) {
            headerText = t('GroupUserPanel_Title');
        } else if (isCreateMode) {
            headerText = t('CreateGroupPanel_Header');
        } else {
            headerText = t('EditGroupPanel_Header');
        }
        return (
            <PanelHeader>
                <Text variant={'large'}>{headerText}</Text>
            </PanelHeader>
        );
    };

    /**
     * The body of the panel.
     *
     * @returns {HTMLElement} The body.
     */
    const onRenderBody = () => (
        <BodyWrapper>
            <BusySpinnerOverlay isBusy={isBusy} />
            <StackContainer>
                <Stack>
                    <TextField
                        readOnly={isReadMode || isUpdateMode}
                        value={groupNameValue ?? ''}
                        label={t('GroupsPanel_Textfield_Name')}
                        placeholder={t('GroupsPanel_Textfield_Name')}
                        onChange={(_, newValue?: string) => {
                            setGroupNameValue(newValue);
                        }}
                    />
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 7 }} horizontalAlign="space-between">
                    <Stack.Item>
                        <TextField
                            styles={{ root: { width: '150px' } }}
                            readOnly
                            label={t('GroupsTable_ColumnHeadline_PredictabilityRate')}
                            value={group?.predictabilityRate != null ? Math.trunc(group?.predictabilityRate * 100).toString() : ''}
                            suffix="%"
                        />
                    </Stack.Item>
                    <Stack.Item align="center" tokens={{ margin: '20px 0 0 0' }}>
                        <Checkbox
                            disabled={isReadMode}
                            label={t('GroupsTable_ColumnHeadline_Is_Assigned')}
                            checked={isThresholdEnabled}
                            onChange={(_, checked) => {
                                if (checked) {
                                    setIsThresholdEnabled(true);
                                    setPredictabilityThreshold(50);
                                } else {
                                    setIsThresholdEnabled(false);
                                    setPredictabilityThreshold(undefined);
                                }
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            disabled={!isThresholdEnabled}
                            readOnly={isReadMode}
                            styles={{ root: { width: '150px' } }}
                            label={t('GroupUserPanel_Expected_Threshold')}
                            value={predictabilityThreshold !== undefined ? predictabilityThreshold.toString() : ''}
                            suffix="%"
                            onChange={(event) => {
                                const newValue = event.currentTarget.value;
                                const numericValue = parseInt(newValue);
                                if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 100) {
                                    setPredictabilityThreshold(numericValue);
                                } else if (numericValue > 100) {
                                    setPredictabilityThreshold(100);
                                } else {
                                    setPredictabilityThreshold(undefined);
                                }
                            }}
                        />
                    </Stack.Item>
                </Stack>
                <Stack tokens={columnStackTokens}>
                    <Stack horizontal wrap>
                        <Text styles={textStyles}>{t('GroupsTable_ColumnHeadline_Users')}</Text>
                        <TagPicker
                            disabled={isReadMode}
                            styles={tagPickerStyles}
                            defaultSelectedItems={selectedUserTags}
                            onResolveSuggestions={filterSuggestedTags}
                            onChange={(items?: ITag[]) => setSelectedUserTags(items)}
                        />
                    </Stack>
                </Stack>
            </StackContainer>
        </BodyWrapper>
    );

    /**
     * Footer of the panel.
     *
     * @returns {Element} The footer element.
     */
    const onRenderFooter = () => (
        <Stack horizontal horizontalAlign={'end'} verticalAlign={'end'} tokens={{ childrenGap: 10, padding: '0 20px 20px 20px' }}>
            <DefaultButton text={isReadMode ? t('EditUserPanel_Footer_CloseButton') : t('EditUserPanel_Footer_CancelButton')} onClick={onClose} />
            {!isReadMode && (
                <PrimaryButton
                    disabled={isCreateMode && (groupNameValue === '' || groupNameValue === undefined)}
                    text={isCreateMode ? t('CreateGroupPanel_Footer_CreateButton') : t('EditGroupPanel_Footer_ConfirmButton')}
                    onClick={() => {
                        isCreateMode ? onCreate() : onUpdate();
                    }}
                />
            )}
        </Stack>
    );

    return (
        <Panel
            allowTouchBodyScroll
            isOpen={panelPurpose.isOpen}
            isFooterAtBottom
            isBlocking
            isLightDismiss
            type={PanelType.medium}
            onRenderHeader={onRenderHeader}
            onDismiss={onClose}
            onRenderBody={onRenderBody}
            onRenderFooter={onRenderFooter}
        />
    );
};
