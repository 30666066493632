/**
 * The type of collection
 */
export enum CollectionTypeEnum {
    /** Shows only letters assigned to the user. */
    AssignedToUser = 0,
    /** Shows only letters assigned to no one. */
    AssignedToNone = 1,
    /** Shows only letters assigned to a group. */
    AssignedToGroup = 2,
}
