import React, { FunctionComponent, useEffect, useCallback, useState } from 'react';
import { DefaultButton, IButtonStyles, Icon, IIconProps, IIconStyles, Panel, PrimaryButton, Stack, useTheme, Text, PanelType, MessageBarType } from '@fluentui/react';
import { ILetter } from '../types/ILetter';
import { BusySpinnerOverlay } from '../busyIndicators/BusySpinnerOverlay';
import { useStoreActions, useStoreState } from '../store/Store';
import styled from 'styled-components/macro';
import { postToApi } from '../helper/ApiHelper';
import { useTranslation } from 'react-i18next';
import { IGroup } from '../types/IGroup';
import { ILetterGroupUpdate } from '../types/ILetterGroupUpdate';

const PanelHeader = styled.div`
    padding: 0 0 20px 20px;
    width: 100%;
`;

const StyledGroupCard = styled.div`
    position: relative;
    display: flex;
    max-width: 260px;
    flex-direction: column;
    margin: 5px;
    padding: 5px;
    justify-content: space-evenly;
`;

const StyledGroupCardText = styled.div`
    font-size: 11px;
    line-height: 15px;
`;

const GroupListContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
`;

interface IEditRecipientsPanelProps {
    /** The letters to update. */
    letters?: ILetter[];
}

/**
 * Component that contains the panel to choose a group recipient.
 *
 * @param {IEditRecipientsPanelProps} props The props for the edit group recipients panel.
 * @returns {FunctionComponent<IEditRecipientsPanelProps>} The recipients panel.
 */
export const EditGroupRecipientsPanel: FunctionComponent<IEditRecipientsPanelProps> = (props: IEditRecipientsPanelProps) => {
    /** Access to translations.*/
    const { t } = useTranslation();
    /** Access to the global theme. */
    const theme = useTheme();

    /** The busy state of this component. */
    const [isBusy, setBusy] = useState<boolean>(false);

    /** The busy state of this component. */
    const [selectedGroup, setSelectedGroup] = useState<IGroup>();

    /** Store state of the EditGroupPanel. */
    const panelPurpose = useStoreState((state) => state.LettersModel.groupPanelPurpose);

    /** Action to update the state oft the update recipients / assignees panel. */
    const updatePanelPurpose = useStoreActions((actions) => actions.LettersModel.updateGroupPanelPurpose);

    /**
     * Global state of groups.
     */
    const groups = useStoreState<IGroup[]>((state) => state.GroupsModel.groups);

    /**
     * Fetches groups in the global state.
     */
    const fetchGroups = useStoreActions((actions) => actions.GroupsModel.fetchGroups);

    /**
     *  Global state representing if assigned or collection letters are being shown.
     */
    const showType = useStoreState((state) => state.LettersModel.showType);

    /**
     * Action to fetch the letters.
     */
    const fetchLetters = useStoreActions((actions) => actions.LettersModel.fetchLetters);

    /**
     * Updates the global notification.
     */
    const updateNotification = useStoreActions((actions) => actions.GlobalNotificationModel.updateNotification);

    /**
     * Fetch needed data.
     */
    useEffect(() => {
        if (panelPurpose.isOpen) {
            fetchGroups();
        }
    }, [fetchGroups, panelPurpose.isOpen]);

    /**
     * Update the chosen letters to them based on the update model.
     *
     * @param {ILetterGroupUpdate[]} letterGroupUpdates The update models.
     */
    const updateGroup = async (letterGroupUpdates: ILetterGroupUpdate[]) => {
        try {
            setBusy(true);
            await postToApi<ILetter[]>(`Letter/Groups`, letterGroupUpdates);
            fetchLetters(showType);
            updateNotification({ message: t('Letter_GroupUpdate_Success'), type: MessageBarType.success });
            resetState();
        } catch (error) {
            console.debug(error);
            updateNotification({ message: t('Letter_GroupUpdate_Error'), type: MessageBarType.error });
        } finally {
            setBusy(false);
        }
    };

    /**
     * Button styles for the list elements.
     */
    const listElementGroupStyles: IButtonStyles = {
        root: {
            padding: 0,
            maxWidth: 120,
            maxHeight: 240,
            fontSize: 12,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
        },
        icon: {
            color: theme.palette.themePrimary,
            fontSize: 15,
            margin: 0,
        },
        flexContainer: {
            maxWidth: 240,
            maxHeight: 120,
            justifyContent: 'center',
            flexDirection: 'column',
        },
        textContainer: {
            maxWidth: 240,
            maxHeight: 120,
        },
        label: {
            maxWidth: 240,
            maxHeight: 120,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    };

    /**
     * Button styles for the chosen list elements.
     */
    const chosenListElementStyles: IButtonStyles = {
        root: {
            padding: 0,
            maxWidth: 120,
            maxHeight: 240,
            fontSize: 12,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            borderStyle: 'solid',
            borderRadius: 4,
            borderWidth: 4,
            borderColor: theme.palette.themePrimary,
        },
        rootHovered: {
            padding: 0,
            maxWidth: 240,
            maxHeight: 120,
            fontSize: 12,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            borderStyle: 'solid',
            borderRadius: 4,
            borderWidth: 4,
            borderColor: theme.palette.themePrimary,
        },
        icon: {
            color: theme.palette.themePrimary,
            fontSize: 15,
            margin: 0,
        },
        flexContainer: {
            maxWidth: 240,
            maxHeight: 120,
            justifyContent: 'center',
            flexDirection: 'column',
        },
    };

    /**
     * Styles of the icon on editSlideGroupMenu.
     */
    const activeGroupStyles: IIconStyles = {
        root: {
            color: theme.palette.themeSecondary,
            position: 'absolute',
            top: 3,
            right: 3,
            borderRadius: 999999999,
            padding: 1,
            backgroundColor: theme.palette.white,
            zIndex: 10000,
        },
    };

    /**
     * Props of the icon for an active image on editSlideGroupMenu.
     */
    const activeGroupIconProps: IIconProps = {
        iconName: 'CheckMark',
    };

    /**
     * Resets the component state.
     */
    const resetState = useCallback((): void => {
        updatePanelPurpose({ isOpen: false });
        setSelectedGroup(undefined);
    }, [updatePanelPurpose]);

    /**
     * Handle what happens when the user selects a group.
     *
     * @param {IGroup} group The group that is clicked.
     */
    const handleGroupSelection = (group: IGroup) => {
        setSelectedGroup(group);
    };

    /**
     * Render function of a group card.
     *
     * @param {IGroup} group THe group to display.
     * @param {boolean} isChosen If the group should be displayed as active.
     * @returns {typeof(StyledGroupCard)} A render for a single group.
     */
    const renderGroup = (group: IGroup, isChosen: boolean) => {
        return (
            <StyledGroupCard key={`group-${group.id}`}>
                {isChosen && <Icon styles={activeGroupStyles} iconName={activeGroupIconProps.iconName} />}{' '}
                <DefaultButton
                    styles={isChosen ? chosenListElementStyles : listElementGroupStyles}
                    label={group.label}
                    onClick={() => {
                        handleGroupSelection(group);
                    }}
                >
                    <StyledGroupCardText>{group.label}</StyledGroupCardText>
                </DefaultButton>
            </StyledGroupCard>
        );
    };

    /**
     * Transforms ILetter[] into ILetterGroupUpdate[] for the api call.
     *
     * @param {ILetter[]} letters The letters to transform for use in the update call.
     * @param {number|undefined} newGroupId The group id to set.
     * @returns {ILetterGroupUpdate[]} The transformed elements.
     */
    const transformLettersForGroupUpdate = (letters: ILetter[], newGroupId?: number): ILetterGroupUpdate[] => {
        const letterGroupUpdates: ILetterGroupUpdate[] = [];
        letters.forEach((letter) => {
            letterGroupUpdates.push({
                id: letter.id,
                groupId: newGroupId,
            });
        });
        return letterGroupUpdates;
    };

    /**
     * Footer of the panel.
     *
     * @returns {Element} The footer element.
     */
    const onRenderFooter = () => (
        <Stack style={{ marginTop: 'auto' }} horizontal horizontalAlign={'end'} verticalAlign={'end'} tokens={{ childrenGap: 10, padding: '0 20px 20px 20px' }}>
            <DefaultButton text={t('EditGroupPanel_Footer_CancelButton')} onClick={() => resetState()} />
            <PrimaryButton
                disabled={!selectedGroup}
                text={t('EditGroupPanel_Footer_ConfirmButton')}
                onClick={() => {
                    if (props.letters) {
                        updateGroup(transformLettersForGroupUpdate(props.letters, selectedGroup?.id));
                    }
                }}
            />
        </Stack>
    );

    /**
     * The body of the panel.
     *
     * @returns {HTMLElement} The body.
     */
    const onRenderBody = () => (
        <Stack>
            <BusySpinnerOverlay isBusy={isBusy} />
            <GroupListContainer>{groups?.map((group: IGroup) => (group ? renderGroup(group, selectedGroup?.id === group.id) : null))}</GroupListContainer>
        </Stack>
    );

    /**
     * Header of the panel.
     *
     * @returns {Element} The header element.
     */
    const onRenderHeader = () => (
        <PanelHeader>
            <Text variant={'large'}>{t('EditGroupRecipientsPanel_Headline_Recipients')}</Text>
        </PanelHeader>
    );

    return (
        <Panel
            allowTouchBodyScroll
            isOpen={panelPurpose.isOpen}
            isFooterAtBottom
            isBlocking
            isLightDismiss
            type={PanelType.medium}
            onRenderHeader={onRenderHeader}
            onDismiss={resetState}
            onRenderBody={onRenderBody}
            onRenderFooter={onRenderFooter}
        />
    );
};
