import React, { FunctionComponent, useCallback } from 'react';
import styled from 'styled-components/macro';
import { IColumn, Panel, PanelType } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { IComment } from '../types/IComment';
import { Table } from '../table/Table';
import { formatToGermanDate } from '../helper/DateFormatHelper';

const BodyWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    margin-top: 31px;
`;

/**
 * The interface for the LetterViewPanel
 */
interface ICommentsPanel {
    /** The selected letter to display. */
    comments: IComment[];
    /** The state if the letter is open. */
    isOpen: boolean;
    /** The function to trigger on dismiss. */
    onDismiss: () => void;
}

/**
 * Panel component to display comments.
 *
 * @param {ICommentsPanel} props The properties of the comments panel.
 * @returns {FunctionComponent} The comments panel component.
 */
export const CommentsPanel: FunctionComponent<ICommentsPanel> = (props) => {
    /** Access to translations. */
    const { t } = useTranslation();

    /**
     * The columns of the table.
     *
     * @returns {IColumn[]} The columns
     */
    const columns = useCallback((): IColumn[] => {
        const columns: IColumn[] = [];
        columns.push({
            key: 'spacing',
            name: '',
            minWidth: 1,
            maxWidth: 7,
            columnActionsMode: 0,
        });
        columns.push({
            key: 'reason',
            fieldName: 'reason',
            name: t('CommentsTable_reason'),
            minWidth: 150,
            maxWidth: 250,
            columnActionsMode: 0,
            isMultiline: true,
        });
        columns.push({
            key: 'userName',
            fieldName: 'userName',
            name: t('CommentsTable_userName'),
            minWidth: 150,
            maxWidth: 250,
            columnActionsMode: 0,
        });
        columns.push({
            key: 'dateCreated',
            fieldName: 'dateCreated',
            name: t('CommentsTable_dateCreated'),
            minWidth: 150,
            maxWidth: 250,
            columnActionsMode: 0,
            onRender: (item: IComment) => formatToGermanDate(item.dateCreated),
        });
        columns.push({
            key: 'spacing2',
            name: '',
            minWidth: 1,
            maxWidth: 7,
            columnActionsMode: 0,
        });
        return columns;
    }, [t]);

    /**
     * Render function of the panel body.
     *
     * @returns {Element} The panel body component.
     */
    const onRenderBody = () => (
        <BodyWrapper>
            <Table columns={columns()} data={props.comments} isBusy={false} />
        </BodyWrapper>
    );

    return (
        <Panel
            allowTouchBodyScroll
            isOpen={props.isOpen}
            isBlocking
            isLightDismiss
            headerText={t('CommentPanel_HeaderText')}
            type={PanelType.medium}
            onDismiss={() => props.onDismiss()}
            onRenderBody={onRenderBody}
        />
    );
};
