import { Action, action, thunk, Thunk } from 'easy-peasy';
import { getFromApi } from '../../helper/ApiHelper';
import { IRole } from '../../types/IRole';

/**
 * Interface for the roles model.
 */
export interface IRolesModel {
    /**
     * List of all roles.
     */
    roles: IRole[];
    /**
     * Updates all roles of the state.
     */
    updateRoles: Action<IRolesModel, IRole[]>;
    /**
     * Triggers a fetch and update of all roles.
     */
    fetchRoles: Thunk<IRolesModel, void>;
}

/**
 * Initial state of the roles model.
 */
export const RolesModel: IRolesModel = {
    roles: [],
    updateRoles: action((state, payload) => {
        state.roles = payload;
    }),
    fetchRoles: thunk(async (actions, payload, store) => {
        const objects = await getFromApi<IRole[]>(`Role`);
        actions.updateRoles(objects);
    }),
};
