import React, { FunctionComponent, ReactElement } from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';
import History from './BrowserHistory';
import { RouteWrapper } from './RouteWrapper';
import { BasicLayout } from '../layout/BasicLayout';
import { HomeView } from '../views/HomeView';
import { UsersView } from '../views/UsersView';
import { RoleEnum } from '../types/RoleEnum';
import { AuthenticationGate } from './AuthenticationGate';
import { NotAuthorizedView } from '../views/NotAuthorizedView';
import { NotAuthorizedLayout } from '../layout/NotAuthorizedLayout';
import { GroupsView } from '../views/GroupsView';
import { UserRepresentationView } from '../views/UserRepresentationView';
import { useMsal } from '@azure/msal-react';

/**
 * The routes component.
 *
 * @returns {ReactElement} Router.
 */
export const Routes: FunctionComponent = (): ReactElement => {
    /** Access to the msal instance. */
    const msal = useMsal();

    return (
        <Router history={History}>
            <Switch>
                <RouteWrapper exact path="/notAuthorized" layout={NotAuthorizedLayout}>
                    <NotAuthorizedView />
                </RouteWrapper>
                <RouteWrapper exact path="/home" layout={BasicLayout}>
                    <AuthenticationGate roleRequirement={RoleEnum.User} redirectRoute="/notAuthorized">
                        <HomeView />
                    </AuthenticationGate>
                </RouteWrapper>
                <RouteWrapper exact path="/users" layout={BasicLayout}>
                    <AuthenticationGate roleRequirement={RoleEnum.Admin} redirectRoute="/home">
                        <UsersView />
                    </AuthenticationGate>
                </RouteWrapper>
                <RouteWrapper exact path="/groups" layout={BasicLayout}>
                    <AuthenticationGate roleRequirement={RoleEnum.Admin} redirectRoute="/home">
                        <GroupsView />
                    </AuthenticationGate>
                </RouteWrapper>
                {msal.instance.getActiveAccount()?.username.toLowerCase().endsWith('@roche.com') && (
                    <RouteWrapper exact path="/representations" layout={BasicLayout}>
                        <AuthenticationGate roleRequirement={RoleEnum.User} redirectRoute="/home">
                            <UserRepresentationView />
                        </AuthenticationGate>
                    </RouteWrapper>
                )}
                <Redirect to="/home" />
            </Switch>
        </Router>
    );
};
