import { useTheme, Text } from '@fluentui/react';
import React, { FunctionComponent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { TopMenu } from '../menus/TopMenu';
import { IThemed } from '../types/IThemed';
import { NotAuthorizedView } from '../views/NotAuthorizedView';

const LayoutContainer = styled.div`
    display: flex;
    flex: 1;
    max-height: calc(100% - 56px);
`;

const ContentContainer = styled.div<IThemed>`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.palette.white};
    border-radius: 4px;
    border-color: ${(props) => props.palette.neutralLight};
    border-width: 1px;
    border-style: solid;
    overflow: auto;
    box-shadow: 0px 6px 15px 0px ${(props) => props.palette.blackTranslucent40};
`;

const HeaderContainer = styled.div<IThemed>`
    display: flex;
    align-items: center;
    padding: 6px;
    background-color: ${(props) => props.palette.white};
`;

const LogoImage = styled.img`
    margin-left: 5px;
`;

const UserMenuWrapper = styled.div`
    display: flex;
    margin-left: auto;
`;

const LayoutContainerWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const ContentWrapper = styled.div<IThemed>`
    display: flex;
    flex: 1;
    overflow-y: auto;
    overflow-x: auto;
    background-color: ${(props) => props.palette.neutralLighterAlt};
    border-top: 1px solid ${(props) => props.palette.neutralLight};
    padding: 12px;
`;

/**
 * The basic layout component.
 *
 * @returns {ReactElement} NotAuthorized layout.
 */
export const NotAuthorizedLayout: FunctionComponent = (): ReactElement => {
    /**
     * Global fluent UI theme.
     */
    const theme = useTheme();

    /**
     * Access to translations.
     */
    const { t } = useTranslation();

    return (
        <LayoutContainerWrapper>
            <HeaderContainer palette={theme.palette}>
                <LogoImage src="./logo.svg" alt="" height={28} />
                <Text variant={'large'} styles={{ root: { color: theme.palette.themeDarkAlt, marginLeft: 7 } }}>
                    {t('App_DisplayName')}
                </Text>
                <UserMenuWrapper>
                    <TopMenu />
                </UserMenuWrapper>
            </HeaderContainer>
            <LayoutContainer>
                <ContentWrapper palette={theme.palette}>
                    <ContentContainer palette={theme.palette}>
                        <NotAuthorizedView />
                    </ContentContainer>
                </ContentWrapper>
            </LayoutContainer>
        </LayoutContainerWrapper>
    );
};
