import React, { FunctionComponent, useState } from 'react';
import { Dialog, DialogType, DialogFooter, IDialogContentProps } from '@fluentui/react/lib/Dialog';
import { DefaultButton, IButtonStyles, IModalProps, ITextFieldStyles, PrimaryButton, TextField, useTheme } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

/**
 * Interface for the properties of the confirmation dialog component.
 */
interface IDenialDialog {
    /**
     * Whether the dialog is open or not.
     */
    isOpen: boolean;

    /**
     * Callback to close the dialog.
     */
    close: () => void;

    /**
     * Callback to execute when dialog is confirmed.
     */
    confirmCallback: (denialReason: string) => void;

    /**
     * The optional custom width for the dialog.
     */
    customWidth?: number;
}

/**
 * The denial dialog.
 *
 * @param {IDenialDialog} props Props.
 * @returns {FunctionComponent<IDenialDialog>} Denial dialog.
 */
export const DenialDialog: FunctionComponent<IDenialDialog> = (props: IDenialDialog) => {
    /**
     * Access to the fluent ui theme.
     */
    const theme = useTheme();

    /**
     * Access to translations.
     */
    const { t } = useTranslation();

    /**
     * The denial reason.
     */
    const [denialReason, setDenialReason] = useState<string>('');

    /**
     * Properties of the modal object used for the dialog.
     */
    const modalProps: IModalProps = {
        isBlocking: true,
    };

    /**
     * Properties of the Dialog content.
     */
    const dialogContentProps: IDialogContentProps = {
        title: t('DenialDialogue_Title'),
        subText: t('DenialDialogue_SubText'),
        type: DialogType.close,
    };

    /**
     * The styles of the confirm button.
     */
    const confirmationButtonStyles: IButtonStyles = {
        root: {
            color: theme.palette.red,
            borderColor: theme.palette.red,
        },
        rootHovered: {
            color: theme.palette.red,
            borderColor: theme.palette.red,
        },
        rootPressed: {
            color: theme.palette.red,
            borderColor: theme.palette.red,
        },
        rootFocused: {
            color: theme.palette.red,
            borderColor: theme.palette.red,
        },
    };

    /**
     * Styles for the employee textfield.
     */
    const textFieldStyles: Partial<ITextFieldStyles> = {
        root: {
            width: '100%',
        },
    };

    return (
        <Dialog
            minWidth={props.customWidth && props.customWidth}
            hidden={!props.isOpen}
            dialogContentProps={dialogContentProps}
            onDismiss={() => {
                setDenialReason('');
                props.close();
            }}
            modalProps={modalProps}
        >
            <TextField
                multiline
                rows={3}
                resizable={false}
                styles={textFieldStyles}
                value={denialReason}
                label={t('DenialDialogue_DenialReason_Label')}
                placeholder={t('DenialDialogue_DenialReason_Placeholder')}
                onChange={(_, newValue?: string) => {
                    if (newValue) {
                        if (newValue.length <= 256) {
                            setDenialReason(newValue);
                        }
                    } else {
                        setDenialReason('');
                    }
                }}
                validateOnLoad={false}
                validateOnFocusOut={true}
                max={256}
            />
            <DialogFooter>
                <DefaultButton
                    text={t('ConfirmDialog_Abort')}
                    onClick={() => {
                        setDenialReason('');
                        props.close();
                    }}
                />
                <PrimaryButton
                    disabled={denialReason === ''}
                    styles={confirmationButtonStyles}
                    text={t('DenialDialogue_Confirm')}
                    onClick={() => {
                        setDenialReason('');
                        props.confirmCallback(denialReason);
                        props.close();
                    }}
                />
            </DialogFooter>
        </Dialog>
    );
};
