/** a regex describing a valid email. */
const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
/**
 * Validates a string to be a valid email.
 *
 * @param {string} email The email to validate.
 * @returns {boolean} Returns the true if valid email and false if not.
 */
export const validateEmail = (email: string): boolean => {
    return (email.match(validEmailRegex)?.length ?? 0) > 0;
};
