import { ComponentsStyles, IFontStyles, IPalette, IRawStyle, ISemanticColors } from '@fluentui/react';

/**
 * The color palette for the theme.
 */
export const Palette: Partial<IPalette> = {
    themeDarker: '#004578',
    themeDark: '#005a9e',
    themeDarkAlt: '#106ebe',
    themePrimary: '#225989',
    themeSecondary: '#1581F9',
    themeTertiary: '#71afe5',
    themeLight: '#c7e0f4',
    themeLighter: '#deecf9',
    themeLighterAlt: '#eff6fc',
    black: '#000000',
    blackTranslucent40: '#00000029',
    neutralDark: '#272727',
    neutralPrimary: '#333333',
    neutralPrimaryAlt: '#4b4b4b',
    neutralSecondary: '#858585',
    neutralSecondaryAlt: '',
    neutralTertiary: '#c2c2c2',
    neutralTertiaryAlt: '#c8c8c8',
    neutralQuaternary: '#d0d0d0',
    neutralQuaternaryAlt: '#dadada',
    neutralLight: '#e6e6e6',
    neutralLighter: '#f4f4f4',
    neutralLighterAlt: '#FBF4EF',
    white: '#ffffff',
    red: '#F57F6B',
};

/**
 * The color palette for the theme.
 */
export const DarkPalette: Partial<IPalette> = {
    black: '#ffffff',
    blackTranslucent40: '#ffffff15',
    neutralDark: '#c4bfb7',
    neutralLight: '#333333',
    neutralLighter: '#152c3d',
    neutralLighterAlt: '#1B1E1F',
    neutralPrimary: '#DFDFDF',
    neutralPrimaryAlt: '#707070',
    neutralQuaternary: '#274459',
    neutralQuaternaryAlt: '#223e52',
    neutralSecondary: '#8c8c8c',
    neutralTertiary: '#707070',
    neutralTertiaryAlt: '#3e5e75',
    red: '#B23030',
    themeDark: '#006a93',
    themeDarkAlt: '#007dae',
    themeDarker: '#004e6d',
    themeLight: '#c7e0f4',
    themeLighter: '#deecf9',
    themeLighterAlt: '#eff6fc',
    themePrimary: '#225989',
    themeSecondary: '#1581F9',
    themeTertiary: '#71afe5',
    white: '#181A1B',
};

/**
 * The semantic colors.
 */
export const SemanticColors: Partial<ISemanticColors> = {};

/**
 * The semantic colors for the dark theme.
 */
export const DarkSemanticColors: Partial<ISemanticColors> = {
    successBackground: '#88B584',
    successIcon: '#004C00',
    infoBackground: '#BABABA',
    infoIcon: '#222222',
    warningBackground: '#D3BE7A',
    warningIcon: '#333333',
    severeWarningBackground: '#D39F7A',
    errorBackground: '#D3887A',
};

/**
 * The fonts for the theme.
 */
export const Fonts: Partial<IFontStyles> = {
    // Control Icons
    small: {
        fontSize: '12px',
        fontWeight: 'regular',
    },
    // Nav Menus, Labels
    medium: {
        fontSize: '14px',
        fontWeight: 'regular',
    },
    // Icons
    mediumPlus: {
        fontSize: '16px',
        fontWeight: 'bold',
    },
    // Modal Headlines
    large: {
        fontSize: '20px',
        fontWeight: 'regular',
    },
    // Main Headlines
    xxLarge: {
        fontSize: '30px',
        fontWeight: 'bold',
    },
};

/**
 * The default font style to use for theme.
 */
export const DefaultFontStyle: IRawStyle = {
    fontFamily: 'Roche Sans, Arial, Helvetica, sans-serif',
    fontWeight: 'regular',
};

/**
 * Default styles for fluent ui components.
 *
 * @param {IPalette} palette The theme color palette.
 * @returns {ComponentsStyles} The default component styles.
 */
export const DefaultComponentStyles = (palette: Partial<IPalette>): ComponentsStyles => {
    return {
        MessageBar: {
            styles: {
                root: {
                    borderRadius: 4,
                    display: 'flex',
                    justifyContent: 'center',
                },
                innerText: {
                    paddingTop: 2,
                },
            },
        },
        PrimaryButton: {
            styles: {
                root: {
                    borderRadius: 2,
                    borderWidth: 2,
                    color: palette.themeSecondary,
                    borderColor: palette.themeSecondary,
                    backgroundColor: 'transparent',
                },
                rootHovered: {
                    borderWidth: 2,
                    color: palette.themeSecondary,
                    borderColor: palette.themeSecondary,
                    backgroundColor: palette.neutralLighter,
                },
                rootPressed: {
                    borderWidth: 2,
                    color: palette.themeDarkAlt,
                    borderColor: palette.themeSecondary,
                    backgroundColor: palette.neutralLight,
                },
                label: {
                    fontWeight: '600',
                },
            },
        },
        DefaultButton: {
            styles: {
                root: {
                    borderWidth: 0,
                    color: palette.neutralDark,
                    borderColor: palette.themeSecondary,
                },
                rootHovered: {
                    color: palette.neutralDark,
                },
                rootPressed: {
                    color: palette.black,
                },
                label: {
                    fontWeight: '300',
                },
            },
        },
        Label: {
            styles: {
                root: {
                    fontSize: 11,
                    fontWeight: 'bold',
                    color: palette.neutralPrimary,
                },
            },
        },
        TextField: {
            styles: {
                root: {
                    selectors: {
                        '.ms-TextField-fieldGroup': {
                            borderColor: palette.neutralQuaternaryAlt,
                            height: 36,
                        },
                        '.ms-TextField-field': {
                            '::placeholder': {
                                color: palette.neutralQuaternaryAlt,
                            },
                        },
                    },
                },
            },
        },
        Panel: {
            styles: {
                scrollableContent: {
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                },
                content: {
                    flex: 1,
                    overflow: 'auto',
                },
            },
        },
        Dialog: {
            styles: {
                root: {
                    '.ms-Overlay': {
                        backgroundColor: palette.white,
                        opacity: 0.6,
                    },
                },
            },
        },
        DatePicker: {
            styles: {
                root: {
                    maxHeight: 61,
                },
                wrapper: {
                    'input:read-only': {
                        backgroundColor: 'transparent !important',
                        color: palette.neutralPrimary,
                    },
                    '.ms-TextField-fieldGroup:hover': {
                        borderColor: palette.neutralPrimary,
                    },
                },
            },
        },
        Dropdown: {
            styles: {
                title: {
                    height: 36,
                    paddingTop: 2,
                    border: `1px solid ${palette.neutralQuaternaryAlt}`,
                    backgroundColor: 'transparent',
                    color: palette.neutralPrimary,
                },
                caretDown: { paddingTop: 2, paddingRight: 4 },
                root: {
                    selectors: {
                        '.ms-Dropdown-titleIsPlaceHolder': {
                            color: palette.neutralQuaternaryAlt,
                            ':hover': {
                                color: `${palette.neutralQuaternaryAlt} !important`,
                            },
                        },
                    },
                },
            },
        },
        SearchBox: {
            styles: {
                root: {
                    borderColor: palette.neutralQuaternaryAlt,
                },
            },
        },
        TagPicker: {
            styles: {
                root: {
                    selectors: {
                        '.ms-BasePicker-input': {
                            minHeight: 34,
                            paddingLeft: 8,
                            '::placeholder': {
                                color: palette.neutralQuaternaryAlt,
                            },
                        },
                        '.ms-BasePicker-text': {
                            borderColor: palette.neutralQuaternaryAlt,
                            paddingLeft: 2,
                            minHeight: 36,
                            '::after': {
                                backgroundColor: 'transparent',
                            },
                            ':hover': {
                                borderColor: palette.black,
                            },
                        },
                    },
                },
            },
        },
    };
};
