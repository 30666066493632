import { Action, action } from 'easy-peasy';
import { MessageBarType } from '@fluentui/react';

/**
 * A single notification.
 */
export interface INotification {
    /**
     * The message to display.
     */
    message: string;
    /**
     * The type of message bar to display.
     */
    type: MessageBarType;
}

/**
 * Interface for the global notification model.
 */
export interface IGlobalNotificationModel {
    /**
     * The notification to display.
     */
    notification?: INotification;

    /**
     * Action to update the current notification state.
     */
    updateNotification: Action<IGlobalNotificationModel, INotification | undefined>;
}

/**
 * Initial state of the global notification model.
 */
export const GlobalNotificationModel: IGlobalNotificationModel = {
    updateNotification: action((state, payload) => {
        state.notification = payload;
    }),
};
