import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { ITheme, Spinner, SpinnerSize, useTheme } from '@fluentui/react';

const BusyOverlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props: { theme: ITheme }) => props.theme.palette.white + 'D9'};
    z-index: 1;
`;

export interface IBusySpinnerOverlay {
    /**
     * Whether the busy spinner is active or not.
     */
    isBusy: boolean;
}

/**
 * Basic component to indicate async operations.
 * NOTE: To use place this component inside a container that has set 'position: relative'.
 * This component then will take 100% of that container space and displays itself as overlay.
 *
 * @param {IBusySpinnerOverlay} props Component props.
 * @returns {ReactElement} Busy spinner overly.
 */
export const BusySpinnerOverlay: FunctionComponent<IBusySpinnerOverlay> = (props: IBusySpinnerOverlay): ReactElement => {
    const Theme: ITheme = useTheme();
    return (
        <>
            {props.isBusy ? (
                <BusyOverlay theme={Theme}>
                    <Spinner size={SpinnerSize.large} />
                </BusyOverlay>
            ) : null}
        </>
    );
};
