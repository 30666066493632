import { IMessageBarStyles, MessageBar, useTheme, Text } from '@fluentui/react';
import React, { FunctionComponent, ReactElement, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { TopMenu } from '../menus/TopMenu';
import { EditUserPanel } from '../panels/EditUserPanel';
import { ManageGroupPanel } from '../panels/ManageGroupsPanel';
import { ToBeDeletedLettersPanel } from '../panels/ToBeDeletedLettersPanel';
import { useStoreActions, useStoreState } from '../store/Store';
import { IThemed } from '../types/IThemed';
import { NavMenu } from './NavMenu';

const LayoutContainer = styled.div`
    display: flex;
    flex: 1;
    max-height: calc(100% - 56px);
`;

const ContentContainer = styled.div<IThemed>`
    display: flex;
    flex: 1;
    background-color: ${(props) => props.palette.white};
    border-radius: 4px;
    border-color: ${(props) => props.palette.neutralLight};
    border-width: 1px;
    border-style: solid;
    overflow: auto;
    box-shadow: 0px 6px 15px 0px ${(props) => props.palette.blackTranslucent40};
`;

const HeaderContainer = styled.div<IThemed>`
    display: flex;
    align-items: center;
    padding: 6px;
    background-color: ${(props) => props.palette.white};
`;

const UserMenuWrapper = styled.div`
    display: flex;
    flex: 1;
`;

const LogoImage = styled.img`
    margin-left: 5px;
`;

const LayoutContainerWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const ContentWrapper = styled.div<IThemed>`
    display: flex;
    flex: 1;
    overflow-y: auto;
    overflow-x: auto;
    background-color: ${(props) => props.palette.neutralLighterAlt};
    border-top: 1px solid ${(props) => props.palette.neutralLight};
    padding: 12px;
`;

export interface LayoutProps {
    /** Child component. */
    children?: ReactNode;
}

/**
 * The basic layout component.
 *
 * @param {LayoutProps} props Layout properties.
 * @returns {ReactElement} Basic layout.
 */
export const BasicLayout: FunctionComponent<LayoutProps> = (props: LayoutProps): ReactElement => {
    /**
     * Global fluent UI theme.
     */
    const theme = useTheme();

    /**
     * Access to translations.
     */
    const { t } = useTranslation();

    /**
     * Store state of the global notification message bar.
     */
    const notificationModel = useStoreState((state) => state.GlobalNotificationModel.notification);

    /**
     * Store action to update the global notification message bar.
     */
    const updateNotification = useStoreActions((actions) => actions.GlobalNotificationModel.updateNotification);

    /**
     * Whether the global message bar is visible or not.
     */
    const [isMessageBarVisible, setIsMessageBarVisible] = useState<boolean>(false);

    /**
     * Global message bar style.
     */
    const messageBarStyles: IMessageBarStyles = {
        root: {
            width: 'auto',
            minWidth: window.innerWidth < 400 ? window.innerWidth - 20 : 400,
            margin: 'auto',
            bottom: 20,
            left: '50%',
            transform: 'translateX(-50%)',
            position: 'absolute',
            display: 'flex',
            zIndex: 99999,
        },
    };

    /**
     * Displays a new notification model and hides it after a timeout.
     */
    useEffect(() => {
        let messageBarTimeout: number | undefined;
        if (notificationModel) {
            if (messageBarTimeout) {
                clearTimeout(messageBarTimeout);
            }
            messageBarTimeout = window.setTimeout(() => {
                setIsMessageBarVisible(false);
                updateNotification(undefined);
            }, 5000 + Math.ceil(notificationModel.message.length / 120) * 1000);
            setIsMessageBarVisible(true);
        }
        return () => {
            if (messageBarTimeout) {
                clearTimeout(messageBarTimeout);
            }
        };
    }, [isMessageBarVisible, notificationModel, updateNotification]);
    return (
        <>
            <ToBeDeletedLettersPanel />
            <EditUserPanel />
            <ManageGroupPanel />
            <LayoutContainerWrapper>
                <HeaderContainer palette={theme.palette}>
                    <LogoImage src="./logo.svg" alt="" height={28} />
                    <Text variant={'large'} styles={{ root: { color: theme.palette.themeDarkAlt, marginLeft: 7, marginRight: 7 } }}>
                        {t('App_DisplayName')}
                    </Text>
                    <UserMenuWrapper>
                        <TopMenu showSearch />
                    </UserMenuWrapper>
                </HeaderContainer>
                <LayoutContainer>
                    <NavMenu />
                    <ContentWrapper palette={theme.palette}>
                        <ContentContainer palette={theme.palette}>{props.children}</ContentContainer>
                    </ContentWrapper>
                </LayoutContainer>
                {notificationModel && isMessageBarVisible && (
                    <MessageBar styles={messageBarStyles} messageBarType={notificationModel.type}>
                        {notificationModel.message}
                    </MessageBar>
                )}
            </LayoutContainerWrapper>
        </>
    );
};
