import { Action, action, thunk, Thunk } from 'easy-peasy';
import { postToApi } from '../../helper/ApiHelper';
import { ILetter } from '../../types/ILetter';
import { IPagedRequest } from '../../types/IPagedRequest';
import { IPaginationResult } from '../../types/IPaginationResult';

/**
 * Interface for the letters model.
 */
export interface IToBeDeletedLettersModel {
    /**
     * List of all letters to be deleted in the next 10 days.
     */
    toBeDeletedLetters: ILetter[];
    /**
     * Updates the list of all letters to be deleted in the next 10 days.
     */
    updateToBeDeletedLetters: Action<IToBeDeletedLettersModel, ILetter[]>;
    /**
     * Triggers a fetch and update of all to be deleted letters.
     */
    fetchToBeDeletedLettersLetters: Thunk<IToBeDeletedLettersModel>;
    /**
     * The maximum available pages.
     */
    maxPages: number;
    /**
     * Updates the maximum available pages.
     */
    updateMaxPages: Action<IToBeDeletedLettersModel, number>;
    /**
     * The maximum available items.
     */
    itemsTotal: number;
    /**
     * Updates the maximum available items.
     */
    updateItemsTotal: Action<IToBeDeletedLettersModel, number>;
    /**
     * The current page of the letters table.
     */
    currentPage: number;
    /**
     * The Action to update the current page.
     */
    updateCurrentPage: Action<IToBeDeletedLettersModel, number>;
    /**
     * The state representing if letters are being fetched.
     */
    isFetching: boolean;
    /**
     * The Action to update the state representing if letters are being fetched.
     */
    updateIsFetching: Action<IToBeDeletedLettersModel, boolean>;
    /**
     * The state representing if the ToBeDeletedLettersPanel is open.
     */
    isTobDeletedOpen?: boolean;
    /**
     * The Action to update the state representing if the ToBeDeletedLettersPanel is open
     */
    updateIsTobDeletedOpen: Action<IToBeDeletedLettersModel, boolean>;
}

/**
 * Initial state of the letters model.
 */
export const ToBeDeletedLettersModel: IToBeDeletedLettersModel = {
    isTobDeletedOpen: false,
    updateIsTobDeletedOpen: action((state, payload) => {
        state.isTobDeletedOpen = payload;
    }),
    isFetching: false,
    updateIsFetching: action((state, payload) => {
        state.isFetching = payload;
    }),
    toBeDeletedLetters: [],
    updateToBeDeletedLetters: action((state, payload) => {
        state.toBeDeletedLetters = payload;
    }),
    currentPage: 1,
    updateCurrentPage: action((state, payload) => {
        state.currentPage = payload;
    }),
    maxPages: 1,
    updateMaxPages: action((state, payload) => {
        state.maxPages = payload;
    }),
    itemsTotal: 0,
    updateItemsTotal: action((state, payload) => {
        state.itemsTotal = payload;
    }),
    fetchToBeDeletedLettersLetters: thunk(async (actions, payload, store) => {
        actions.updateIsFetching(true);
        const body: IPagedRequest = {
            filterText: '',
            itemsPerPage: 20,
            pageToDeliver: store.getState().currentPage,
            sortDirection: 1,
            sortPropertyName: 'Id',
        };
        try {
            const response = await postToApi<IPaginationResult<ILetter>>(`Letter/PagedToBeDeleted`, body);
            actions.updateItemsTotal(response.itemsTotal);
            actions.updateMaxPages(response.pagesAvailable);
            actions.updateToBeDeletedLetters(response.results);
            actions.updateCurrentPage(response.page);
        } catch (error) {
            console.error(error);
        }
        actions.updateIsFetching(false);
    }),
};
