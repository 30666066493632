import React, { FunctionComponent } from 'react';
import { Dialog, DialogType, DialogFooter, IDialogContentProps } from '@fluentui/react/lib/Dialog';
import { DefaultButton, IButtonStyles, IModalProps, PrimaryButton, useTheme } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

/**
 * Interface for the properties of the confirmation dialog component.
 */
interface IConfirmationDialog {
    /**
     * Whether the dialog is open or not.
     */
    isOpen: boolean;

    /**
     * Callback to close the dialog.
     */
    close: () => void;

    /**
     * Callback to execute when dialog is confirmed.
     */
    confirmCallback: () => void;

    /**
     * The title of the dialog.
     */
    title: string;

    /**
     * The text content of the dialog.
     */
    text: string;

    /**
     * The text of the confirm button.
     */
    confirmButtonText: string;

    /**
     * Whether the confirm action is dangerous or not.
     */
    isDangerousAction?: boolean;

    /**
     * The optional custom width for the dialog.
     */
    customWidth?: number;
}

/**
 * Basic dialog to confirm an action.
 *
 * @param {IConfirmationDialog} props Props.
 * @returns {FunctionComponent<IConfirmationDialog>} Confirmation Dialog.
 */
export const ConfirmationDialog: FunctionComponent<IConfirmationDialog> = (props: IConfirmationDialog) => {
    /** Access to the fluent ui theme. */
    const theme = useTheme();
    /** Access to translations. */
    const { t } = useTranslation();

    /**
     * Properties of the modal object used for the dialog.
     */
    const modalProps: IModalProps = {
        isBlocking: true,
    };

    /**
     * Properties of the Dialog content.
     */
    const dialogContentProps: IDialogContentProps = {
        title: props.title,
        subText: props.text,
        type: DialogType.close,
    };

    /**
     * The styles of the confirm button.
     */
    const confirmationButtonStyles: IButtonStyles = {
        root: {
            color: props.isDangerousAction ? theme.palette.red : theme.palette.themePrimary,
            borderColor: props.isDangerousAction ? theme.palette.red : theme.palette.themePrimary,
        },
        rootHovered: {
            color: props.isDangerousAction ? theme.palette.red : theme.palette.themePrimary,
            borderColor: props.isDangerousAction ? theme.palette.red : theme.palette.themePrimary,
        },
        rootPressed: {
            color: props.isDangerousAction ? theme.palette.red : theme.palette.themePrimary,
            borderColor: props.isDangerousAction ? theme.palette.red : theme.palette.themePrimary,
        },
        rootFocused: {
            color: props.isDangerousAction ? theme.palette.red : theme.palette.themePrimary,
            borderColor: props.isDangerousAction ? theme.palette.red : theme.palette.themePrimary,
        },
    };

    return (
        <Dialog minWidth={props.customWidth && props.customWidth} hidden={!props.isOpen} dialogContentProps={dialogContentProps} onDismiss={props.close} modalProps={modalProps}>
            <DialogFooter>
                <DefaultButton text={t('ConfirmDialog_Abort')} onClick={props.close} />
                <PrimaryButton
                    styles={confirmationButtonStyles}
                    text={props.confirmButtonText}
                    onClick={() => {
                        props.confirmCallback();
                        props.close();
                    }}
                />
            </DialogFooter>
        </Dialog>
    );
};
