import { Action, action } from 'easy-peasy';
import { IUserPanelPurpose } from '../../types/IUserPanelPurpose';

/**
 * Interface for the users model.
 */
export interface IUsersModel {
    /**
     * The purpose and state of the entities panel.
     */
    panelPurpose: IUserPanelPurpose;
    /**
     * Updates the panel purpose.
     */
    updatePanelPurpose: Action<IUsersModel, IUserPanelPurpose>;
}

/**
 * Initial state of the users model.
 */
export const UsersModel: IUsersModel = {
    panelPurpose: { isOpen: false, mode: 'CREATE' },
    updatePanelPurpose: action((state, payload) => {
        state.panelPurpose = payload;
    }),
};
