import React, { FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react';
import {
    IColumn,
    SelectionMode,
    IDetailsRowProps,
    CommandBar,
    DetailsRowFields,
    IDetailsRowFieldsProps,
    IIconProps,
    DetailsRow,
    ICommandBarItemProps,
    Selection,
    MessageBarType,
    IconButton,
    Dropdown,
    IDropdownStyles,
    TooltipHost,
} from '@fluentui/react';
import { Table } from './Table';
import { ILetter } from '../types/ILetter';
import { deleteFromApi, postToApi, getFromApi, downloadWithSasToken } from '../helper/ApiHelper';
import { formatBytes } from '../helper/ByteHelper';
import { formatLetterTypeToText } from '../helper/LetterHelper';
import { CollectionTypeEnum } from '../types/CollectionTypeEnum';
import { ILetterDenyUpdate } from '../types/ILetterDenyUpdate';
import { ILetterGroupUpdate } from '../types/ILetterGroupUpdate';
import { ILetterIsReadUpdate } from '../types/ILetterIsReadUpdate';
import { useStoreActions, useStoreState } from '../store/Store';
import { EditRecipientsPanel } from '../panels/EditRecipientsPanel';
import { LetterViewPanel } from '../panels/LetterViewPanel';
import { useTranslation } from 'react-i18next';
import { IUser } from '../types/IUser';
import { ConfirmationDialog } from '../dialogs/ConfirmationDialog';
import { EditGroupRecipientsPanel } from '../panels/EditGroupRecipientsPanel';
import { DenialDialog } from '../dialogs/DenialDialog';
import { CommentsPanel } from '../panels/CommentsPanel';
import { IComment } from '../types/IComment';
import styled from 'styled-components';
import { LetterReadStatusFilter } from '../types/LetterReadStatusFilter';
import { formatToGermanDateWithTime } from '../helper/DateFormatHelper';
import { toolTipClickThroughStyles } from '../theme/Styles';

const TableControls = styled.div`
    display: flex;
    align-items: flex-end;
    width: 100%;
`;

/**
 * Properties of the download icon.
 */
const downloadIconProps: IIconProps = {
    iconName: 'Download',
};

/**
 * Letter table component.
 *
 * @returns {ReactElement} Route component wrapped in the provided layout.
 */
export const LetterTable: FunctionComponent = (): ReactElement => {
    /**
     * Access to translations.
     */
    const { t } = useTranslation();

    /** Global state of the logged in user. */
    const user = useStoreState<IUser | undefined>((state) => state.UserModel.user);
    /** The state representing if letters are being fetched. */
    const isFetching = useStoreState((state) => state.LettersModel.isFetching);
    /** Global state of letters. */
    const letters = useStoreState((state) => state.LettersModel.letters);
    /** The current page of the table. */
    const currentPage = useStoreState((state) => state.LettersModel.currentPage);
    /** The maximum available pages. */
    const maxAvailablePages = useStoreState((state) => state.LettersModel.maxPages);
    /** The searchString. */
    const searchString = useStoreState((state) => state.LettersModel.searchTerm);
    /** Global state representing if logged in user, group or collection letters are being shown. */
    const showType = useStoreState((state) => state.LettersModel.showType);
    /** Global state representing which groups letters are being shown. */
    const groupToShow = useStoreState((state) => state.LettersModel.groupToShow);
    /** The unique identifier of the selected user to represent. */
    const selectedUserToRepresentId = useStoreState((state) => state.LettersModel.selectedUserToRepresentId);
    /**The selected filter for the letter read status. */
    const letterReadStatusFilter = useStoreState((state) => state.LettersModel.letterReadStatusFilter);

    /** Action to update the current page. */
    const updateCurrentPage = useStoreActions((actions) => actions.LettersModel.updateCurrentPage);
    /** Action to search letters. */
    const searchLetters = useStoreActions((actions) => actions.LettersModel.searchLetters);
    /** Action to fetch the letters. */
    const fetchLetters = useStoreActions((actions) => actions.LettersModel.fetchLetters);
    /** Action to update the preview panel state. */
    const updatePreviewOpen = useStoreActions((actions) => actions.LetterViewerModel.updateIsOpen);
    /** Action to update the state oft the update recipients panel. */
    const updatePanelPurpose = useStoreActions((actions) => actions.LettersModel.updatePanelPurpose);
    /** Action to update the state oft the update group panel. */
    const updateGroupPanelPurpose = useStoreActions((actions) => actions.LettersModel.updateGroupPanelPurpose);
    /** Updates the global users panel purpose. */
    const updateUsersPanelPurpose = useStoreActions((actions) => actions.UsersModel.updatePanelPurpose);
    /** Updates the global notification. */
    const updateNotification = useStoreActions((actions) => actions.GlobalNotificationModel.updateNotification);
    /** Update the selected filter for the letter read status. */
    const updateLetterReadStatusFilter = useStoreActions((actions) => actions.LettersModel.updateLetterReadStatusFilter);

    /** The selected letter to display in the preview panel. */
    const [selectedLetter, setSelectedLetter] = useState<ILetter | undefined>();
    /** Whether the dialog to confirm letter deletion is open or not. */
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState<boolean>(false);
    /** Whether the dialog to deny letters is open or not. */
    const [isDenialDialogOpen, setIsDenialDialogOpen] = useState<boolean>(false);
    /** Whether the comments panel is open. */
    const [isCommentsPanelOpen, setIsCommentsPanelOpen] = useState<boolean>(false);
    /** The comments to show. */
    const [comments, setComments] = useState<IComment[]>([]);
    /** Whether the UI is currently locally busy or not. */
    const [isBusy, setIsBusy] = useState<boolean>(false);

    /**
     * Delete the selected letters.
     */
    const deleteOnClick = async () => {
        try {
            setIsBusy(true);
            const idsToDelete = selected.map((letter) => letter.id);
            await deleteFromApi('Letter/Batched', idsToDelete);
            updateNotification({ type: MessageBarType.success, message: t('DeleteLetters_Success') });
            fetchLetters(showType);
        } catch (error) {
            console.error(error);
            updateNotification({ type: MessageBarType.error, message: t('DeleteLetters_Error') });
        } finally {
            setIsBusy(false);
        }
    };

    /**
     * The selected letters.
     */
    const [selected, setSelected] = useState<ILetter[]>([]);

    /**
     * Fetches the letters initially.
     */
    useEffect(() => {
        if (searchString != null && searchString !== '') {
            searchLetters();
        } else {
            fetchLetters(showType);
        }
    }, [fetchLetters, currentPage, showType, searchString, searchLetters, groupToShow, selectedUserToRepresentId]);

    /**
     * The current selection of the details component.
     */
    const selection: Selection = new Selection({
        onSelectionChanged: () => {
            const currentSelectedItems = selection.getSelection();
            setSelected(currentSelectedItems as ILetter[]);
        },
    });

    /**
     * Checks whether most selected letters are read or not read.
     *
     * @returns {boolean} Result of the check.
     */
    const isReadCheck = (): boolean => {
        const total = selected.length;
        const count = selected.filter((sl) => letterIsRead(sl)).length;
        return count > total / 2;
    };

    /**
     * Checks whether the given letter is read or not read.
     *
     * @param {ILetter} letter The letter to test.
     * @returns {boolean} Result of the check.
     */
    const letterIsRead = (letter: ILetter): boolean => {
        if (showType === CollectionTypeEnum.AssignedToUser) {
            // we are in the view mode where the current user sees his letters only
            if (selectedUserToRepresentId !== undefined) {
                // letter has a representative user so that we find out if that user marked the letter as read in his options
                return letter.userLetters.some((ul) => ul.userId === selectedUserToRepresentId && ul.isRead);
            }
            // letter has no representative so we check if the current session user has marked the letter as read
            return letter.userLetters.some((ul) => ul.userId === user?.id && ul.isRead);
        }
        if (showType === CollectionTypeEnum.AssignedToGroup) {
            // we are in a group view which is shared between users
            return letter.isRead ?? false;
        }
        // we are in the collection postbox group -> always treat the letter as unread in the ui
        return false;
    };

    /**
     * Transforms ILetter[] into ILetterIsReadUpdate[] for the api call.
     *
     * @param {ILetter[]} letters The letters to transform for use in the update call.
     * @param {boolean[]} isRead The the is read value to use in the update call.
     * @returns {ILetterIsReadUpdate[]} The transformed elements.
     */
    const transformLettersForIsReadUpdate = (letters: ILetter[], isRead: boolean): ILetterIsReadUpdate[] => {
        const letterIsReadUpdates: ILetterIsReadUpdate[] = [];
        letters.forEach((letter) => {
            letterIsReadUpdates.push({
                id: letter.id,
                isRead: isRead,
                onlyForUser: showType === CollectionTypeEnum.AssignedToUser,
            });
        });
        return letterIsReadUpdates;
    };

    /**
     * Transforms ILetter[] into ILetterStatusUpdate[] for the api call.
     *
     * @param {ILetter[]} letters The letters to transform for use in the update call.
     * @param {string} denialReason The reason for denial.
     * @returns {ILetterDenyUpdate[]} The transformed elements.
     */
    const transformLettersForDeny = (letters: ILetter[], denialReason: string): ILetterDenyUpdate[] => {
        const letterDenyUpdates: ILetterDenyUpdate[] = [];
        if (user) {
            letters.forEach((letter) => {
                letterDenyUpdates.push({
                    id: letter.id,
                    userId: selectedUserToRepresentId ? selectedUserToRepresentId : user?.id,
                    denialReason: denialReason,
                });
            });
        }
        return letterDenyUpdates;
    };

    /**
     * Transforms ILetter[] into ILetterGroupUpdate[] for the api call.
     *
     * @param {ILetter[]} letters The letters to transform for use in the update call.
     * @param {string} denialReason The denial reason.
     * @returns {ILetterGroupUpdate[]} The transformed elements.
     */
    const transformLettersForGroupDenial = (letters: ILetter[], denialReason: string): ILetterGroupUpdate[] => {
        const letterGroupUpdates: ILetterGroupUpdate[] = [];
        if (user) {
            letters.forEach((letter) => {
                letterGroupUpdates.push({
                    id: letter.id,
                    denialReason: denialReason,
                });
            });
        }
        return letterGroupUpdates;
    };

    /**
     * Update the is read of the chosen letters based on the update model.
     *
     * @param {ILetterIsReadUpdate[]} letterRecipientUpdates The update models.
     */
    const updateIsRead = async (letterRecipientUpdates: ILetterIsReadUpdate[]) => {
        await postToApi<ILetter[]>(`Letter/IsRead`, letterRecipientUpdates);
        fetchLetters(showType);
    };

    /**
     * Update the chosen letters to deny them based on the update model.
     *
     * @param {ILetterDenyUpdate[]} letterDenyUpdates The update models.
     */
    const updateDeny = async (letterDenyUpdates: ILetterDenyUpdate[]) => {
        await postToApi<ILetter[]>(`Letter/Deny`, letterDenyUpdates);
        fetchLetters(showType);
    };

    /**
     * Update the chosen letters to them based on the update model.
     *
     * @param {ILetterGroupUpdate[]} letterGroupUpdates The update models.
     */
    const updateGroup = async (letterGroupUpdates: ILetterGroupUpdate[]) => {
        await postToApi<ILetter[]>(`Letter/Groups`, letterGroupUpdates);
        fetchLetters(showType);
    };

    /**
     * Switches Is Read status of the selected letters.
     */
    const updateIsReadOnClick = () => {
        updateIsRead(transformLettersForIsReadUpdate(selected, !isReadCheck()));
    };

    /**
     * Ensures that the state of the letter is set to read for the current user.
     */
    const updateLetterStatusAfterPreview = () => {
        if (showType === CollectionTypeEnum.AssignedToNone) {
            // do not change the state if we are in the global group
            return;
        }
        if (selectedLetter !== undefined) {
            updateIsRead(transformLettersForIsReadUpdate([selectedLetter], true));
        }
    };

    /**
     * Deny being the recipient.
     *
     * @param {string} denialReason The update models.
     */
    const updateDenyCallback = async (denialReason: string) => {
        try {
            setIsBusy(true);
            if (showType === CollectionTypeEnum.AssignedToGroup) {
                await updateGroup(transformLettersForGroupDenial(selected, denialReason));
            } else {
                await updateDeny(transformLettersForDeny(selected, denialReason));
            }
            updateNotification({ type: MessageBarType.success, message: t('DenyLetters_Success') });
        } catch (error) {
            console.error(error);
            updateNotification({ type: MessageBarType.error, message: t('DenyLetters_Error') });
        } finally {
            setIsBusy(false);
        }
    };

    /**
     * Handle deny button click.
     */
    const updateDenyOnClick = () => {
        setIsDenialDialogOpen(true);
    };

    /**
     * Downloads selected letters.
     */
    const downloadSelected = async () => {
        const lettersToDownload = selected;
        lettersToDownload.forEach(async (letter) => {
            const sasToken = await getFromApi<string>(`Letter/GenerateDownloadToken/${letter.id}.pdf`);
            const link = document.createElement('a');
            link.download = letter.originalFilename;
            link.href = await downloadWithSasToken(sasToken, letter.originalFilename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };

    /**
     * Downloads selected letters and set them to is read.
     */
    const downloadOnClick = () => {
        downloadSelected();
        updateIsRead(transformLettersForIsReadUpdate(selected, true));
    };

    /**
     * Builds the command bar items.
     *
     * @returns {ICommandBarItemProps[]} The command bar items.
     */
    const getCommandBarItems = (): ICommandBarItemProps[] => {
        const items: ICommandBarItemProps[] = [];
        items.push({
            disabled: selected.length === 0,
            key: 'Home_TableMenu_Download',
            text: t('Home_TableMenu_Download'),
            iconOnly: true,
            iconProps: downloadIconProps,
            onClick: downloadOnClick,
        });
        if (showType === CollectionTypeEnum.AssignedToUser || showType === CollectionTypeEnum.AssignedToGroup) {
            items.push({
                disabled: selected.length === 0,
                key: 'Home_TableMenu_Change_IsRead',
                text: isReadCheck() ? t('Home_TableMenu_Change_IsRead_True') : t('Home_TableMenu_Change_IsRead_False'),
                onClick: updateIsReadOnClick,
            });
            items.push({
                disabled: selected.length === 0,
                key: 'Home_TableMenu_Deny',
                text: t('Home_TableMenu_Deny'),
                onClick: updateDenyOnClick,
            });
        }
        if (showType === CollectionTypeEnum.AssignedToGroup) {
            items.push({
                disabled: selected.length === 0,
                key: 'Home_TableMenu_Change_Recipient',
                text: t('Home_TableMenu_Change_Recipient'),
                onClick: () => updatePanelPurpose({ isOpen: true }),
            });
        }
        if (showType === CollectionTypeEnum.AssignedToNone) {
            items.push({
                disabled: selected.length === 0,
                key: 'Home_TableMenu_Change_RecipientGroup',
                text: t('Home_TableMenu_Change_RecipientGroup'),
                onClick: () => updateGroupPanelPurpose({ isOpen: true }),
            });
            items.push({
                disabled: selected.length === 0,
                key: 'Home_TableMenu_Change_Recipient',
                text: t('Home_TableMenu_Change_Recipient'),
                onClick: () => updatePanelPurpose({ isOpen: true }),
            });
            items.push({
                disabled: selected.length === 0,
                key: 'Home_TableMenu_Change_InviteUser',
                text: t('Home_TableMenu_Change_InviteUser'),
                onClick: () => updateUsersPanelPurpose({ isOpen: true, mode: 'CREATE', letters: selected }),
            });
        }
        items.push({
            disabled: selected.length === 0,
            key: 'Home_TableMenu_DeleteLetter',
            text: t('Home_TableMenu_DeleteLetter'),
            onClick: () => setIsConfirmDeleteDialogOpen(true),
        });
        return items;
    };

    /**
     * Handles click on a table row item.
     *
     * @param {ILetter} item the item clicked on.
     */
    const rowClick = (item: ILetter) => {
        setSelectedLetter(item);
        updatePreviewOpen(true);
    };

    /**
     * The columns of the table.
     *
     * @returns {IColumn[]} The columns
     */
    const columns = useCallback((): IColumn[] => {
        const columns: IColumn[] = [];
        columns.push({
            key: 'spacing',
            name: '',
            minWidth: 1,
            maxWidth: 7,
            columnActionsMode: 0,
        });
        columns.push({
            key: 'label',
            fieldName: 'label',
            name: t('LetterTable_Label'),
            minWidth: 150,
            maxWidth: 250,
            columnActionsMode: 0,
            onRender: (item: ILetter) => (
                <TooltipHost styles={toolTipClickThroughStyles} content={item.label}>
                    {item.label}
                </TooltipHost>
            ),
        });
        columns.push({
            key: 'letterType',
            fieldName: 'letterType',
            name: t('LetterTable_letterType'),
            minWidth: 80,
            maxWidth: 120,
            columnActionsMode: 0,
            onRender: (item: ILetter) => (item.letterType !== undefined ? formatLetterTypeToText(item.letterType, t) : t('LettersTable_LetterType_Unknown')),
        });
        columns.push({
            key: 'sender',
            fieldName: 'sender',
            name: t('LetterTable_sender'),
            minWidth: 80,
            maxWidth: 170,
            columnActionsMode: 0,
            onRender: (item: ILetter) => (
                <TooltipHost styles={toolTipClickThroughStyles} content={item.sender}>
                    {item.sender}
                </TooltipHost>
            ),
        });
        columns.push({
            key: 'recipient',
            fieldName: 'recipient',
            name: t('LetterTable_parsedRecipient'),
            minWidth: 80,
            maxWidth: 170,
            columnActionsMode: 0,
            onRender: (item: ILetter) => (
                <TooltipHost styles={toolTipClickThroughStyles} content={item.recipient}>
                    {item.recipient}
                </TooltipHost>
            ),
        });
        if (showType === CollectionTypeEnum.AssignedToNone) {
            columns.push({
                key: 'invite',
                fieldName: 'invite',
                name: t('LetterTable_Invite'),
                minWidth: 150,
                maxWidth: 300,
                columnActionsMode: 0,
                onRender: (item: ILetter) => (
                    <TooltipHost styles={toolTipClickThroughStyles} content={item.invite?.email ? item.invite?.email : ''}>
                        {item.invite?.email ? item.invite?.email : ''}
                    </TooltipHost>
                ),
            });
        }
        columns.push({
            key: 'firstLine',
            fieldName: 'firstLine',
            name: t('LetterTable_firstLine'),
            minWidth: 100,
            maxWidth: 300,
            columnActionsMode: 0,
            onRender: (item: ILetter) => (
                <TooltipHost styles={toolTipClickThroughStyles} content={item.firstLine}>
                    {item.firstLine}
                </TooltipHost>
            ),
        });
        columns.push({
            key: 'dateReceived',
            fieldName: 'dateReceived',
            name: t('LetterTable_dateReceived'),
            minWidth: 150,
            maxWidth: 150,
            columnActionsMode: 0,
            onRender: (item: ILetter) => formatToGermanDateWithTime(item.dateCreated),
        });
        columns.push({
            key: 'originalFilename',
            fieldName: 'originalFilename',
            name: t('LetterTable_originalFileName'),
            minWidth: 270,
            maxWidth: 270,
            columnActionsMode: 0,
            onRender: (item: ILetter) => (
                <TooltipHost styles={toolTipClickThroughStyles} content={item.originalFilename}>
                    {item.originalFilename}
                </TooltipHost>
            ),
        });
        columns.push({
            key: 'fileSize',
            fieldName: 'fileSize',
            name: t('LetterTable_fileSize'),
            minWidth: 80,
            maxWidth: 80,
            columnActionsMode: 0,
            onRender: (item: ILetter) => (item?.size ? formatBytes(item.size, 2) : ''),
        });
        if (showType === CollectionTypeEnum.AssignedToNone) {
            columns.push({
                key: 'denials',
                fieldName: 'denials',
                name: t('LetterTable_denials'),
                minWidth: 70,
                columnActionsMode: 0,
                onRender: (item: ILetter) => (
                    <IconButton
                        style={{ margin: 'auto' }}
                        disabled={!(item.denialComments && item.denialComments.length > 0)}
                        iconProps={{ iconName: 'Comment' }}
                        onClick={() => {
                            setComments(item.denialComments ?? []);
                            setIsCommentsPanelOpen(true);
                        }}
                    />
                ),
            });
        }
        return columns;
    }, [showType, t]);

    /**
     * Custom row render to render read letters differently.
     *
     * @param {IDetailsRowProps} props The original row props.
     * @param {(props?: IDetailsRowProps) => Element | null} defaultRender The default renderer.
     * @returns {Element} The rendered row or null.
     */
    const onRenderRow = (props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => JSX.Element | null): JSX.Element | null => {
        if (defaultRender) {
            if (props) {
                const letter = props.item as ILetter;
                const isRead = letterIsRead(letter);
                props.styles = {
                    root: {
                        cursor: 'pointer',
                        fontWeight: isRead ? 'normal' : '600',
                    },
                };
                return (
                    <div
                        onClick={(e) => {
                            const target = e.target as HTMLElement;
                            if (target.className.includes('ms-DetailsRow-cell')) {
                                rowClick(letter);
                            }
                        }}
                    >
                        <DetailsRow rowFieldsAs={renderRowFields} {...props} />
                    </div>
                );
            }
        }
        return null;
    };

    /**
     * Renders row fields without the functionality to select the row on click.
     *
     * @param {IDetailsRowFieldsProps} props Default props.
     * @returns {Element} Rendered row fields.
     */
    const renderRowFields = (props: IDetailsRowFieldsProps) => {
        return (
            <span data-selection-disabled={true}>
                <DetailsRowFields {...props} />
            </span>
        );
    };

    /**
     * Styles of the dropdown to select the read state of the letters.
     */
    const letterReadStateDropdownStyles: Partial<IDropdownStyles> = {
        root: { minWidth: 100, marginLeft: 'auto', marginRight: '24px' },
    };

    return (
        <>
            <CommentsPanel isOpen={isCommentsPanelOpen} comments={comments} onDismiss={() => setIsCommentsPanelOpen(false)} />
            <DenialDialog isOpen={isDenialDialogOpen} confirmCallback={updateDenyCallback} close={() => setIsDenialDialogOpen(false)} />
            <ConfirmationDialog
                isOpen={isConfirmDeleteDialogOpen}
                close={() => setIsConfirmDeleteDialogOpen(false)}
                confirmButtonText={t('Delete')}
                isDangerousAction
                text={t('Home_DeleteLetter_Text')}
                title={t('Home_DeleteLetter_Title')}
                confirmCallback={deleteOnClick}
            />
            <EditGroupRecipientsPanel letters={selected} />
            <EditRecipientsPanel letters={selected} />
            <LetterViewPanel
                selectedLetter={selectedLetter}
                updateLetterStatus={() => {
                    updateLetterStatusAfterPreview();
                }}
            />
            <TableControls>
                <CommandBar items={getCommandBarItems()} />
                {showType !== CollectionTypeEnum.AssignedToNone && (
                    <Dropdown
                        styles={letterReadStateDropdownStyles}
                        label={t('LetterReadStatusFilter_Label')}
                        options={[
                            { key: LetterReadStatusFilter.Undefined, text: t('LetterReadStatusFilter_All') },
                            { key: LetterReadStatusFilter.Read, text: t('LetterReadStatusFilter_Read') },
                            { key: LetterReadStatusFilter.Unread, text: t('LetterReadStatusFilter_Unread') },
                        ]}
                        selectedKey={letterReadStatusFilter}
                        onChange={(_, option) => {
                            updateLetterReadStatusFilter(option?.key as LetterReadStatusFilter);
                            fetchLetters(showType);
                        }}
                    />
                )}
            </TableControls>
            <Table
                columns={columns()}
                data={letters}
                isBusy={isFetching || isBusy}
                currentPage={currentPage}
                availablePages={maxAvailablePages}
                navigateToEnd={() => updateCurrentPage(maxAvailablePages)}
                navigateToStart={() => updateCurrentPage(1)}
                navigateBackward={() => updateCurrentPage(currentPage - 1)}
                navigateForward={() => updateCurrentPage(currentPage + 1)}
                selectionMode={SelectionMode.multiple}
                selection={selection}
                onRenderRow={onRenderRow}
            />
        </>
    );
};
