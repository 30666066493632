import { Action, action } from 'easy-peasy';

/**
 * Interface for the navigation model.
 */
export interface INavigationModel {
    /**
     * The current selected navigation key.
     */
    selectedNavKey: string;
    /**
     * Action to update the current selected navigation key.
     */
    updateSelectedNavKey: Action<INavigationModel, string>;
    /**
     * Whether the nav menu is currently collapsed or not.
     */
    isCollapsed?: boolean;
    /**
     * Action to update the current collapse state.
     */
    updateIsCollapsed: Action<INavigationModel, boolean>;
}

/**
 * Initial state of the navigation model.
 */
export const NavigationModel: INavigationModel = {
    selectedNavKey: '/',
    updateSelectedNavKey: action((state, payload) => {
        state.selectedNavKey = payload;
    }),
    isCollapsed: undefined,
    updateIsCollapsed: action((state, payload) => {
        state.isCollapsed = payload;
    }),
};
