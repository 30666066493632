/**
 * Function turning a byte value into a reasonably displayable value.
 *
 * @param {number} bytes The byte value as a number to convert.
 * @param {number} decimals Optional parameter to determine decimal accuracy.
 * @returns {string} The String representing a reasonably displayable value with its unit and the specified decimals.
 */
export const formatBytes = (bytes: number, decimals = 2): string => {
    if (bytes === 0) return '0 Bytes';
    if (bytes === 1) return '1 Byte';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    if (i >= sizes.length) {
        throw Error('Byte value bigger than YB please check value.');
    }
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
