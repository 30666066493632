import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Text } from '@fluentui/react/lib/Text';
import { DefaultButton, Panel, PanelType, Stack, IStackStyles } from '@fluentui/react';
import { useStoreState, useStoreActions } from '../store/Store';
import { ToBeDeletedLetterTable } from '../table/ToBeDeletedLetterTable';

const PanelHeader = styled.div`
    padding: 0 0 20px 20px;
    width: 100%;
`;

const BodyWrapper = styled.div`
    display: flex;
    flex: 1;
    padding: 20px;
`;

/**
 * Panel component to display a single employee for edit or new creation.
 *
 * @returns {FunctionComponent} EditUser Panel.
 */
export const ToBeDeletedLettersPanel: FunctionComponent = () => {
    /** Access to translations. */
    const { t } = useTranslation();

    /**
     * The state representing if the ToBeDeletedLettersPanel is open.
     */
    const isTobDeletedOpen = useStoreState((state) => state.ToBeDeletedLettersModel.isTobDeletedOpen);

    /**
     * The Action to update the state representing if the ToBeDeletedLettersPanel is open
     */
    const updateIsTobDeletedOpen = useStoreActions((actions) => actions.ToBeDeletedLettersModel.updateIsTobDeletedOpen);

    /**
     * The stack styles.
     */
    const stackStyles: IStackStyles = {
        root: {
            flex: 1,
        },
    };

    /**
     * Header of the panel.
     *
     * @returns {Element} The header element.
     */
    const onRenderHeader = () => (
        <PanelHeader>
            <Text variant={'large'}>{t('ToBeDeletedLettersPanel_Header')}</Text>
        </PanelHeader>
    );

    /**
     * The body of the panel.
     *
     * @returns {HTMLElement} The body.
     */
    const onRenderBody = () => (
        <BodyWrapper>
            <Stack styles={stackStyles}>
                <ToBeDeletedLetterTable />
            </Stack>
        </BodyWrapper>
    );
    /**
     * Footer of the panel.
     *
     * @returns {Element} The footer element.
     */
    const onRenderFooter = () => (
        <Stack horizontal horizontalAlign={'end'} verticalAlign={'end'} tokens={{ childrenGap: 10, padding: '0 20px 20px 20px' }}>
            <DefaultButton text={t('EditUserPanel_Footer_CancelButton')} onClick={() => updateIsTobDeletedOpen(false)} />
        </Stack>
    );
    return (
        <Panel
            allowTouchBodyScroll
            isOpen={isTobDeletedOpen}
            isFooterAtBottom
            isBlocking
            isLightDismiss
            type={PanelType.custom}
            onRenderHeader={onRenderHeader}
            onDismiss={() => updateIsTobDeletedOpen(false)}
            onRenderBody={onRenderBody}
            onRenderFooter={onRenderFooter}
        />
    );
};
