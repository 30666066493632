import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components/macro';
import {
    DetailsList,
    IColumn,
    SelectionMode,
    ConstrainMode,
    DetailsListLayoutMode,
    IDetailsHeaderProps,
    ScrollablePane,
    ScrollbarVisibility,
    Sticky,
    StickyPositionType,
    IDetailsListStyles,
    useTheme,
    IRenderFunction,
    IDetailsRowProps,
    ISelection,
    IObjectWithKey,
    MarqueeSelection,
} from '@fluentui/react';
import { BusySpinnerOverlay } from '../busyIndicators/BusySpinnerOverlay';
import { PaginationControls } from './PaginationControls';

const TableContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    position: relative;
`;

const PaginationControlsWrapper = styled.div`
    margin-top: auto;
    padding: 15px;
`;

/**
 * Interface for the properties of the table.
 */
export interface ITable {
    /** The columns of the table. */
    columns: IColumn[];

    /** The data to display as rows in the table. */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[];

    /** Whether the table is busy or not. */
    isBusy: boolean;

    /** The number of the current displayed page. */
    currentPage?: number;

    /** The number of the maximum available pages. */
    availablePages?: number;

    /** Callback to navigate one page backward. */
    navigateBackward?: () => void;

    /** Callback to navigate one page forward. */
    navigateForward?: () => void;

    /** Callback to navigate to the first page. */
    navigateToStart?: () => void;

    /** Callback to navigate to the last page. */
    navigateToEnd?: () => void;

    /** Callback to override the default row rendering. */
    onRenderRow?: IRenderFunction<IDetailsRowProps>;

    /** The selection mode of the table. */
    selectionMode?: SelectionMode;
    /** The selection of the table. */
    selection?: ISelection<IObjectWithKey>;
}

/**
 * Basic table component.
 *
 * @param {ITable} props Table properties.
 * @returns {ReactElement} Route component wrapped in the provided layout.
 */
export const Table: FunctionComponent<ITable> = (props: ITable): ReactElement => {
    const theme = useTheme();

    /**
     * Styles of the details list component.
     */
    const detailsListStyles: Partial<IDetailsListStyles> = {
        root: {
            '.ms-DetailsRow': {
                color: theme.palette.black,
                fontSize: '13px',
            },
            '.ms-DetailsRow-cell': {
                display: 'flex',
                alignItems: 'center',
            },
        },
        headerWrapper: {
            '.ms-DetailsHeader-cellName': {
                fontWeight: 'regular',
                color: theme.palette.neutralSecondary,
                fontSize: 11,
            },
        },
    };

    return (
        <TableContainer>
            <TableWrapper>
                <BusySpinnerOverlay isBusy={props.isBusy} />
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    {(props.selectionMode ?? SelectionMode.none) !== SelectionMode.none && props.selection ? (
                        <MarqueeSelection selection={props.selection}>
                            <DetailsList
                                styles={detailsListStyles}
                                columns={props.columns}
                                items={props.data}
                                selection={props.selection}
                                onRenderRow={props.onRenderRow}
                                layoutMode={DetailsListLayoutMode.justified}
                                constrainMode={ConstrainMode.unconstrained}
                                selectionMode={props.selectionMode ?? SelectionMode.none}
                                onRenderDetailsHeader={(
                                    headerProps: IDetailsHeaderProps | undefined,
                                    defaultRender: ((props?: IDetailsHeaderProps | undefined) => JSX.Element | null) | undefined
                                ) => {
                                    if (headerProps) {
                                        headerProps.styles = {
                                            ...headerProps.styles,
                                            root: {
                                                backgroundColor: 'transparent',
                                            },
                                        };
                                        const newColumns = headerProps.columns.map((column) => {
                                            const newColumn: IColumn = {
                                                ...column,
                                                styles: {
                                                    cellName: {
                                                        fontWeight: 'regular',
                                                        color: theme.palette.neutralSecondary,
                                                        fontSize: 11,
                                                    },
                                                },
                                            };
                                            return newColumn;
                                        });
                                        headerProps = {
                                            ...headerProps,
                                            columns: newColumns,
                                        };
                                    }
                                    return (
                                        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                                            {defaultRender && <div>{defaultRender(headerProps)}</div>}
                                        </Sticky>
                                    );
                                }}
                            />
                        </MarqueeSelection>
                    ) : (
                        <DetailsList
                            styles={detailsListStyles}
                            columns={props.columns}
                            items={props.data}
                            selection={props.selection}
                            onRenderRow={props.onRenderRow}
                            layoutMode={DetailsListLayoutMode.justified}
                            constrainMode={ConstrainMode.unconstrained}
                            selectionMode={props.selectionMode ?? SelectionMode.none}
                            onRenderDetailsHeader={(
                                headerProps: IDetailsHeaderProps | undefined,
                                defaultRender: ((props?: IDetailsHeaderProps | undefined) => JSX.Element | null) | undefined
                            ) => {
                                if (headerProps) {
                                    headerProps.styles = {
                                        ...headerProps.styles,
                                        root: {
                                            backgroundColor: 'transparent',
                                        },
                                    };
                                    const newColumns = headerProps.columns.map((column) => {
                                        const newColumn: IColumn = {
                                            ...column,
                                            styles: {
                                                cellName: {
                                                    fontWeight: 'regular',
                                                    color: theme.palette.neutralSecondary,
                                                    fontSize: 11,
                                                },
                                            },
                                        };
                                        return newColumn;
                                    });
                                    headerProps = {
                                        ...headerProps,
                                        columns: newColumns,
                                    };
                                }
                                return (
                                    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                                        {defaultRender && <div>{defaultRender(headerProps)}</div>}
                                    </Sticky>
                                );
                            }}
                        />
                    )}
                </ScrollablePane>
            </TableWrapper>
            {props.availablePages != null &&
                props.availablePages > 1 &&
                props.currentPage != null &&
                props.currentPage > 0 &&
                props.navigateBackward &&
                props.navigateForward &&
                props.navigateToEnd &&
                props.navigateToStart && (
                <PaginationControlsWrapper>
                    <PaginationControls
                        isBusy={props.isBusy}
                        currentPage={props.currentPage}
                        availablePages={props.availablePages}
                        navigateBackward={props.navigateBackward}
                        navigateForward={props.navigateForward}
                        navigateToEnd={props.navigateToEnd}
                        navigateToStart={props.navigateToStart}
                    />
                </PaginationControlsWrapper>
            )}
        </TableContainer>
    );
};
