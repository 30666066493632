import React, { FunctionComponent, ReactElement, useMemo } from 'react';
import { DefaultButton, IButtonStyles, Separator, Stack, Text, useTheme } from '@fluentui/react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '../store/Store';
import { useStoreActions } from '../store/Store';
import { IUser } from '../types/IUser';
import { RoleEnum } from '../types/RoleEnum';
import { CollectionTypeEnum } from '../types/CollectionTypeEnum';
import { LetterTable } from '../table/LetterTable';

/**
 * Props of the HomeViewContainer.
 */
const HomeViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

/**
 * Props of the top container.
 */
const TopContainer = styled.div`
    display: flex;
    padding: 22px 35px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
`;

/**
 * Props of the bottom container.
 */
const BottomContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

/**
 *
 */
const FilterContainer = styled.div``;

/**
 * View component for the home view.
 *
 * @returns {ReactElement} Home View.
 */
export const HomeView: FunctionComponent = (): ReactElement => {
    /**
     * Access to translations.
     */
    const { t } = useTranslation();

    /**
     * Access to fluent ui theme.
     */
    const theme = useTheme();
    /**
     * Global state of the logged in user.
     */
    const user = useStoreState<IUser | undefined>((state) => state.UserModel.user);

    /**
     * The user representations connected to the logged in user.
     */
    const userRepresentations = useStoreState((state) => state.UserModel.userRepresentations);

    /**
     * The Action to update the global state representing if logged in user, group or collection letters are being shown.
     */
    const updateShowType = useStoreActions((actions) => actions.LettersModel.updateShowType);

    /**
     *  Global state representing which groups letters are being shown.
     */
    const groupToShow = useStoreState((state) => state.LettersModel.groupToShow);

    /**
     * The Action to update the global state representing which groups letters are being shown.
     */
    const updateGroupToShow = useStoreActions((actions) => actions.LettersModel.updateGroupToShow);

    /**
     * Action to update the current page.
     */
    const updateCurrentPage = useStoreActions((actions) => actions.LettersModel.updateCurrentPage);

    /**
     * Action to update the unique identifier of the selected user to represent.
     */
    const updateSelectedUserToRepresentId = useStoreActions((actions) => actions.LettersModel.updateSelectedUserToRepresentId);

    /**
     *  Global state representing if logged in user, group or collection letters are being shown.
     */
    const showType = useStoreState((state) => state.LettersModel.showType);

    /**
     * The unique identifier of the selected user to represent.
     */
    const selectedUserToRepresentId = useStoreState((state) => state.LettersModel.selectedUserToRepresentId);

    /** All mailboxes of other users the logged in user has access to. */
    const userMailboxesToRepresent = useMemo(() => userRepresentations.filter((ur) => ur.representativeId === user?.id), [user?.id, userRepresentations]);

    /**
     * Styles of the show buttons if the button is active.
     */
    const showButtonActiveStyles: IButtonStyles = {
        root: {
            borderBottomColor: theme.palette.themePrimary,
            borderBottomWidth: 2,
            borderBottomStyle: 'solid',
        },
        rootHovered: {
            borderBottomColor: theme.palette.themePrimary,
            borderBottomWidth: 2,
            borderBottomStyle: 'solid',
        },
        rootPressed: {
            borderBottomColor: theme.palette.themePrimary,
            borderBottomWidth: 2,
            borderBottomStyle: 'solid',
        },
    };

    /**
     * Styles of the show buttons if the button is not active.
     */
    const showButtonInactiveStyles: IButtonStyles = {
        root: {
            borderBottomWidth: 2,
            borderBottomColor: 'transparent',
        },
        rootHovered: {
            borderBottomWidth: 2,
            borderBottomColor: 'transparent',
        },
        rootPressed: {
            borderBottomWidth: 2,
            borderBottomColor: 'transparent',
        },
    };

    /**
     * If the logged in user is letter admin.
     */
    const isLetterAdmin = user?.userRoles ? user.userRoles[0].roleId === RoleEnum.LetterAdmin : false;

    /**
     * If the logged in user is admin.
     */
    const isAdmin = user?.userRoles ? user.userRoles[0].roleId === RoleEnum.Admin : false;

    /**
     * Renders a single switch to group table button.
     *
     * @param {string} text the text to show on the button.
     * @param {number} showGroup the group id to set as new groupToShow value on click.
     * @returns {Element} The separator and button elements.
     */
    const renderGroupButton = (text: string, showGroup: number) => {
        return (
            <React.Fragment key={text + showGroup}>
                <Separator vertical />
                <DefaultButton
                    styles={showType === CollectionTypeEnum.AssignedToGroup && groupToShow === showGroup ? showButtonActiveStyles : showButtonInactiveStyles}
                    text={text}
                    onClick={() => {
                        updateCurrentPage(1);
                        updateGroupToShow(showGroup);
                        updateShowType(CollectionTypeEnum.AssignedToGroup);
                    }}
                />
            </React.Fragment>
        );
    };

    /**
     * Renders a the switch to group table buttons for the groups of the user.
     *
     * @returns {Element[]} The array of button elements and separators.
     */
    const renderGroupButtons = () => {
        const buttons: JSX.Element[] = [];
        user?.userGroups?.forEach((group) => {
            if (group.group?.label) {
                const text = t('Home_Button_Group', { group: group.group?.label });
                buttons.push(renderGroupButton(text, group.groupId));
            }
        });
        return buttons;
    };

    return (
        <HomeViewContainer>
            <TopContainer>
                <Text variant={'xxLarge'}>{t('Home_Title')}</Text>
            </TopContainer>
            <Stack horizontal>
                {isAdmin || isLetterAdmin ? (
                    <FilterContainer>
                        <Stack horizontal>
                            <DefaultButton
                                styles={showType === CollectionTypeEnum.AssignedToUser && !selectedUserToRepresentId ? showButtonActiveStyles : showButtonInactiveStyles}
                                text={t('Home_Button_ShowRecipient')}
                                onClick={() => {
                                    updateSelectedUserToRepresentId(undefined);
                                    updateShowType(CollectionTypeEnum.AssignedToUser);
                                }}
                            />
                            {user?.userGroups ? renderGroupButtons() : null}
                            <Separator vertical />
                            <DefaultButton
                                styles={showType === CollectionTypeEnum.AssignedToNone ? showButtonActiveStyles : showButtonInactiveStyles}
                                text={t('Home_Button_ShowCollection')}
                                onClick={() => {
                                    updateSelectedUserToRepresentId(undefined);
                                    updateGroupToShow(undefined);
                                    updateShowType(CollectionTypeEnum.AssignedToNone);
                                }}
                            />
                        </Stack>
                    </FilterContainer>
                ) : user?.userGroups ? (
                    <FilterContainer>
                        <Stack horizontal>
                            <DefaultButton
                                styles={showType === CollectionTypeEnum.AssignedToUser && !selectedUserToRepresentId ? showButtonActiveStyles : showButtonInactiveStyles}
                                text={t('Home_Button_ShowRecipient')}
                                onClick={() => {
                                    updateSelectedUserToRepresentId(undefined);
                                    updateShowType(CollectionTypeEnum.AssignedToUser);
                                }}
                            />
                            {renderGroupButtons()}
                        </Stack>
                    </FilterContainer>
                ) : null}
                {userMailboxesToRepresent && userMailboxesToRepresent.length > 0 && (
                    <FilterContainer>
                        <Stack horizontal>
                            <Separator vertical />
                            {userMailboxesToRepresent.map((mailbox) => (
                                <DefaultButton
                                    key={`user-mailbox-${mailbox.userToRepresentId}`}
                                    styles={
                                        showType === CollectionTypeEnum.AssignedToUser && selectedUserToRepresentId === mailbox.userToRepresentId
                                            ? showButtonActiveStyles
                                            : showButtonInactiveStyles
                                    }
                                    text={mailbox.userToRepresentLabel}
                                    onClick={() => {
                                        updateSelectedUserToRepresentId(mailbox.userToRepresentId);
                                        updateShowType(CollectionTypeEnum.AssignedToUser);
                                    }}
                                />
                            ))}
                        </Stack>
                    </FilterContainer>
                )}
            </Stack>
            <BottomContainer>
                <Separator style={{ marginBottom: '20px' }} />
                <LetterTable />
            </BottomContainer>
        </HomeViewContainer>
    );
};
