/**
 * Determines whether the current browser is an Internet Explorer.
 *
 * @returns {boolean} True if the current browser is an Internet Explorer or Edge.
 */
export const isIE = (): boolean => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ') > -1;
    const msie11 = ua.indexOf('Trident/') > -1;
    // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
    const isEdge = ua.indexOf('Edge/') > -1;
    return msie || msie11 || isEdge;
};
