import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import History from '../navigation/BrowserHistory';

/**
 * React plugin.
 */
const reactPlugin = new ReactPlugin();

/**
 * Application Insights instance.
 */
let appInsights;

/**
 * Configures and initializes application insights.
 *
 * @param {string} aiKey Application insights instrumentation key.
 * @returns {ApplicationInsights} Application Insights configuration.
 */
const configureAi = (aiKey: string): ApplicationInsights => {
    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: aiKey,
            disableFetchTracking: false,
            enableAutoRouteTracking: true,
            enableRequestHeaderTracking: true,
            autoTrackPageVisitTime: true,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: History },
            },
        },
    });
    appInsights.loadAppInsights();
    return appInsights;
};

export { reactPlugin, configureAi };
