import React, { FunctionComponent, ReactElement } from 'react';
import { Icon, IIconStyles, IStackStyles, IStackTokens, Stack, Text } from '@fluentui/react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
    padding: 24px 35px;
`;

/**
 * View component for the not authorized view.
 *
 * @returns {ReactElement} Not authorized view.
 */
export const NotAuthorizedView: FunctionComponent = (): ReactElement => {
    /** Access to translations. */
    const { t } = useTranslation();

    /**
     * Basic stack layout tokens.
     */
    const stackTokens: IStackTokens = {
        childrenGap: 50,
    };

    /**
     * The stack styles.
     */
    const stackStyles: IStackStyles = {
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
        },
    };

    /**
     * The styles of the icon.
     */
    const iconStyles: IIconStyles = {
        root: {
            fontSize: 100,
        },
    };

    return (
        <Container>
            <Stack tokens={stackTokens} styles={stackStyles}>
                <Icon iconName="ProtectRestrict" styles={iconStyles} />
                <Text variant={'xxLarge'}>{t('NotAuthorized_Text')}</Text>
            </Stack>
        </Container>
    );
};
