import { Action, action } from 'easy-peasy';

/**
 * Interface for the letters model.
 */
export interface ILetterViewerModel {
    /**
     * Whether the Panel is open.
     */
    isOpen: boolean;
    /**
     * Action to update the theme choice.
     */
    updateIsOpen: Action<ILetterViewerModel, boolean>;
}

/**
 * Initial state of the letters model.
 */
export const LetterViewerModel: ILetterViewerModel = {
    isOpen: false,
    updateIsOpen: action((state, payload) => {
        state.isOpen = payload;
    }),
};
