import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { i18n } from 'i18next';
import styled from 'styled-components/macro';
import { Routes } from './navigation/Routes';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { _config } from '.';
import { I18nextProvider } from 'react-i18next';
import { buildMsalConfig } from './helper/MsalHelper';
import { initialize } from './helper/localization/Localization';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { configureAi, reactPlugin } from './helper/AiHelper';
import { useStoreActions, useStoreState } from './store/Store';
import { PartialTheme, ThemeProvider } from '@fluentui/react';
import { DarkPalette, DefaultComponentStyles, DefaultFontStyle, Palette, Fonts, SemanticColors, DarkSemanticColors } from './theme/Theme';
import { MsalEventHandler } from './components/MsalEventHandler';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const AppContainer = styled.div`
    display: flex;
    min-height: 100vh;
    height: 100vh;
    min-width: 100%;
    width: 100%;
`;

type ChildProps = {
    children: React.ReactNode;
};

/**
 * The key for the local storage dark theme state.
 */
export const darkThemeStorageKey = 'isDarkTheme';

/**
 * The msal instance to use through the application.
 */
export let msalInstance: PublicClientApplication;

/** The query client to use. */
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

/**
 * The application component.
 *
 * @returns {ReactElement | null} Main App component or NULL if a dependency is missing.
 */
const App: FunctionComponent = (): ReactElement | null => {
    /** The state of the i18n instance. */
    const [i18nInstance, setI18nInstance] = useState<i18n>();

    /** Msal instance state. */
    const [internalMsalInstance, setInternalMsalInstance] = useState<PublicClientApplication>();

    /**
     * Whether the dark theme is selected.
     */
    const isDarkTheme = useStoreState((state) => state.UserModel.isDarkTheme);

    /**
     * Changes the theme selection in the global store.
     */
    const updateIsDarkTheme = useStoreActions((actions) => actions.UserModel.updateIsDarkTheme);

    /**
     * Changes the local storage dark theme state on change.
     */
    useEffect(() => {
        if (isDarkTheme != null) {
            localStorage.setItem(darkThemeStorageKey, String(isDarkTheme));
        }
    }, [isDarkTheme, updateIsDarkTheme]);

    // Initialize the fluent ui theme.
    const theme: PartialTheme = {
        palette: isDarkTheme ? DarkPalette : Palette,
        defaultFontStyle: DefaultFontStyle,
        components: DefaultComponentStyles(isDarkTheme ? DarkPalette : Palette),
        fonts: Fonts,
        isInverted: isDarkTheme,
        semanticColors: isDarkTheme ? DarkSemanticColors : SemanticColors,
    };

    /**
     * Initialize the application.
     */
    useEffect(() => {
        _config.then((configuration) => {
            if (!configuration) {
                console.error('Configuration could not be loaded.');
                return;
            }
            // Configure Application Insights
            if (configuration.uiOptions && configuration.uiOptions.applicationInsightsKey) {
                configureAi(configuration.uiOptions.applicationInsightsKey);
            } else {
                console.error('Application Insights is not configured.');
            }
            if ((configuration.azureAdOptions && configuration.azureAdOptions.clientId) || (configuration.azureAdB2COptions && configuration.azureAdB2COptions.clientId)) {
                // Build the msal config using the fetched configuration values.
                const msalConfig = buildMsalConfig(configuration, true);
                // Create the msal instance.
                msalInstance = new PublicClientApplication(msalConfig);
                // Set the msal instance to use in the provider.
                setInternalMsalInstance(msalInstance);
            } else {
                console.error('MSAL is not configured.');
            }
        });
        // Initialize i18next.
        initialize().then((i) => {
            setI18nInstance(i);
        });
    }, []);

    /**
     * Initializes app wide tasks only for authenticated users.
     *
     * @param {ChildProps} props Component props including the children to render.
     * @returns {ReactElement | null} Null if the user is not logged in, otherwise the properties children.
     */
    const AuthenticatedDataInitialization = (props: ChildProps): ReactElement | null => {
        /**
         * Fetches the currently signed in user.
         */
        const fetchUser = useStoreActions((actions) => actions.UserModel.fetchUser);

        /**
         * Async fetch for the user representations connected to the logged in user.
         */
        const fetchUserRepresentations = useStoreActions((actions) => actions.UserModel.fetchUserRepresentations);

        /**
         * Whether the dark theme is selected.
         */
        const isDarkTheme = useStoreState((state) => state.UserModel.isDarkTheme);
        /** The state of the i18n instance. */
        const [i18nInstance, setI18nInstance] = useState<i18n>();

        /**
         * One-time data retrieval on startup.
         */
        useEffect(() => {
            if (i18nInstance) {
                fetchUser();
                fetchUserRepresentations();
            }
        }, [fetchUser, fetchUserRepresentations, i18nInstance]);

        if (i18nInstance == null) {
            // Initialize i18next.
            initialize().then((i) => {
                setI18nInstance(i);
            });
            return null;
        }

        // Initialize the fluent ui theme.
        const theme: PartialTheme = {
            palette: isDarkTheme ? DarkPalette : Palette,
            defaultFontStyle: DefaultFontStyle,
            components: DefaultComponentStyles(isDarkTheme ? DarkPalette : Palette),
            fonts: Fonts,
            isInverted: isDarkTheme,
            semanticColors: isDarkTheme ? DarkSemanticColors : SemanticColors,
        };

        return (
            <ThemeProvider theme={theme}>
                <I18nextProvider i18n={i18nInstance}>{props.children}</I18nextProvider>
            </ThemeProvider>
        );
    };

    /**
     * App without authentication.
     *
     * @param {i18n} i18n Initialized internationalization.
     * @returns {ReactElement} App without authentication.
     */
    const noAuthentication = (i18n: i18n): ReactElement => (
        <QueryClientProvider client={queryClient}>
            <I18nextProvider i18n={i18n}>
                <ThemeProvider theme={theme}>
                    <AppContainer>
                        <Routes />
                    </AppContainer>
                </ThemeProvider>
            </I18nextProvider>
        </QueryClientProvider>
    );

    /**
     * App with msal authentication.
     *
     * @param {i18n} i18n Initialized internationalization.
     * @param {PublicClientApplication} msal Initialized msal.
     * @returns {ReactElement} App with msal authentication.
     */
    const withAuthentication = (i18n: i18n, msal: PublicClientApplication): ReactElement => (
        <QueryClientProvider client={queryClient}>
            <MsalProvider instance={msal}>
                <MsalEventHandler>
                    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                        <AuthenticatedDataInitialization>
                            <I18nextProvider i18n={i18n}>
                                <ThemeProvider theme={theme}>
                                    <AppContainer>
                                        <Routes />
                                    </AppContainer>
                                </ThemeProvider>
                            </I18nextProvider>
                        </AuthenticatedDataInitialization>
                    </MsalAuthenticationTemplate>
                </MsalEventHandler>
            </MsalProvider>
        </QueryClientProvider>
    );

    return i18nInstance ? (internalMsalInstance ? withAuthentication(i18nInstance, internalMsalInstance) : noAuthentication(i18nInstance)) : null;
};

export default withAITracking(reactPlugin, App, App.name, 'App-Container');
