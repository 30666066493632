import React, { FunctionComponent, useEffect, useCallback, useState, useMemo } from 'react';
import { DefaultButton, Panel, PrimaryButton, Stack, Text, PanelType, MessageBarType } from '@fluentui/react';
import { IUser } from '../types/IUser';
import { ILetter } from '../types/ILetter';
import { useStoreActions, useStoreState } from '../store/Store';
import styled from 'styled-components/macro';
import { ILetterRecipientUpdate } from '../types/ILetterRecipientUpdate';
import { getFromApi, postToApi } from '../helper/ApiHelper';
import { useTranslation } from 'react-i18next';
import { ILetterGroupUpdate } from '../types/ILetterGroupUpdate';
import { RoleEnum } from '../types/RoleEnum';
import { CollectionTypeEnum } from '../types/CollectionTypeEnum';
import UserSearchPanelBody from '../components/UserSearchPanelBody';
import usePagedEntity from '../hooks/usePagedEntity';

const PanelHeader = styled.div`
    padding: 0 0 20px 20px;
    width: 100%;
`;

interface IEditRecipientsPanelProps {
    /** The letters to update. */
    letters?: ILetter[];
}

/**
 * Component that contains the files uploaded to the library.
 *
 * @param {IEditRecipientsPanelProps} props The props for the edit recipients panel.
 * @returns {FunctionComponent<IEditRecipientsPanelProps>} The Recipients Panel.
 */
export const EditRecipientsPanel: FunctionComponent<IEditRecipientsPanelProps> = (props: IEditRecipientsPanelProps) => {
    /** Access to translations.*/
    const { t } = useTranslation();

    /** The busy state of this component. */
    const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);

    /** Store state of the EditUserPanel. */
    const panelPurpose = useStoreState((state) => state.LettersModel.panelPurpose);
    /** Global state representing which groups letters are being shown. */
    const groupToShow = useStoreState((state) => state.LettersModel.groupToShow);
    /** Global state representing if assigned or collection letters are being shown. */
    const showType = useStoreState((state) => state.LettersModel.showType);
    /** Global state of the logged in user. */
    const user = useStoreState((state) => state.UserModel.user);
    /** Action to update the state oft the update recipients / assignees panel. */
    const updatePanelPurpose = useStoreActions((actions) => actions.LettersModel.updatePanelPurpose);
    /** Action to fetch the letters. */
    const fetchLetters = useStoreActions((actions) => actions.LettersModel.fetchLetters);
    /** Updates the global notification. */
    const updateNotification = useStoreActions((actions) => actions.GlobalNotificationModel.updateNotification);

    /** Query for users. */
    const usersQuery = usePagedEntity<IUser>({
        entityName: 'User',
    });
    /** Query for users in the selected group. */
    const usersForGroupQuery = usePagedEntity<IUser>({
        entityName: 'User',
        relativePagedEndpointPath: groupToShow ? `User/PagedForGroup/${groupToShow}` : undefined,
    });

    /**
     * True if the logged in user is an admin.
     */
    const isAdmin = useMemo(() => (user?.userRoles ? user.userRoles[0].roleId === RoleEnum.Admin : false), [user]);

    /**
     * Fetch needed data.
     */
    useEffect(() => {
        if (groupToShow && (!isAdmin || showType === CollectionTypeEnum.AssignedToGroup)) {
            usersForGroupQuery.updateSearchValue('');
        }
        /** Get the initially selected users. */
        const getCurrentSelectedUsers = async () => {
            const currentSelectedUserIds: number[] = [];
            const currentSelectedDisqualifiedUserIds: number[] = [];
            props.letters?.forEach((letter) => {
                if (currentSelectedUserIds.length === 0) {
                    letter.userLetters.forEach((ul) => currentSelectedUserIds.push(ul.userId));
                } else {
                    letter.userLetters.forEach((ul) => {
                        if (!currentSelectedUserIds.includes(ul.userId)) {
                            currentSelectedDisqualifiedUserIds.push(ul.userId);
                        }
                    });
                }
            });
            const userIdsToSelect = currentSelectedUserIds.filter((id) => !currentSelectedDisqualifiedUserIds.includes(id));
            const usersToSelectPromise = userIdsToSelect.map(async (id) => await getFromApi<IUser>(`User/${id}`));
            const usersToSelect = await Promise.all(usersToSelectPromise);
            setSelectedUsers(usersToSelect);
        };
        getCurrentSelectedUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usersQuery, usersForGroupQuery, groupToShow, panelPurpose.isOpen]);

    /**
     * Update the chosen letters to them based on the update model.
     *
     * @param {ILetterGroupUpdate[]} letterGroupUpdates The update models.
     */
    const updateGroup = async (letterGroupUpdates: ILetterGroupUpdate[]) => {
        await postToApi<ILetter[]>(`Letter/Groups`, letterGroupUpdates);
    };

    /**
     * Update the recipients of the chosen letters based on the update model.
     *
     * @param {ILetterRecipientUpdate[]} letterRecipientUpdates The update models.
     */
    const updateRecipients = async (letterRecipientUpdates: ILetterRecipientUpdate[]) => {
        try {
            await postToApi<ILetter[]>(`Letter/Recipients`, letterRecipientUpdates);
            updateNotification({ message: t('EditRecipientsPanel_Success'), type: MessageBarType.success });
            fetchLetters(showType);
        } catch (error) {
            updateNotification({ message: t('EditRecipientsPanel_Error'), type: MessageBarType.error });
            console.error(error);
        }
    };

    /**
     * Transforms ILetter[] into ILetterStatusUpdate[] for the api call.
     *
     * @param {ILetter[]} letters The letters to transform for use in the update call.
     * @param {number|undefined} newGroupId The group id to set.
     * @returns {ILetterGroupUpdate[]} The transformed elements.
     */
    const transformLettersForGroupUpdate = (letters: ILetter[], newGroupId?: number): ILetterGroupUpdate[] => {
        const letterGroupUpdates: ILetterGroupUpdate[] = [];
        letters.forEach((letter) => {
            letterGroupUpdates.push({
                id: letter.id,
                groupId: newGroupId,
            });
        });
        return letterGroupUpdates;
    };

    /**
     * Resets the component state.
     */
    const resetState = useCallback((): void => {
        updatePanelPurpose({ isOpen: false });
        setSelectedUsers([]);
    }, [updatePanelPurpose]);

    /**
     * Transforms IUser[] and ILetter[] into ILetterRecipientUpdate[] for the api call.
     *
     * @param {ILetter[]} letters The letters to transform for use in the update call.
     * @param {IUser[]} selectedUsers The selected users to transform for user in the update call.
     * @returns {ILetterRecipientUpdate[]} The transformed elements.
     */
    const transformUsersForRecipientUpdate = (letters: ILetter[], selectedUsers: IUser[]): ILetterRecipientUpdate[] => {
        if (showType === CollectionTypeEnum.AssignedToGroup) {
            const letterRecipientUpdates: ILetterRecipientUpdate[] = [];
            letters.forEach((letter) => {
                letterRecipientUpdates.push({
                    id: letter.id,
                    groupId: groupToShow,
                    userLetters: selectedUsers.map((u) => {
                        return { id: 0, isRead: false, letterId: letter.id, userId: u.id };
                    }),
                });
            });
            return letterRecipientUpdates;
        }
        const letterRecipientUpdates: ILetterRecipientUpdate[] = [];
        letters.forEach((letter) => {
            letterRecipientUpdates.push({
                id: letter.id,
                userLetters: selectedUsers.map((u) => {
                    return { id: 0, isRead: false, letterId: letter.id, userId: u.id };
                }),
            });
        });
        return letterRecipientUpdates;
    };

    /**
     * Footer of the panel.
     *
     * @returns {Element} The footer element.
     */
    const onRenderFooter = () => (
        <Stack style={{ marginTop: 'auto' }} horizontal horizontalAlign={'end'} verticalAlign={'end'} tokens={{ childrenGap: 10, padding: '0 20px 20px 20px' }}>
            <DefaultButton text={t('EditUserPanel_Footer_CancelButton')} onClick={() => resetState()} />
            <PrimaryButton
                disabled={selectedUsers.length === 0}
                text={t('EditUserPanel_Footer_ConfirmButton')}
                onClick={() => {
                    if (props.letters) {
                        updateGroup(transformLettersForGroupUpdate(props.letters));
                        updateRecipients(transformUsersForRecipientUpdate(props.letters, selectedUsers));
                        resetState();
                    }
                }}
            />
        </Stack>
    );

    /**
     * Header of the panel.
     *
     * @returns {Element} The header element.
     */
    const onRenderHeader = () => (
        <PanelHeader>
            <Text variant={'large'}>{t('EditRecipientsPanel_Headline_Recipients')}</Text>
        </PanelHeader>
    );

    return (
        <Panel
            allowTouchBodyScroll
            isOpen={panelPurpose.isOpen}
            isFooterAtBottom
            isBlocking
            isLightDismiss
            type={PanelType.medium}
            onRenderHeader={onRenderHeader}
            onDismiss={resetState}
            onRenderBody={() => <UserSearchPanelBody selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />}
            onRenderFooter={onRenderFooter}
        />
    );
};
