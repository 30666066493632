import React, { FunctionComponent, ComponentType, ReactElement, ReactNode } from 'react';
import { Route } from 'react-router-dom';

/**
 * Interface for the properties of the route wrapper.
 */
export interface IRouteWrapper {
    /**
     * The layout that is rendered around the component.
     */
    layout: ComponentType;
    /**
     * Whether this is an exact route or not.
     */
    exact: boolean;
    /**
     * The path of the route.
     */
    path: string;
    /**
     * Child components.
     */
    children?: ReactNode;
}

/**
 * Component that creates a route that renders the given component wrapped by the given layout.
 *
 * @param {IRouteWrapper} props Route wrapper props.
 * @returns {ReactElement} Route component wrapped in the provided layout.
 */
export const RouteWrapper: FunctionComponent<IRouteWrapper> = (props: IRouteWrapper): ReactElement => {
    const Layout = props.layout as ComponentType;
    return <Route exact={props.exact} path={props.path} render={(renderProps) => <Layout {...renderProps}>{props.children}</Layout>} />;
};
