import React, { FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react';
import {
    IColumn,
    SelectionMode,
    IDetailsRowProps,
    CommandBar,
    DetailsRowFields,
    IDetailsRowFieldsProps,
    IIconProps,
    DetailsRow,
    ICommandBarItemProps,
    Selection,
    MessageBarType,
} from '@fluentui/react';
import { Table } from './Table';
import { ILetter } from '../types/ILetter';
import { postToApi, getFromApi, downloadWithSasToken, deleteFromApi } from '../helper/ApiHelper';
import { formatBytes } from '../helper/ByteHelper';
import { formatLetterTypeToText } from '../helper/LetterHelper';
import { ILetterDenyUpdate } from '../types/ILetterDenyUpdate';
import { ILetterGroupUpdate } from '../types/ILetterGroupUpdate';
import { ILetterIsReadUpdate } from '../types/ILetterIsReadUpdate';
import { ILetterRecipientUpdate } from '../types/ILetterRecipientUpdate';
import { useStoreActions, useStoreState } from '../store/Store';
import { EditRecipientsPanel } from '../panels/EditRecipientsPanel';
import { LetterViewPanel } from '../panels/LetterViewPanel';
import { useTranslation } from 'react-i18next';
import { IUser } from '../types/IUser';
import { ConfirmationDialog } from '../dialogs/ConfirmationDialog';
import { DenialDialog } from '../dialogs/DenialDialog';
import { formatToGermanDateWithTime } from '../helper/DateFormatHelper';

/**
 * Properties of the download icon.
 */
const downloadIconProps: IIconProps = {
    iconName: 'Download',
};

/**
 * Letter table component.
 *
 * @returns {ReactElement} Route component wrapped in the provided layout.
 */
export const ToBeDeletedLetterTable: FunctionComponent = (): ReactElement => {
    /**
     * Access to translations.
     */
    const { t } = useTranslation();

    /**
     * Global state of the logged in user.
     */
    const user = useStoreState<IUser | undefined>((state) => state.UserModel.user);

    /**
     * The state representing if letters are being fetched.
     */
    const isFetching = useStoreState((state) => state.LettersModel.isFetching);

    /**
     * The selected letter to display in the preview panel.
     */
    const [selectedLetter, setSelectedLetter] = useState<ILetter | undefined>();

    /**
     * Global state of letters.
     */
    const letters = useStoreState((state) => state.ToBeDeletedLettersModel.toBeDeletedLetters);

    /**
     * The current page of the table.
     */
    const currentPage = useStoreState((state) => state.ToBeDeletedLettersModel.currentPage);

    /**
     * Action to update the current page.
     */
    const updateCurrentPage = useStoreActions((actions) => actions.ToBeDeletedLettersModel.updateCurrentPage);

    /**
     * Action to fetch the letters.
     */
    const fetchLetters = useStoreActions((actions) => actions.ToBeDeletedLettersModel.fetchToBeDeletedLettersLetters);

    /**
     * The maximum available pages.
     */
    const maxAvailablePages = useStoreState((state) => state.ToBeDeletedLettersModel.maxPages);

    /**
     * Action to update the preview panel state.
     */
    const updatePreviewOpen = useStoreActions((actions) => actions.LetterViewerModel.updateIsOpen);

    /**
     * Updates the global notification.
     */
    const updateNotification = useStoreActions((actions) => actions.GlobalNotificationModel.updateNotification);

    /**
     * The selected letters.
     */
    const [selected, setSelected] = useState<ILetter[]>([]);

    /**
     * Whether the dialog to confirm letter deletion is open or not.
     */
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState<boolean>(false);

    /**
     * Whether the dialog to deny letters is open or not.
     */
    const [isDenialDialogOpen, setIsDenialDialogOpen] = useState<boolean>(false);

    /**
     * Whether the UI is currently locally busy or not.
     */
    const [isBusy, setIsBusy] = useState<boolean>(false);

    /**
     * Fetches the letters initially.
     */
    useEffect(() => {
        fetchLetters();
    }, [fetchLetters, currentPage]);

    /**
     * Delete the selected letters.
     */
    const deleteOnClick = async () => {
        try {
            setIsBusy(true);
            const idsToDelete = selected.map((letter) => letter.id);
            await deleteFromApi('Letter/Batched', idsToDelete);
            updateNotification({ type: MessageBarType.success, message: t('DeleteLetters_Success') });
            fetchLetters();
        } catch (error) {
            console.error(error);
            updateNotification({ type: MessageBarType.error, message: t('DeleteLetters_Error') });
        } finally {
            setIsBusy(false);
        }
    };

    /**
     * The current selection of the details component.
     */
    const selection: Selection = new Selection({
        onSelectionChanged: () => {
            const currentSelectedItems = selection.getSelection();
            setSelected(currentSelectedItems as ILetter[]);
        },
    });

    /**
     * Check whetter most selected letters are read or not read.
     *
     * @returns {boolean} Result of the check.
     */
    const isReadCheck = (): boolean => {
        const total = selected.length;
        const count = selected.filter((sl) => letterIsRead(sl)).length;
        return count > total / 2;
    };

    /**
     * Check whetter the given letter is read or not read.
     *
     * @param {ILetter} letter The letter to test.
     * @returns {boolean} Result of the check.
     */
    const letterIsRead = (letter: ILetter): boolean => {
        return (letter.userLetters.find((ul) => ul.userId === user?.id && ul.isRead === true) === undefined ? false : true) || (letter.isRead ?? false);
    };

    /**
     * Transforms ILetter[] into ILetterStatusUpdate[] for the api call.
     *
     * @param {ILetter[]} letters The letters to transform for use in the update call.
     * @param {boolean[]} isRead The the is read value to use in the update call.
     * @returns {ILetterRecipientUpdate[]} The transformed elements.
     */
    const transformLettersForIsReadUpdate = (letters: ILetter[], isRead: boolean): ILetterRecipientUpdate[] => {
        const letterRecipientUpdates: ILetterRecipientUpdate[] = [];
        letters.forEach((letter) => {
            letterRecipientUpdates.push({
                id: letter.id,
                userLetters: letter.userLetters.map((ul) => {
                    ul.isRead = isRead;
                    return ul;
                }),
            });
        });
        return letterRecipientUpdates;
    };

    /**
     * Transforms ILetter[] into ILetterIsReadUpdate[] for the api call.
     *
     * @param {ILetter[]} letters The letters to transform for use in the update call.
     * @param {boolean[]} isRead The the is read value to use in the update call.
     * @returns {ILetterIsReadUpdate[]} The transformed elements.
     */
    const transformLettersForGroupIsReadUpdate = (letters: ILetter[], isRead: boolean): ILetterIsReadUpdate[] => {
        const letterIsReadUpdates: ILetterIsReadUpdate[] = [];
        letters.forEach((letter) => {
            letterIsReadUpdates.push({
                id: letter.id,
                isRead: isRead,
            });
        });
        return letterIsReadUpdates;
    };

    /**
     * Transforms ILetter[] into ILetterStatusUpdate[] for the api call.
     *
     * @param {ILetter[]} letters The letters to transform for use in the update call.
     * @param {string} denialReason The reason for denial.
     * @returns {ILetterDenyUpdate[]} The transformed elements.
     */
    const transformLettersForDeny = (letters: ILetter[], denialReason: string): ILetterDenyUpdate[] => {
        const letterDenyUpdates: ILetterDenyUpdate[] = [];
        if (user) {
            letters.forEach((letter) => {
                letterDenyUpdates.push({
                    id: letter.id,
                    userId: user?.id,
                    denialReason: denialReason,
                });
            });
        }
        return letterDenyUpdates;
    };

    /**
     * Transforms ILetter[] into ILetterGroupUpdate[] for the api call.
     *
     * @param {ILetter[]} letters The letters to transform for use in the update call.
     * @param {string} denialReason The denial reason.
     * @returns {ILetterGroupUpdate[]} The transformed elements.
     */
    const transformLettersForGroupDenial = (letters: ILetter[], denialReason: string): ILetterGroupUpdate[] => {
        const letterGroupUpdates: ILetterGroupUpdate[] = [];
        if (user) {
            letters.forEach((letter) => {
                letterGroupUpdates.push({
                    id: letter.id,
                    denialReason: denialReason,
                });
            });
        }
        return letterGroupUpdates;
    };

    /**
     * Update the recipients of the chosen letters based on the update model.
     *
     * @param {ILetterRecipientUpdate[]} letterRecipientUpdates The update models.
     */
    const updateRecipients = async (letterRecipientUpdates: ILetterRecipientUpdate[]) => {
        await postToApi<ILetter[]>(`Letter/Recipients`, letterRecipientUpdates);
        fetchLetters();
    };

    /**
     * Update the is read of the chosen letters based on the update model.
     *
     * @param {ILetterIsReadUpdate[]} letterRecipientUpdates The update models.
     */
    const updateIsRead = async (letterRecipientUpdates: ILetterIsReadUpdate[]) => {
        await postToApi<ILetter[]>(`Letter/IsRead`, letterRecipientUpdates);
        fetchLetters();
    };

    /**
     * Update the chosen letters to deny them based on the update model.
     *
     * @param {ILetterDenyUpdate[]} letterDenyUpdates The update models.
     */
    const updateDeny = async (letterDenyUpdates: ILetterDenyUpdate[]) => {
        await postToApi<ILetter[]>(`Letter/Deny`, letterDenyUpdates);
        fetchLetters();
    };

    /**
     * Switches Is Read status of the selected letters.
     */
    const updateIsReadOnClick = () => {
        const separatedLetters = separateGroupAndIndividualLetters(selected);
        updateRecipients(transformLettersForIsReadUpdate(separatedLetters.individualLetters, !isReadCheck()));
        updateIsRead(transformLettersForGroupIsReadUpdate(separatedLetters.groupLetters, !isReadCheck()));
    };

    /**
     * Update the chosen letters to them based on the update model.
     *
     * @param {ILetterGroupUpdate[]} letterGroupUpdates The update models.
     */
    const updateGroup = async (letterGroupUpdates: ILetterGroupUpdate[]) => {
        await postToApi<ILetter[]>(`Letter/Groups`, letterGroupUpdates);
        fetchLetters();
    };

    const isLetterGroupAssigned = (letter: ILetter) => {
        return letter.groupId && letter.groupId > 0;
    };
    const separateGroupAndIndividualLetters = (letters: ILetter[]) => {
        const groupLetters: ILetter[] = [];
        const individualLetters: ILetter[] = [];
        letters.forEach((letter) => {
            if (isLetterGroupAssigned(letter)) {
                groupLetters.push(letter);
            } else {
                individualLetters.push(letter);
            }
        });
        return { groupLetters, individualLetters };
    };

    /**
     * Deny being the recipient.
     *
     * @param {string} denialReason The update models.
     */
    const updateDenyCallback = (denialReason: string) => {
        const separatedLetters = separateGroupAndIndividualLetters(selected);
        updateGroup(transformLettersForGroupDenial(separatedLetters.groupLetters, denialReason));
        updateDeny(transformLettersForDeny(separatedLetters.individualLetters, denialReason));
    };

    /**
     * Handle deny button click.
     */
    const updateDenyOnClick = () => {
        setIsDenialDialogOpen(true);
    };

    /**
     * Downloads selected letters.
     */
    const downloadSelected = async () => {
        const lettersToDownload = selected;
        lettersToDownload.forEach(async (letter) => {
            const sasToken = await getFromApi<string>(`Letter/GenerateDownloadToken/${letter.id}.pdf`);
            const link = document.createElement('a');
            link.download = letter.originalFilename;
            link.href = await downloadWithSasToken(sasToken, letter.originalFilename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };

    /**
     * Downloads selected letters and set them to is read.
     */
    const downloadOnClick = () => {
        downloadSelected();
        const separatedLetters = separateGroupAndIndividualLetters(selected);
        updateRecipients(transformLettersForIsReadUpdate(separatedLetters.individualLetters, true));
        updateIsRead(transformLettersForGroupIsReadUpdate(separatedLetters.groupLetters, true));
    };
    /**
     * Builds the command bar items.
     *
     * @returns {ICommandBarItemProps[]} The command bar items.
     */
    const getCommandBarItems = (): ICommandBarItemProps[] => {
        const items: ICommandBarItemProps[] = [];
        items.push({
            disabled: selected.length === 0,
            key: 'Home_TableMenu_Download',
            text: t('Home_TableMenu_Download'),
            iconOnly: true,
            iconProps: downloadIconProps,
            onClick: downloadOnClick,
        });
        items.push({
            disabled: selected.length === 0,
            key: 'Home_TableMenu_Change_IsRead',
            text: isReadCheck() ? t('Home_TableMenu_Change_IsRead_True') : t('Home_TableMenu_Change_IsRead_False'),
            onClick: updateIsReadOnClick,
        });
        items.push({
            disabled: selected.length === 0,
            key: 'Home_TableMenu_Deny',
            text: t('Home_TableMenu_Deny'),
            onClick: updateDenyOnClick,
        });
        items.push({
            disabled: selected.length === 0,
            key: 'Home_TableMenu_DeleteLetter',
            text: t('Home_TableMenu_DeleteLetter'),
            onClick: () => setIsConfirmDeleteDialogOpen(true),
        });

        return items;
    };

    /**
     * Handles click on a table row item.
     *
     * @param {ILetter} item the item clicked on.
     */
    const rowClick = (item: ILetter) => {
        setSelectedLetter(item);
        if (!isLetterGroupAssigned(item)) {
            updateRecipients(transformLettersForIsReadUpdate([item], true));
        }
        if (isLetterGroupAssigned(item)) {
            updateIsRead(transformLettersForGroupIsReadUpdate([item], true));
        }
        updatePreviewOpen(true);
    };

    /**
     * The columns of the table.
     *
     * @returns {IColumn[]} The columns
     */
    const columns = useCallback((): IColumn[] => {
        const columns: IColumn[] = [];
        columns.push({
            key: 'spacing',
            name: '',
            minWidth: 1,
            maxWidth: 7,
            columnActionsMode: 0,
        });
        columns.push({
            key: 'label',
            fieldName: 'label',
            name: t('LetterTable_Label'),
            minWidth: 150,
            maxWidth: 250,
            columnActionsMode: 0,
            onRender: (item: ILetter) => item.label,
        });
        columns.push({
            key: 'letterType',
            fieldName: 'letterType',
            name: t('LetterTable_letterType'),
            minWidth: 80,
            maxWidth: 120,
            columnActionsMode: 0,
            onRender: (item: ILetter) => (item.letterType !== undefined ? formatLetterTypeToText(item.letterType, t) : t('LettersTable_LetterType_Unknown')),
        });
        columns.push({
            key: 'sender',
            fieldName: 'sender',
            name: t('LetterTable_sender'),
            minWidth: 80,
            maxWidth: 170,
            columnActionsMode: 0,
            onRender: (item: ILetter) => item.sender,
        });
        columns.push({
            key: 'recipient',
            fieldName: 'recipient',
            name: t('LetterTable_parsedRecipient'),
            minWidth: 80,
            maxWidth: 170,
            columnActionsMode: 0,
            onRender: (item: ILetter) => item.recipient,
        });
        columns.push({
            key: 'firstLine',
            fieldName: 'firstLine',
            name: t('LetterTable_firstLine'),
            minWidth: 80,
            maxWidth: 300,
            columnActionsMode: 0,
            onRender: (item: ILetter) => item.firstLine,
        });
        columns.push({
            key: 'dateReceived',
            fieldName: 'dateReceived',
            name: t('LetterTable_dateReceived'),
            minWidth: 150,
            maxWidth: 300,
            columnActionsMode: 0,
            onRender: (item: ILetter) => formatToGermanDateWithTime(item.dateCreated),
        });
        columns.push({
            key: 'fileSize',
            fieldName: 'fileSize',
            name: t('LetterTable_fileSize'),
            minWidth: 80,
            maxWidth: 80,
            columnActionsMode: 0,
            onRender: (item: ILetter) => (item?.size ? formatBytes(item.size, 2) : ''),
        });
        return columns;
    }, [t]);

    /**
     * Custom row render to render read letters differently.
     *
     * @param {IDetailsRowProps} props The original row props.
     * @param {(props?: IDetailsRowProps) => Element | null} defaultRender The default renderer.
     * @returns {Element} The rendered row or null.
     */
    const onRenderRow = (props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => JSX.Element | null): JSX.Element | null => {
        if (defaultRender) {
            if (props) {
                const letter = props.item as ILetter;
                const isRead = letterIsRead(letter);
                props.styles = {
                    root: {
                        cursor: 'pointer',
                        fontWeight: isRead ? 'normal' : '600',
                    },
                };
                return (
                    <div
                        onClick={(e) => {
                            const target = e.target as HTMLElement;
                            if (target.className.includes('ms-DetailsRow-cell')) {
                                rowClick(letter);
                            }
                        }}
                    >
                        <DetailsRow rowFieldsAs={renderRowFields} {...props} />
                    </div>
                );
            }
        }
        return null;
    };

    /**
     * Renders row fields without the functionality to select the row on click.
     *
     * @param {IDetailsRowFieldsProps} props Default props.
     * @returns {Element} Rendered row fields.
     */
    const renderRowFields = (props: IDetailsRowFieldsProps) => {
        return (
            <span data-selection-disabled={true}>
                <DetailsRowFields {...props} />
            </span>
        );
    };
    return (
        <>
            <DenialDialog isOpen={isDenialDialogOpen} confirmCallback={updateDenyCallback} close={() => setIsDenialDialogOpen(false)} />
            <ConfirmationDialog
                isOpen={isConfirmDeleteDialogOpen}
                close={() => setIsConfirmDeleteDialogOpen(false)}
                confirmButtonText={t('Delete')}
                isDangerousAction
                text={t('Home_DeleteLetter_Text')}
                title={t('Home_DeleteLetter_Title')}
                confirmCallback={deleteOnClick}
            />
            <EditRecipientsPanel letters={selected} />
            <LetterViewPanel selectedLetter={selectedLetter} />
            <CommandBar items={getCommandBarItems()} />
            <Table
                columns={columns()}
                data={letters}
                isBusy={isFetching && isBusy}
                currentPage={currentPage}
                availablePages={maxAvailablePages}
                navigateToEnd={() => updateCurrentPage(maxAvailablePages)}
                navigateToStart={() => updateCurrentPage(1)}
                navigateBackward={() => updateCurrentPage(currentPage - 1)}
                navigateForward={() => updateCurrentPage(currentPage + 1)}
                selectionMode={SelectionMode.multiple}
                selection={selection}
                onRenderRow={onRenderRow}
            />
        </>
    );
};
