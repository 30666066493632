import React, { FunctionComponent, ReactElement, useState } from 'react';
import { IButtonStyles, IColumn, IconButton, IIconProps, IStackStyles, IStackTokens, MessageBarType, PrimaryButton, Stack, Text, TooltipHost, useTheme } from '@fluentui/react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Table } from '../table/Table';
import { formatToGermanDate } from '../helper/DateFormatHelper';
import { IUser } from '../types/IUser';
import { useStoreActions } from '../store/Store';
import { deleteFromApi } from '../helper/ApiHelper';
import { ConfirmationDialog } from '../dialogs/ConfirmationDialog';
import usePagedEntity from '../hooks/usePagedEntity';

const UserViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

/** Props of the top container. */
const TopContainer = styled.div`
    display: flex;
    padding: 22px 35px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
`;

/** Props of the bottom container. */
const BottomContainer = styled.div`
    display: flex;
    flex: 1;
`;

/**
 * View component for the home view.
 *
 * @returns {ReactElement} Home View.
 */
export const UsersView: FunctionComponent = (): ReactElement => {
    /** Access to translations. */
    const { t } = useTranslation();
    /** Access to fluent ui theme. */
    const theme = useTheme();

    /** Whether this component is busy or not.*/
    const [isBusy, setIsBusy] = useState<boolean>(false);
    /** Whether the confirmation dialog is open or not. */
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
    /** User reference to delete. */
    const [userToDelete, setUserToDelete] = useState<IUser>();

    /** Updates the global users panel purpose. */
    const updatePanelPurpose = useStoreActions((actions) => actions.UsersModel.updatePanelPurpose);
    /** Updates the global notification. */
    const updateNotification = useStoreActions((actions) => actions.GlobalNotificationModel.updateNotification);

    /** Query for users. */
    const usersQuery = usePagedEntity<IUser>({
        entityName: 'User',
    });

    /**
     * Styles of the icon button.
     *
     * @param {boolean} isLarge Whether the text is large or not.
     * @returns {IButtonStyles} The styles for a fluent ui button.
     */
    const iconButtonStyles = (isLarge?: boolean): IButtonStyles => ({
        root: {
            marginLeft: isLarge ? 3 : 7,
            fontSize: isLarge ? 22 : 'unset',
        },
        icon: {
            color: theme.palette.black,
        },
        rootDisabled: {
            background: 'none',
        },
    });

    /** Columns of the table. */
    const columns: IColumn[] = [
        {
            key: 'spacing',
            name: '',
            minWidth: 1,
            maxWidth: 1,
            columnActionsMode: 0,
        },
        {
            key: 'actions',
            fieldName: 'actions',
            name: '',
            minWidth: 50,
            maxWidth: 60,
            columnActionsMode: 0,
            onRender: (item: IUser) => (
                <>
                    <TooltipHost content={t('UserView_editUser_Tooltip')} id={'editUserTooltip'}>
                        <IconButton
                            styles={iconButtonStyles()}
                            iconProps={editUserIconProps}
                            ariaDescription="editUserTooltip"
                            onClick={() => {
                                updatePanelPurpose({ isOpen: true, mode: 'UPDATE', entityId: item.id });
                            }}
                        />
                    </TooltipHost>
                </>
            ),
        },
        {
            key: 'name',
            fieldName: 'name',
            name: t('UserTable_ColumnHeadline_Name'),
            minWidth: 150,
            maxWidth: 250,
            columnActionsMode: 0,
            onRender: (item: IUser) => item.displayName,
        },
        {
            key: 'email',
            fieldName: 'email',
            name: t('UserTable_ColumnHeadline_Email'),
            minWidth: 150,
            maxWidth: 300,
            columnActionsMode: 0,
            onRender: (item: IUser) => item.email,
        },
        {
            key: 'userRole',
            fieldName: 'userRole',
            name: t('UserTable_ColumnHeadline_UserRole'),
            minWidth: 90,
            maxWidth: 200,
            columnActionsMode: 0,
            onRender: (item: IUser) => item.userRoles && item.userRoles[0] && item.userRoles[0].role?.label,
        },
        {
            key: 'lastLogin',
            fieldName: 'lastLogin',
            name: t('UserTable_ColumnHeadline_LastLogin'),
            minWidth: 100,
            maxWidth: 200,
            columnActionsMode: 0,
            onRender: (item: IUser) => formatToGermanDate(item.lastLogin),
        },
    ];

    /**
     * Deletes the given user.
     *
     * @param {IUser} user Reference to delete.
     */
    const deleteUser = async (user: IUser): Promise<void> => {
        if (user == null) {
            return;
        }
        setIsConfirmDialogOpen(false);
        setIsBusy(true);
        try {
            await deleteFromApi(`User/${user.id}`);
            setUserToDelete(undefined);
            updateNotification({ message: t('User_Deletion_Success'), type: MessageBarType.success });
        } catch (error) {
            console.error(error);
            updateNotification({ message: t('User_Deletion_Error'), type: MessageBarType.error });
        }
        setIsBusy(false);
    };

    /**
     * Basic stack layout tokens.
     */
    const stackTokens: IStackTokens = {
        childrenGap: 50,
    };

    /**
     * Props of the icon to edit an employee.
     */
    const editUserIconProps: IIconProps = {
        iconName: 'Edit',
    };

    const stackStyles: IStackStyles = {
        root: {
            flex: 1,
        },
    };

    return (
        <UserViewContainer>
            <ConfirmationDialog
                isOpen={isConfirmDialogOpen}
                title={t('User_Delete_Title')}
                text={t('User_Delete_Prompt', {
                    name: userToDelete?.displayName ? userToDelete?.displayName : userToDelete?.email,
                })}
                confirmButtonText={t('Delete')}
                close={() => setIsConfirmDialogOpen(false)}
                confirmCallback={() => userToDelete && deleteUser(userToDelete)}
                isDangerousAction
            />
            <TopContainer>
                <Text variant={'xxLarge'}>{t('UserView_Headline')}</Text>
                <PrimaryButton
                    onClick={() => {
                        updatePanelPurpose({ isOpen: true, mode: 'CREATE' });
                    }}
                >
                    {t('UserView_Button_InviteUser')}
                </PrimaryButton>
            </TopContainer>
            <BottomContainer>
                <Stack styles={stackStyles} tokens={stackTokens}>
                    <Table
                        columns={columns}
                        data={usersQuery.data}
                        isBusy={isBusy || usersQuery.isFetching}
                        currentPage={usersQuery.page}
                        availablePages={usersQuery.maxPages}
                        navigateToEnd={() => usersQuery.toEnd()}
                        navigateToStart={() => usersQuery.toStart()}
                        navigateBackward={() => usersQuery.previous()}
                        navigateForward={() => usersQuery.next()}
                    />
                </Stack>
            </BottomContainer>
        </UserViewContainer>
    );
};
