import { FunctionComponent, ReactElement } from 'react';
import { useStoreState } from '../store/Store';
import { useHistory } from 'react-router-dom';
import { RoleEnum } from '../types/RoleEnum';

interface IAuthenticationGateProps {
    children: ReactElement;
    roleRequirement: RoleEnum;
    redirectRoute: string;
}

/**
 * The child components can only be accessed if the logged in user has a certain role.
 *
 * @param {props} props Component requirements.
 * @returns {ReactElement | null} The child component or null if the required role is not met.
 */
export const AuthenticationGate: FunctionComponent<IAuthenticationGateProps> = (props): ReactElement | null => {
    /** The global state of logged in user. */
    const user = useStoreState((state) => state.UserModel.user);

    /** The history. */
    const history = useHistory();

    /** If user is undefined do not redirect and return null. */
    if (user === undefined) {
        return null;
    }

    /** Represents if the user is a user. */
    const isUser =
        user.userRoles && (user.userRoles[0].roleId === RoleEnum.LetterAdmin || user.userRoles[0].roleId === RoleEnum.Admin || user.userRoles[0].roleId === RoleEnum.User);

    /** Represents if the user is a global admin. */
    const isAdmin = user.userRoles && user.userRoles[0].roleId === RoleEnum.Admin;

    /** Represents if the user is a group admin. */
    const isLetterAdmin = user.userRoles && (user.userRoles[0].roleId === RoleEnum.LetterAdmin || user.userRoles[0].roleId === RoleEnum.Admin);

    /**
     * The redirect function.
     *
     * @returns {null} Kills the authentication gate return with a null return value.
     */
    const redirect = (): null => {
        history.push(props.redirectRoute);
        return null;
    };

    /**
     * The redirect function.
     *
     * @returns {null} Kills the authentication gate return with a null return value.
     */
    const notInvitedRedirect = (): null => {
        history.push('/notAuthorized');
        return null;
    };

    switch (props.roleRequirement) {
        case RoleEnum.Admin: {
            // Global Admin required
            return isAdmin ? props.children : isUser ? redirect() : notInvitedRedirect();
        }
        case RoleEnum.LetterAdmin: {
            // Must be Letter-Admin (or higher)
            return isLetterAdmin ? props.children : isUser ? redirect() : notInvitedRedirect();
        }
        case RoleEnum.User: {
            // A user is by design global user
            return isUser ? props.children : notInvitedRedirect();
        }
        case RoleEnum.Undefined: {
            // Restriction is not defined.
            return props.children;
        }
        default: {
            // Restriction is unknown and cannot be met.
            console.warn(`Unknown role '${props.roleRequirement}' encountered.`);
            return notInvitedRedirect();
        }
    }
};
