import { _config } from '../index';
import { msalInstance } from '../App';
import { BlockBlobClient } from '@azure/storage-blob';

/**
 * Custom error class for HTTP errors.
 */
export class HttpError extends Error {
    constructor(method: string, url: string, relativeUrl: string, status: number, message: string) {
        super(message);
        let errorMessage = message;
        let displayMessage = message;
        this.status = status;
        // check if message is an object
        if (typeof message === 'object') {
            const typedMessage = message as ErrorResponse;
            // Check if message is of type https://tools.ietf.org/html/rfc7231#section-6.5.1
            if (typedMessage != null) {
                errorMessage = typedMessage.title + JSON.stringify(typedMessage.errors);
                displayMessage = typedMessage.title;
            } else {
                // stringify the unknown object
                errorMessage = JSON.stringify(message);
                displayMessage = JSON.stringify(message);
            }
            this.message = errorMessage;
        }
        console.error(`(${status})${displayMessage ? ' "' + displayMessage + '" ' : ' '}${method} ${relativeUrl} [${url}]`);
    }
    status = -1;
}
/**
 * Error message type for https://tools.ietf.org/html/rfc7231#section-6.5.1
 */
interface ErrorResponse {
    /** The response json type. */
    type: string;
    /** The error title. */
    title: string;
    /** The http error code. */
    status: number;
    /** The error trace id. */
    traceId: string;
    /** The nested error details. */
    errors: { [key: string]: string[] };
}

/**
 * Retrieves data from the API as a JSON object.
 *
 * @template T
 * @param {string} relativePath The relative path to the endpoint to query.
 * @param {AbortSignal} abortSignal An abort signal to cancel the request.
 * @param {boolean} anonymous Whether the request shall be anonymous, therefore without a bearer token.
 * @returns {Promise<T>} The result as an object.
 */
export const getFromApi = async <T>(relativePath: string, abortSignal?: AbortSignal, anonymous?: boolean): Promise<T> => {
    const response = await fetchWrapper(relativePath, 'GET', undefined, abortSignal, anonymous);
    return response.json() as Promise<T>;
};

/**
 * PUT request to the backend API.
 *
 * @template T
 * @param {string} relativePath The relative path to the endpoint to query.
 * @param {any} body The object to put in the body of the request
 * @param {AbortSignal} abortSignal An abort signal to cancel the request.
 * @param {boolean} anonymous Whether the request shall be anonymous, therefore without a bearer token.
 * @returns {Promise<T>} The answer as an object.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const putToApi = async <T>(relativePath: string, body?: any, abortSignal?: AbortSignal, anonymous?: boolean): Promise<T> => {
    const response = await fetchWrapper(relativePath, 'PUT', JSON.stringify(body), abortSignal, anonymous);
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
        return response.json() as Promise<T>;
    }
    return {} as Promise<T>;
};

/**
 * Posts a request to the backend API.
 *
 * @template T
 * @param {string} relativePath The relative path to the endpoint to query.
 * @param {any} body The object to put in the body of the request
 * @param {AbortSignal} abortSignal An abort signal to cancel the request.
 * @param {boolean} anonymous Whether the request shall be anonymous, therefore without a bearer token.
 * @returns {Promise<T>} The answer as an object.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postToApi = async <T>(relativePath: string, body: any, abortSignal?: AbortSignal, anonymous?: boolean): Promise<T> => {
    const response = await fetchWrapper(relativePath, 'POST', JSON.stringify(body), abortSignal, anonymous);
    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json')) {
        return response.json() as Promise<T>;
    } else {
        return response.blob() as Promise<T>;
    }
};

/**
 * Deletes an object from the api.
 *
 * @template T
 * @param {string} relativePath The relative path to the endpoint to query.
 * @param {any} body The object to put in the body of the request
 * @param {AbortSignal} abortSignal An abort signal to cancel the request.
 * @returns {Promise<T>} Returns the result if any.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteFromApi = async (relativePath: string, body?: any, abortSignal?: AbortSignal): Promise<void> => {
    return fetchWrapper(relativePath, 'DELETE', JSON.stringify(body), abortSignal);
};

/**
 * Fetches the API with required default headers.
 *
 * @param {string} relativeUrl The relative path to the endpoint to query.
 * @param {string} method The HTTP verb to use.
 * @param {any} body The object to put in the body of the request.
 * @param {AbortSignal} abortSignal An abort signal to cancel the request.
 * @param {boolean} anonymous Whether the request shall be anonymous, therefore without a bearer token.
 * @returns {Promise<any>} Returns the result if any.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchWrapper = async (relativeUrl: string, method: string, body?: any, abortSignal?: AbortSignal, anonymous?: boolean): Promise<any> => {
    const options = await _config;
    const apiConfig = options.apiOptions;
    const headers = new Headers();
    headers.append('Time-Offset', `${-new Date().getTimezoneOffset()}`);
    headers.append('Access-Control-Allow-Origin', '*');
    const scopes = anonymous ? null : apiConfig.scope ? [apiConfig.scope] : null;
    if (scopes != null) {
        let account = msalInstance.getActiveAccount();
        if (!account) {
            const allAccounts = msalInstance.getAllAccounts();
            if (allAccounts.length > 0) {
                msalInstance.setActiveAccount(allAccounts[0]);
            }
            account = msalInstance.getActiveAccount();
        }
        if (account) {
            const token = await msalInstance.acquireTokenSilent({ scopes: scopes, account: account });
            headers.append('Authorization', `Bearer ${token.accessToken}`);
        }
    }
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Accept-Language', 'de-de');
    headers.append('Culture', navigator.language);
    const response = await fetch(`${apiConfig.baseUrl}/${apiConfig.apiVersion}/${relativeUrl}`, {
        method,
        headers: headers,
        signal: abortSignal,
        body,
    });
    if (!response.ok) {
        const responseText = await response.text();
        let errorMessage = '';
        if (responseText) {
            errorMessage = JSON.parse(responseText);
        }
        throw new HttpError(method, response.url, relativeUrl, response.status, errorMessage);
    }
    return response;
};

/**
 * Download a file using a SAS Token.
 *
 * @param {File}sasToken The SAS Token to use for the download.
 * @param {string}fileName The target file name.
 * @returns {Promise<string>} The Object url to the fully downloaded file.
 */
export const downloadWithSasToken = async (sasToken: string, fileName: string): Promise<string> => {
    // Create the blob client using the given SAS Token.
    const blobClient = new BlockBlobClient(sasToken);
    // Download the file.
    const result = await blobClient.download();
    if (!result || !result.blobBody) {
        throw new Error('Not found in blob.');
    }
    return await result.blobBody.then((blob: Blob) => {
        return window.URL.createObjectURL(blob);
    });
};
