import { Action, action, thunk, Thunk } from 'easy-peasy';
import { postToApi } from '../../helper/ApiHelper';
import { CollectionTypeEnum } from '../../types/CollectionTypeEnum';
import { ILetter } from '../../types/ILetter';
import { ILetterPagedRequest } from '../../types/ILetterPagedRequest';
import { IRecipientPanelPurpose } from '../../types/IRecipientPanelPurpose';
import { IPaginationResult } from '../../types/IPaginationResult';
import { LetterReadStatusFilter } from '../../types/LetterReadStatusFilter';

/**
 * Interface for the letters model.
 */
export interface ILettersModel {
    /**
     * List of all letters.
     */
    letters: ILetter[];
    /**
     * Updates all letters of the state.
     */
    updateLetters: Action<ILettersModel, ILetter[]>;
    /**
     * Triggers a fetch and update of all letters.
     */
    fetchLetters: Thunk<ILettersModel, CollectionTypeEnum>;
    /**
     * Triggers a search and update of all letters.
     */
    searchLetters: Thunk<ILettersModel, void>;
    /**
     * The selected filter for the letter read status.
     */
    letterReadStatusFilter: LetterReadStatusFilter;
    /**
     * Update the selected filter for the letter read status.
     */
    updateLetterReadStatusFilter: Action<ILettersModel, LetterReadStatusFilter>;
    /**
     * The maximum available pages.
     */
    maxPages: number;
    /**
     * Updates the maximum available pages.
     */
    updateMaxPages: Action<ILettersModel, number>;
    /**
     * The current page of the letters table.
     */
    currentPage: number;
    /**
     * The Action to update the current page.
     */
    updateCurrentPage: Action<ILettersModel, number>;
    /**
     * The state representing if letters are being fetched.
     */
    isFetching: boolean;
    /**
     * The Action to update the state representing if letters are being fetched.
     */
    updateIsFetching: Action<ILettersModel, boolean>;
    /**
     * The searchString to use for fetching.
     */
    searchTerm: string | undefined;
    /**
     * Action to update the searchString to use for fetching.
     */
    updateSearchTerm: Action<ILettersModel, string | undefined>;
    /**
     * The state representing if recipient or group or assigned or collection letters are being shown.
     */
    showType: CollectionTypeEnum;
    /**
     * The Action to update the state representing if recipient or group or assigned or collection letters are being shown.
     */
    updateShowType: Action<ILettersModel, CollectionTypeEnum>;
    /**
     * The unique identifier of the selected user to represent.
     */
    selectedUserToRepresentId?: number;
    /**
     * Action to update the unique identifier of the selected user to represent.
     */
    updateSelectedUserToRepresentId: Action<ILettersModel, number | undefined>;
    /**
     * The state representing which groups letters are being shown.
     */
    groupToShow?: number | undefined;
    /**
     * The Action to update the state representing which groups letters are being shown.
     */
    updateGroupToShow: Action<ILettersModel, number | undefined>;
    /**
     * The purpose and state of the entities panel.
     */
    panelPurpose: IRecipientPanelPurpose;
    /**
     * Updates the panel purpose.
     */
    updatePanelPurpose: Action<ILettersModel, IRecipientPanelPurpose>;
    /**
     * The purpose and state of the group panel.
     */
    groupPanelPurpose: IRecipientPanelPurpose;
    /**
     * Updates the group panel purpose.
     */
    updateGroupPanelPurpose: Action<ILettersModel, IRecipientPanelPurpose>;
}

/**
 * Initial state of the letters model.
 */
export const LettersModel: ILettersModel = {
    letterReadStatusFilter: LetterReadStatusFilter.Undefined,
    updateLetterReadStatusFilter: action((state, payload) => {
        state.letterReadStatusFilter = payload;
    }),
    groupPanelPurpose: { isOpen: false },
    updateGroupPanelPurpose: action((state, payload) => {
        state.groupPanelPurpose = payload;
    }),
    panelPurpose: { isOpen: false },
    updatePanelPurpose: action((state, payload) => {
        state.panelPurpose = payload;
    }),
    showType: CollectionTypeEnum.AssignedToUser,
    updateShowType: action((state, payload) => {
        state.showType = payload;
    }),
    updateGroupToShow: action((state, payload) => {
        state.groupToShow = payload;
    }),
    selectedUserToRepresentId: undefined,
    updateSelectedUserToRepresentId: action((state, payload) => {
        state.selectedUserToRepresentId = payload;
    }),
    isFetching: false,
    updateIsFetching: action((state, payload) => {
        state.isFetching = payload;
    }),
    letters: [],
    updateLetters: action((state, payload) => {
        state.letters = payload;
    }),
    currentPage: 1,
    updateCurrentPage: action((state, payload) => {
        state.currentPage = payload;
    }),
    maxPages: 1,
    updateMaxPages: action((state, payload) => {
        state.maxPages = payload;
    }),
    searchTerm: '',
    updateSearchTerm: action((state, payload) => {
        state.searchTerm = payload;
    }),
    searchLetters: thunk(async (actions, payload, store) => {
        actions.updateIsFetching(true);
        const state = store.getState();
        const body: ILetterPagedRequest = {
            filterText: state.searchTerm,
            itemsPerPage: 20,
            pageToDeliver: state.currentPage,
            sortDirection: 1,
            sortPropertyName: 'Id',
            collectionFilter: { type: state.showType, groupId: state.groupToShow },
        };
        try {
            const searchResults = await postToApi<IPaginationResult<ILetter>>(`Letter/SearchPaged`, body);
            actions.updateMaxPages(searchResults.pagesAvailable);
            actions.updateLetters(searchResults.results);
            actions.updateIsFetching(false);
        } catch (error) {
            console.error(error);
        }
    }),
    fetchLetters: thunk(async (actions, payload, store) => {
        actions.updateIsFetching(true);
        const state = store.getState();
        if (payload === CollectionTypeEnum.AssignedToUser) {
            const body: ILetterPagedRequest = {
                filterText: '',
                itemsPerPage: 20,
                pageToDeliver: state.currentPage,
                sortDirection: 1,
                sortPropertyName: 'Id',
                collectionFilter: {
                    type: CollectionTypeEnum.AssignedToUser,
                    userToRepresentId: state.selectedUserToRepresentId,
                    letterReadStatusFilter: state.letterReadStatusFilter,
                },
            };
            try {
                const response = await postToApi<IPaginationResult<ILetter>>(`Letter/PagedExtended`, body);
                actions.updateMaxPages(response.pagesAvailable);
                actions.updateLetters(response.results);
                actions.updateCurrentPage(response.page);
            } catch (error) {
                console.error(error);
            }
        } else {
            if (payload === CollectionTypeEnum.AssignedToGroup && state.groupToShow !== undefined) {
                const body: ILetterPagedRequest = {
                    filterText: '',
                    itemsPerPage: 20,
                    pageToDeliver: state.currentPage,
                    sortDirection: 1,
                    sortPropertyName: 'Id',
                    collectionFilter: {
                        type: CollectionTypeEnum.AssignedToGroup,
                        groupId: state.groupToShow,
                        letterReadStatusFilter: state.letterReadStatusFilter,
                    },
                };
                try {
                    const response = await postToApi<IPaginationResult<ILetter>>(`Letter/PagedExtended`, body);
                    actions.updateMaxPages(response.pagesAvailable);
                    actions.updateLetters(response.results);
                    actions.updateCurrentPage(response.page);
                } catch (error) {
                    console.error(error);
                }
            } else {
                const body: ILetterPagedRequest = {
                    filterText: '',
                    itemsPerPage: 20,
                    pageToDeliver: state.currentPage,
                    sortDirection: 1,
                    sortPropertyName: 'Id',
                    collectionFilter: {
                        type: CollectionTypeEnum.AssignedToNone,
                        letterReadStatusFilter: state.letterReadStatusFilter,
                    },
                };
                try {
                    const response = await postToApi<IPaginationResult<ILetter>>(`Letter/PagedExtended`, body);
                    actions.updateMaxPages(response.pagesAvailable);
                    actions.updateLetters(response.results);
                    actions.updateCurrentPage(response.page);
                } catch (error) {
                    console.error(error);
                }
            }
        }
        actions.updateIsFetching(false);
    }),
};
