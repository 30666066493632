import { ITooltipHostStyles } from '@fluentui/react';

/**
 * Enables click through for tooltip host, otherwise the click event is consumed by the tooltip.
 */
export const toolTipClickThroughStyles: ITooltipHostStyles = {
    root: {
        ':active': {
            pointerEvents: 'none',
        },
    },
};
