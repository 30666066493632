import { IStackStyles, Text, IStackTokens, Stack, IColumn, IconButton, PrimaryButton, Panel, DefaultButton, MessageBarType } from '@fluentui/react';
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import UserSearchPanelBody from '../components/UserSearchPanelBody';
import { ConfirmationDialog } from '../dialogs/ConfirmationDialog';
import { deleteFromApi, postToApi } from '../helper/ApiHelper';
import { useStoreActions, useStoreState } from '../store/Store';
import { Table } from '../table/Table';
import { CollectionTypeEnum } from '../types/CollectionTypeEnum';
import { IUser } from '../types/IUser';
import { IUserRepresentation } from '../types/IUserRepresentation';

const UserRepresentationViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const TopContainer = styled.div`
    display: flex;
    padding: 22px 35px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const BottomContainer = styled.div`
    display: flex;
    flex: 1;
`;

/**
 * View to display all active user representations for the logged in user.
 *
 * @returns {ReactElement} The user representation view component.
 */
export const UserRepresentationView: FunctionComponent = (): ReactElement => {
    /** Access to translations. */
    const { t } = useTranslation();

    /** Whether the table is busy or not. */
    const [isBusy, setIsBusy] = useState<boolean>(false);
    /** Whether the user representation panel is open or not. */
    const [isCreatePanelOpen, setIsCreatePanelOpen] = useState<boolean>(false);
    /** The selected users. */
    const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
    /** Whether the dialog to confirm the representation deletion is open or not. */
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
    /** The currently selected item. */
    const [selectedItem, setSelectedItem] = useState<IUserRepresentation>();

    /** The user representations to display. */
    const userRepresentations = useStoreState((state) => state.UserModel.userRepresentations);
    /** Async fetch for the user representations connected to the logged in user. */
    const fetchUserRepresentations = useStoreActions((actions) => actions.UserModel.fetchUserRepresentations);
    /** The Action to update the global state representing if logged in user, group or collection letters are being shown. */
    const updateShowType = useStoreActions((actions) => actions.LettersModel.updateShowType);
    /** Action to update the unique identifier of the selected user to represent. */
    const updateSelectedUserToRepresentId = useStoreActions((actions) => actions.LettersModel.updateSelectedUserToRepresentId);
    /** Updates the global notification. */
    const updateNotification = useStoreActions((actions) => actions.GlobalNotificationModel.updateNotification);

    /**
     * Delete a single user representation in the backend.
     *
     * @param {number} id The unique identifier of the user representation.
     */
    const deleteUserRepresentation = async (id: number) => {
        try {
            setIsBusy(true);
            await deleteFromApi(`UserRepresentation/${id}`);
            await fetchUserRepresentations();
            updateSelectedUserToRepresentId(undefined);
            updateShowType(CollectionTypeEnum.AssignedToUser);
        } catch (error) {
            console.error(error);
        } finally {
            setIsBusy(false);
        }
    };

    /** Fetch all available representations for the logged in user. */
    useEffect(() => {
        const fetchRepresentations = async () => {
            try {
                setIsBusy(true);
                await fetchUserRepresentations();
            } catch (error) {
                console.error(error);
            } finally {
                setIsBusy(false);
            }
        };
        fetchRepresentations();
    }, [fetchUserRepresentations]);

    /** Basic stack layout tokens. */
    const stackTokens: IStackTokens = {
        childrenGap: 50,
    };

    /** The stack styles. */
    const stackStyles: IStackStyles = {
        root: {
            flex: 1,
        },
    };

    /** The table columns. */
    const columns: IColumn[] = [
        {
            key: 'actions',
            name: '',
            minWidth: 50,
            maxWidth: 75,
            columnActionsMode: 0,
            onRender: (item: IUserRepresentation) => (
                <>
                    <IconButton
                        iconProps={{ iconName: 'Delete' }}
                        onClick={() => {
                            setSelectedItem(item);
                            setIsConfirmDialogOpen(true);
                        }}
                    />
                </>
            ),
        },
        {
            key: 'representativeLabel',
            fieldName: 'representativeLabel',
            name: t('UserRepresentationsTable_ColumnHeadline_Representative'),
            minWidth: 150,
            maxWidth: 250,
            columnActionsMode: 0,
        },
        {
            key: 'userToRepresentLabel',
            fieldName: 'userToRepresentLabel',
            name: t('UserRepresentationsTable_ColumnHeadline_UserToRepresent'),
            minWidth: 150,
            maxWidth: 250,
            columnActionsMode: 0,
        },
    ];

    /**
     * Footer of the panel.
     *
     * @returns {Element} The footer element.
     */
    const onRenderFooter = () => (
        <Stack horizontal horizontalAlign={'end'} verticalAlign={'end'} tokens={{ childrenGap: 10, padding: '0 20px 20px 20px' }}>
            <DefaultButton
                text={t('UserRepresentationPanel_Footer_CancelButton')}
                onClick={() => {
                    setIsCreatePanelOpen(false);
                    setSelectedUsers([]);
                }}
            />
            <PrimaryButton
                text={t('UserRepresentationPanel_Footer_ConfirmButton')}
                disabled={selectedUsers.length <= 0}
                onClick={async () => {
                    try {
                        if (selectedUsers.length !== 1) {
                            return;
                        }
                        const representative = selectedUsers[0];
                        await postToApi('UserRepresentation', representative.id);
                        await fetchUserRepresentations();
                        updateNotification({ message: t('UserRepresentationPanel_Success'), type: MessageBarType.success });
                        setIsCreatePanelOpen(false);
                    } catch (error) {
                        console.error(error);
                        updateNotification({ message: t('UserRepresentationPanel_Failure'), type: MessageBarType.error });
                    } finally {
                        setSelectedUsers([]);
                    }
                }}
            />
        </Stack>
    );

    return (
        <UserRepresentationViewContainer>
            <Panel
                onDismiss={() => setIsCreatePanelOpen(false)}
                isOpen={isCreatePanelOpen}
                headerText={t('UserRepresentationPanel_Headline')}
                onRenderFooter={onRenderFooter}
                onRenderBody={() => <UserSearchPanelBody excludeSelf selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />}
                isFooterAtBottom
            />
            <ConfirmationDialog
                isOpen={isConfirmDialogOpen}
                close={() => {
                    setIsConfirmDialogOpen(false);
                    setSelectedItem(undefined);
                }}
                confirmButtonText={t('Delete')}
                text={t('UserRepresentationDelete_ConfirmText')}
                title={t('UserRepresentationDelete_ConfirmTitle')}
                confirmCallback={() => selectedItem && deleteUserRepresentation(selectedItem.id)}
                isDangerousAction
            />
            <TopContainer>
                <Text variant={'xxLarge'}>{t('UserRepresentationView_Headline')}</Text>
                <PrimaryButton onClick={() => setIsCreatePanelOpen(true)}>{t('UserRepresentationView_Button_NewUserRepresentation')}</PrimaryButton>
            </TopContainer>
            <BottomContainer>
                <Stack styles={stackStyles} tokens={stackTokens}>
                    <Table columns={columns} data={userRepresentations} isBusy={isBusy} />
                </Stack>
            </BottomContainer>
        </UserRepresentationViewContainer>
    );
};
