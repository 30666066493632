import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { StoreProvider } from 'easy-peasy';
import { Store } from './store/Store';
import { initializeIcons } from '@fluentui/react';
import App from './App';
import { IConfiguration } from './types/IConfiguration';

// Initialize the fluent ui icons.
initializeIcons();
// Get the root element.
const rootElement = document.getElementById('root');
// Fetch the options from the frontend server.
export const _config: Promise<IConfiguration> = fetch('api/Configuration').then((response) => response.json());
// Wrap the application with a store provider and render the application at the root element.
ReactDOM.render(
    <StoreProvider store={Store}>
        <App />
    </StoreProvider>,
    rootElement
);
// Register the service worker.
serviceWorkerRegistration.unregister();
