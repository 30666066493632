import React, { FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react';
import { CommandBar, CommandBarButton, FontIcon, ICommandBarItemProps, ICommandBarStyles, PrimaryButton, TooltipHost, mergeStyles } from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../store/Store';
import { darkThemeStorageKey } from '../App';
import styled from 'styled-components/macro';
import { SearchControl } from '../components/SearchControl';
import History from '../navigation/BrowserHistory';
import { formatToGermanDateWithTime } from '../helper/DateFormatHelper';
import { getFromApi } from '../helper/ApiHelper';
import { IImportRun } from '../types/IImportRun';
import { useQuery } from '@tanstack/react-query';

const CommandBarWrapper = styled.div`
    flex: 1;
`;
const Counter = styled.div`
    top: 4px;
    left: 23px;
    position: absolute;
    font-size: 10px;
`;

const UserRepresentationButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
`;

export interface ITopMenuProps {
    /** Whether to show the search control. */
    showSearch?: boolean;
}

/**
 * The top bar menu containing all related interactions.
 *
 * @param {ITopMenuProps} topMenuProps Component props.
 * @returns {ReactElement} Top menu.
 */
export const TopMenu: FunctionComponent<ITopMenuProps> = (topMenuProps: ITopMenuProps): ReactElement => {
    /**
     * Access to translations.
     */
    const { t } = useTranslation();

    /**
     * Msal Login
     */
    const msal = useMsal();

    /**
     * Command bar styles.
     */
    const commandBarStyles: ICommandBarStyles = {
        root: { flex: 1, padding: 0, backgroundColor: 'transparent', button: { backgroundColor: 'transparent' }, icon: { backgroundColor: 'transparent' } },
        primarySet: { justifyContent: 'center' },
    };

    /**
     * Whether the notification menu is open or not.
     */
    const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState<boolean>(false);

    /**
     * Whether the settings menu is open or not.
     */
    const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState<boolean>(false);

    /**
     * Changes the theme selection in the global store.
     */
    const updateIsDarkTheme = useStoreActions((actions) => actions.UserModel.updateIsDarkTheme);
    /**
     * The logged in user.
     */
    const user = useStoreState((state) => state.UserModel.user);

    /**
     * Whether the dark theme is selected.
     */
    const isDarkTheme = useStoreState((state) => state.UserModel.isDarkTheme);

    /**
     * Action to fetch the to be deleted letters.
     */
    const fetchToBeDeletedLettersLetters = useStoreActions((actions) => actions.ToBeDeletedLettersModel.fetchToBeDeletedLettersLetters);

    /**
     * Action to update the current selected navigation key.
     */
    const updateSelectedNavKey = useStoreActions((actions) => actions.NavigationModel.updateSelectedNavKey);

    /**
     * The Action to update the state representing if the ToBeDeletedLettersPanel is open
     */
    const updateIsTobDeletedOpen = useStoreActions((actions) => actions.ToBeDeletedLettersModel.updateIsTobDeletedOpen);

    /**
     * List of all letters to be deleted in the next 10 days.
     */
    const toBeDeletedLetters = useStoreState((state) => state.ToBeDeletedLettersModel.toBeDeletedLetters);

    /**
     * The user representations connected to the logged in user.
     */
    const userRepresentations = useStoreState((state) => state.UserModel.userRepresentations);

    /**
     * Loads the dark theme state on component load.
     */
    useEffect(() => {
        if (user) {
            fetchToBeDeletedLettersLetters();
        }
    }, [fetchToBeDeletedLettersLetters, user]);

    /**
     * Fetches the api for the last import run.
     *
     * @returns {Promise} The response from the api.
     */
    const fetchLastImport = async (): Promise<string> => {
        try {
            const response: IImportRun = await getFromApi('ImportRun');
            return formatToGermanDateWithTime(response?.lastTimeStamp);
        } catch (error) {
            console.error(error);
        }
        return '-';
    };

    const lastImportRunQuery = useQuery<string>(['LastImportRun'], fetchLastImport);

    /**
     * Loads the dark theme state on component load.
     */
    useEffect(() => {
        const storedDarkThemeState = localStorage.getItem(darkThemeStorageKey);
        if (storedDarkThemeState != null && storedDarkThemeState !== '') {
            const parsedDarkThemeState = storedDarkThemeState === 'true';
            updateIsDarkTheme(parsedDarkThemeState);
        } else {
            updateIsDarkTheme(window.matchMedia('(prefers-color-scheme: dark)').matches);
        }
    }, [updateIsDarkTheme]);

    /**
     * Callback for the click event listener.
     */
    const clickListener = useCallback(() => {
        if (isNotificationMenuOpen) {
            setIsNotificationMenuOpen(false);
        }
        if (isSettingsMenuOpen) {
            setIsSettingsMenuOpen(false);
        }
    }, [isSettingsMenuOpen, isNotificationMenuOpen]);

    /**
     * Add an event listener to close the user menu.
     */
    useEffect(() => {
        // Attach the listeners on component mount.
        document.addEventListener('click', clickListener);
        // Detach the listeners on component unmount.
        return () => {
            document.removeEventListener('click', clickListener);
        };
    }, [clickListener]);

    /**
     * Synchronous wrapper for the async logout method.
     */
    const logoutSync = () => {
        try {
            msal.instance.logout();
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * Menu bar items.
     */
    const commandBarItems: ICommandBarItemProps[] = [
        {
            key: 'searchBox',
            onRender: () => <SearchControl />,
        },
    ];

    /**
     * The styles for the import run info.
     */
    const importRunStyles = {
        height: '100%',
        width: '100%',
        padding: '9px',
        borderTop: '1px solid #dedede',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottom: 'none',
        display: 'flex',
    };

    /**
     * The styles for the info icon.
     */
    const infoIconClass = mergeStyles({
        fontSize: 20,
        paddingRight: 7,
        position: 'relative',
        top: '0px',
        color: 'rgb(34, 89, 137)',
    });

    /**
     * Menu bar items.
     */
    const farCommandBarItems: ICommandBarItemProps[] = [
        {
            key: 'representations',
            onRender: (e) =>
                userRepresentations &&
                userRepresentations.length > 0 && (
                    <UserRepresentationButtonWrapper>
                        <PrimaryButton
                            style={{ borderRadius: 4, padding: '0 35px' }}
                            text={t('UserRepresentation_IndikatorText')}
                            onClick={() => {
                                updateSelectedNavKey('representations');
                                History.push('/representations');
                            }}
                        />
                    </UserRepresentationButtonWrapper>
                ),
        },
        {
            key: 'notifications',
            iconProps: { iconName: 'Ringer' },
            text: t('Navigation_Item_Notifications'),
            iconOnly: true,
            buttonStyles: { menuIcon: {} },
            onClick: () => updateIsDarkTheme(!isDarkTheme),
            onRender: () => (
                <TooltipHost content={t('Navigation_Item_Notifications')}>
                    <CommandBarButton
                        className="ms-Button ms-Button--commandBar ms-Button--hasMenu ms-CommandBarItem-link root-144"
                        style={{ height: '100%' }}
                        role={'menuitem'}
                        iconProps={{ iconName: 'Ringer' }}
                        onClick={() => updateIsTobDeletedOpen(true)}
                    ></CommandBarButton>
                    <Counter onClick={() => updateIsTobDeletedOpen(true)}>
                        {toBeDeletedLetters.length >= 100 ? '99+' : toBeDeletedLetters.length > 0 ? toBeDeletedLetters.length : ''}
                    </Counter>
                </TooltipHost>
            ),
        },
        {
            key: 'themeChange',
            iconProps: { iconName: isDarkTheme ? 'Sunny' : 'ClearNight' },
            text: isDarkTheme ? t('Navigation_Item_ChangeToLightMode') : t('Navigation_Item_ChangeToDarkMode'),
            iconOnly: true,
            buttonStyles: { menuIcon: { display: 'none' } },
            onClick: () => updateIsDarkTheme(!isDarkTheme),
        },
        {
            key: 'settings',
            iconProps: { iconName: 'Settings' },
            text: t('TopMenu_Settings_Label'),
            iconOnly: true,
            buttonStyles: { menuIcon: { display: 'none' } },
            subMenuProps: {
                items: [
                    {
                        key: 'logout',
                        text: t('TopMenu_Settings_Logout'),
                        iconProps: { iconName: 'SignOut' },
                        onClick: logoutSync,
                    },
                    {
                        key: 'importRun',
                        iconProps: { iconName: 'Info' },
                        onRender: () => (
                            <div style={importRunStyles}>
                                <FontIcon aria-label="Info" className={infoIconClass} iconName="Info" />
                                {`${t('TopMenu_LastImportRun')} ${lastImportRunQuery.data ?? '-'} `}
                            </div>
                        ),
                    },
                ],
            },
        },
    ];

    const isHomeRoute = History.location.pathname.includes('home');

    return (
        <CommandBarWrapper>
            <CommandBar items={topMenuProps.showSearch && isHomeRoute ? commandBarItems : []} farItems={farCommandBarItems} styles={commandBarStyles} />
        </CommandBarWrapper>
    );
};
