/**
 * Format to german date.
 *
 * @param {Date | string | undefined} date The date to format.
 * @returns {string} The formatted date as string.
 */
export const formatToGermanDate = (date: Date | string | undefined): string => {
    if (!date) {
        return '';
    }
    if (typeof date === 'string') {
        date = new Date(date);
    }
    const day = date.getDate();
    const year = date.getFullYear();
    const month = date.getMonth();
    return `${('0' + day).slice(-2)}.${('0' + (month + 1)).slice(-2)}.${year}`;
};

/**
 * Format to german date with time.
 *
 * @param {Date | string | undefined} date The date to format.
 * @returns {string} The formatted date as string.
 */
export const formatToGermanDateWithTime = (date: Date | string | undefined): string => {
    if (!date) {
        return '';
    }
    if (typeof date === 'string') {
        date = new Date(date);
    }
    const day = date.getDate();
    const year = date.getFullYear();
    const month = date.getMonth();
    const time = date.toLocaleTimeString();
    return `${('0' + day).slice(-2)}.${('0' + (month + 1)).slice(-2)}.${year} ${time} `;
};
