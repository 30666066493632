import { createStore, createTypedHooks } from 'easy-peasy';
import { NavigationModel, INavigationModel } from './model/NavigationModel';
import { GlobalNotificationModel, IGlobalNotificationModel } from './model/GlobalNotificationModel';
import { UserModel, IUserModel } from './model/UserModel';
import { UsersModel, IUsersModel } from './model/UsersModel';
import { RolesModel, IRolesModel } from './model/RolesModel';
import { LetterViewerModel, ILetterViewerModel } from './model/LetterViewerModel';
import { LettersModel, ILettersModel } from './model/LettersModel';
import { GroupsModel, IGroupsModel } from './model/GroupsModel';
import { IToBeDeletedLettersModel, ToBeDeletedLettersModel } from './model/ToBeDeletedLettersModel';

/**
 * Interface for the store model.
 */
export interface IStoreModel {
    /**
     * The model for the navigation state.
     */
    NavigationModel: INavigationModel;
    /**
     * Global notification state.
     */
    GlobalNotificationModel: IGlobalNotificationModel;
    /**
     * The logged in user state.
     */
    UserModel: IUserModel;
    /**
     * Global users state.
     */
    UsersModel: IUsersModel;
    /**
     * Global roles state.
     */
    RolesModel: IRolesModel;
    /**
     * The Menu to display the letter as PDF file.
     */
    LetterViewerModel: ILetterViewerModel;
    /**
     *  Global letters state.
     */
    LettersModel: ILettersModel;
    /**
     *  Global groups state.
     */
    GroupsModel: IGroupsModel;
    /**
     *  Global letters to be deleted state.
     */
    ToBeDeletedLettersModel: IToBeDeletedLettersModel;
}

/**
 * Model that represents the store.
 */
const StoreModel: IStoreModel = {
    UsersModel,
    RolesModel,
    NavigationModel,
    GlobalNotificationModel,
    UserModel,
    LetterViewerModel,
    LettersModel,
    GroupsModel,
    ToBeDeletedLettersModel,
};

// generate typed hooks
const { useStoreActions, useStoreState, useStoreDispatch, useStore } = createTypedHooks<IStoreModel>();

// offer typed hooks for consumers
export { useStoreActions, useStoreState, useStoreDispatch, useStore };

/**
 * The store.
 */
export const Store = createStore(StoreModel);
