/**
 * Represents the seeded roles in the DB.
 * Note: The associated numbers map to the backend.
 */
export enum RoleEnum {
    Undefined = -1,
    User = 1,
    LetterAdmin = 2,
    Admin = 3,
}
