import { Action, action, Thunk, thunk } from 'easy-peasy';
import { getFromApi } from '../../helper/ApiHelper';
import { IUser } from '../../types/IUser';
import { IUserRepresentation } from '../../types/IUserRepresentation';

/**
 * Interface for the user model.
 */
export interface IUserModel {
    /**
     * Whether the user wants to see the dark theme.
     */
    isDarkTheme?: boolean;
    /**
     * Action to update the theme choice.
     */
    updateIsDarkTheme: Action<IUserModel, boolean>;
    /**
     * The currently logged in user.
     */
    user?: IUser;
    /**
     * Updates the user.
     */
    updateUser: Action<IUserModel, IUser>;
    /**
     * Triggers a fetch and update the current user.
     */
    fetchUser: Thunk<IUserModel, void>;
    /**
     * The user representations connected to the logged in user.
     */
    userRepresentations: IUserRepresentation[];
    /**
     * The user representations connected to the logged in user.
     */
    updateUserRepresentations: Action<IUserModel, IUserRepresentation[]>;
    /**
     * Async fetch for the user representations connected to the logged in user.
     */
    fetchUserRepresentations: Thunk<IUserModel>;
}

/**
 * Initial state of the user model.
 */
export const UserModel: IUserModel = {
    updateIsDarkTheme: action((state, payload) => {
        state.isDarkTheme = payload;
    }),
    updateUser: action((state, payload) => {
        state.user = payload;
    }),
    fetchUser: thunk(async (actions, payload, store) => {
        try {
            const object = await getFromApi<IUser>(`User/Me`);
            actions.updateUser(object);
        } catch (error) {
            console.error(error);
        }
    }),
    userRepresentations: [],
    updateUserRepresentations: action((state, payload) => {
        state.userRepresentations = payload;
    }),
    fetchUserRepresentations: thunk(async (actions) => {
        try {
            const result = await getFromApi<IUserRepresentation[]>('UserRepresentation');
            actions.updateUserRepresentations(result);
        } catch (error) {
            console.error(error);
        }
    }),
};
