import { Action, action, Thunk, thunk } from 'easy-peasy';
import { getFromApi } from '../../helper/ApiHelper';
import { IGroup } from '../../types/IGroup';
import { IPanelPurpose } from '../../types/IPanelPurpose';

/**
 * Interface for the groups model.
 */
export interface IGroupsModel {
    /**
     * List of all groups.
     */
    groups: IGroup[];
    /**
     * Updates all groups of the state.
     */
    updateGroups: Action<IGroupsModel, IGroup[]>;
    /**
     * Triggers a fetch and update of all groups.
     */
    fetchGroups: Thunk<IGroupsModel, string | undefined>;
    /**
     * The purpose and state of the groups panel.
     */
    panelPurpose: IPanelPurpose;
    /**
     * Updates the panel purpose.
     */
    updatePanelPurpose: Action<IGroupsModel, IPanelPurpose>;
}

/**
 * Initial state of the groups model.
 */
export const GroupsModel: IGroupsModel = {
    panelPurpose: { isOpen: false, mode: 'CREATE' },
    updatePanelPurpose: action((state, payload) => {
        state.panelPurpose = payload;
    }),
    groups: [],
    updateGroups: action((state, payload) => {
        state.groups = payload;
    }),
    fetchGroups: thunk(async (actions, payload, store) => {
        try {
            const result = await getFromApi<IGroup[]>(`Group`);
            actions.updateGroups(result);
        } catch (error) {
            console.error(error);
        }
    }),
};
