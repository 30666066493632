import i18next, { i18n, InitOptions } from 'i18next';
import I18NextHttpBackend, { BackendOptions, RequestCallback } from 'i18next-http-backend';
import { getBrowserShortLanguage } from './LocalizationHelper';

/**
 * Singleton instance.
 */
let instance: i18n | null = null;

/**
 * Loads the i18n requested languages from the localization api.
 *
 * @param {BackendOptions} _options ajax options.
 * @param {string} requestedLanguage The ISO-code for the language to load.
 * @param {string} _payload the requested language.
 * @param {RequestCallback} callback The callback to execute when the languages are returned from the api.
 * @returns {void} Returns nothing.
 */
const loadLocales = async (_options: BackendOptions, requestedLanguage: string, _payload: string, callback: RequestCallback): Promise<void> => {
    try {
        if (initOptions && initOptions.lng && requestedLanguage.toLowerCase() !== initOptions.lng.toLowerCase()) {
            // do nothing when a language is requested, that isn't the one the user selected
            callback(null, { status: 200, data: '' });
            return;
        }
        const localeResult = await fetch(`api/Localization/Get?culture=${initOptions.lng}`);
        const locale = await localeResult.json();
        callback(null, { status: 200, data: locale });
    } catch (error) {
        if (error instanceof Error) {
            console.error(`Failed language: ${requestedLanguage}. Error: ${error.message}`);
            callback(error, { status: 404, data: '' });
        }
    }
};

/**
 * The backend options regarding i18next.
 */
const backendOptions: BackendOptions = {
    request: loadLocales,
    loadPath: '{{lng}}',
    allowMultiLoading: false,
    crossDomain: false,
    withCredentials: false,
    requestOptions: {
        mode: 'cors',
        credentials: 'same-origin',
        cache: 'default',
    },
    reloadInterval: false,
};

/**
 * The initialization options for i18next.
 */
const initOptions: InitOptions = {
    lng: getBrowserShortLanguage(),
    fallbackLng: 'en',
    backend: backendOptions,
    cache: {
        enabled: true,
    },
};

/**
 * Initializes i18n.
 *
 * @returns {Promise<i18n>} The initialized i18n instance or null.
 */
export const initialize = async (): Promise<i18n> => {
    if (!instance) {
        await i18next.use(I18NextHttpBackend).init(initOptions);
        instance = i18next;
    }
    return instance;
};

export default instance;
