import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { IconButton, IIconProps } from '@fluentui/react';

const PaginationControlsContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`;

const PageNumberDisplayWrapper = styled.div`
    font-weight: bold;
    margin: 0 15px;
`;

/**
 * Interface for the properties of the pagination controls.
 */
export interface IPaginationControls {
    /**
     * Whether the table is busy or not.
     */
    isBusy: boolean;

    /**
     * The number of the current displayed page.
     */
    currentPage: number;

    /**
     * The maximum number of available pages.
     */
    availablePages: number;

    /**
     * Callback to navigate one page backward.
     */
    navigateBackward: () => void;

    /**
     * Callback to navigate one page forward.
     */
    navigateForward: () => void;

    /**
     * Callback to navigate to the first page.
     */
    navigateToStart: () => void;

    /**
     * Callback to navigate to the last page.
     */
    navigateToEnd: () => void;
}

/**
 * Builds interactive pagination controls.
 *
 * @param {IPaginationControls} props Pagination properties.
 * @returns {ReactElement} Pagination control.
 */
export const PaginationControls: FunctionComponent<IPaginationControls> = (props: IPaginationControls): ReactElement => {
    /**
     * Icon for the navigate back button.
     */
    const backIcon: IIconProps = {
        iconName: 'ChevronLeftMed',
    };

    /**
     * Icon for the navigate to start button.
     */
    const toStartIcon: IIconProps = {
        iconName: 'DoubleChevronLeft12',
    };

    /**
     * Icon for the navigate forward button.
     */
    const forwardIcon: IIconProps = {
        iconName: 'ChevronRightMed',
    };

    /**
     * Icon for the navigate to end button.
     */
    const toEndIcon: IIconProps = {
        iconName: 'DoubleChevronRight12',
    };

    return (
        <PaginationControlsContainer>
            <IconButton disabled={props.currentPage <= 1 || props.isBusy} iconProps={toStartIcon} onClick={props.navigateToStart} />
            <IconButton disabled={props.currentPage <= 1 || props.isBusy} iconProps={backIcon} onClick={props.navigateBackward} />
            <PageNumberDisplayWrapper>
                {props.currentPage} / {props.availablePages}
            </PageNumberDisplayWrapper>
            <IconButton disabled={props.currentPage >= props.availablePages || props.isBusy} iconProps={forwardIcon} onClick={props.navigateForward} />
            <IconButton disabled={props.currentPage >= props.availablePages || props.isBusy} iconProps={toEndIcon} onClick={props.navigateToEnd} />
        </PaginationControlsContainer>
    );
};
