import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import {
    IButtonStyles,
    IColumn,
    IconButton,
    IIconProps,
    IStackStyles,
    IStackTokens,
    MessageBarType,
    PrimaryButton,
    Stack,
    Text,
    IDetailsRowProps,
    TooltipHost,
    useTheme,
    Icon,
} from '@fluentui/react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Table } from '../table/Table';
import { useStoreActions, useStoreState } from '../store/Store';
import { IGroup } from '../types/IGroup';
import { deleteFromApi } from '../helper/ApiHelper';
import { ConfirmationDialog } from '../dialogs/ConfirmationDialog';
import { CollectionTypeEnum } from '../types/CollectionTypeEnum';

/** Styled Components */
const GroupsViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const TopContainer = styled.div`
    display: flex;
    padding: 22px 35px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const BottomContainer = styled.div`
    display: flex;
    flex: 1;
`;

/**
 * View component for the manage groups view.
 *
 * @returns {ReactElement} The Groups view.
 */
export const GroupsView: FunctionComponent = (): ReactElement => {
    /** Access to translations. */
    const { t } = useTranslation();
    /** Access to fluent ui theme. */
    const theme = useTheme();
    /** Whether this component is busy or not.*/
    const [isBusy, setIsBusy] = useState<boolean>(false);
    /** Whether the dialog to confirm the group deletion is open or not. */
    const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] = useState<boolean>(false);
    /** The currently selected group. */
    const [selectedGroup, setSelectedGroup] = useState<IGroup>();
    /** Global state of users. */
    const groups = useStoreState<IGroup[]>((state) => state.GroupsModel.groups);
    /** Fetches groups in the global state. */
    const fetchGroups = useStoreActions((actions) => actions.GroupsModel.fetchGroups);
    /** Fetches the currently signed in user. */
    const fetchUser = useStoreActions((actions) => actions.UserModel.fetchUser);
    /** Store action to update the global notification message bar. */
    const updateNotification = useStoreActions((actions) => actions.GlobalNotificationModel.updateNotification);
    /** Global state representing which groups letters are being shown. */
    const groupToShow = useStoreState((state) => state.LettersModel.groupToShow);
    /** The Action to update the global state representing which groups letters are being shown. */
    const updateGroupToShow = useStoreActions((actions) => actions.LettersModel.updateGroupToShow);
    /** The Action to update the global state representing if logged in user, group or collection letters are being shown. */
    const updateShowType = useStoreActions((actions) => actions.LettersModel.updateShowType);

    /**
     * Deletes one group by it's unique identifier.
     *
     * @param {number} id The unique identifier of the group to delete.
     */
    const deleteGroup = async (id?: number) => {
        if (id == null) {
            return;
        }
        try {
            setIsBusy(true);
            await deleteFromApi(`Group/${id}`);
            if (id === groupToShow) {
                updateGroupToShow(undefined);
                updateShowType(CollectionTypeEnum.AssignedToUser);
            }
            await fetchGroups();
            await fetchUser();
            updateNotification({ message: t('DeleteGroup_Success'), type: MessageBarType.success });
        } catch (error) {
            console.error(error);
            updateNotification({ message: t('DeleteGroup_Failure'), type: MessageBarType.error });
        } finally {
            setIsBusy(false);
        }
    };

    /** Updates the global users panel purpose. */
    const updatePanelPurpose = useStoreActions((actions) => actions.GroupsModel.updatePanelPurpose);

    /** Fetch the requested users on load. */
    useEffect(() => {
        fetchGroups();
    }, [fetchGroups]);

    /** Basic stack layout tokens. */
    const stackTokens: IStackTokens = {
        childrenGap: 50,
    };

    /** The styles of the Stack. */
    const stackStyles: IStackStyles = {
        root: {
            flex: 1,
        },
    };

    /**
     * Styles of the icon button.
     *
     * @param {boolean} isLarge Whether the text is large or not.
     * @returns {IButtonStyles} The styles for a fluent ui button.
     */
    const iconButtonStyles = (isLarge?: boolean): IButtonStyles => ({
        root: {
            marginLeft: isLarge ? 3 : 7,
            fontSize: isLarge ? 22 : 'unset',
        },
        icon: {
            color: theme.palette.black,
        },
        rootDisabled: {
            background: 'none',
        },
    });

    /**
     * Handles the row click in the manage groups table and opens the Panel in READ mode.
     *
     * @param {IGroup} [item] - The group item that was clicked. If no item is provided, the function will return early.
     */
    const onRowClick = (item?: IGroup) => {
        if (item == null) {
            return;
        }
        setSelectedGroup(item);
        updatePanelPurpose({ isOpen: true, mode: 'READ', entityId: item.id });
    };

    /**
     * Custom row render to render rows with cursor pointer.
     *
     * @param {IDetailsRowProps} props The original row props.
     * @param {(props?: IDetailsRowProps) => ReactElement | null} defaultRender The default renderer.
     * @returns {ReactElement | null} The rendered row or null.
     */
    const onRenderGroupRow = (props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => ReactElement | null): ReactElement | null => {
        if (defaultRender == null) {
            return null;
        }
        const isSelectedItem = defaultRender === props?.item.id;
        if (props) {
            props.styles = {
                root: {
                    cursor: 'pointer',
                    outline: isSelectedItem ? '2px solid !important' : undefined,
                    outlineColor: theme.semanticColors.focusBorder + ' !important',
                    outlineOffset: '-2px',
                    ':focus': {
                        '::after': {
                            border: 'none !important',
                            outline: 'none !important',
                        },
                    },
                    minHeight: 55,
                },
            };
        }
        return <div onClick={() => onRowClick(props?.item)}>{defaultRender(props)}</div>;
    };

    /** Props of the icon to edit a group.*/
    const editGroupIconProps: IIconProps = {
        iconName: 'Edit',
    };

    /*** Props of the icon to delete a group.*/
    const deleteGroupIconProps: IIconProps = {
        iconName: 'Delete',
    };

    /** Columns of the table. */
    const columns: IColumn[] = [
        {
            key: 'spacing',
            name: '',
            minWidth: 1,
            maxWidth: 1,
            columnActionsMode: 0,
        },
        {
            key: 'actions',
            fieldName: 'actions',
            name: '',
            minWidth: 75,
            maxWidth: 100,
            columnActionsMode: 0,
            onRender: (item: IGroup) => (
                <>
                    <TooltipHost content={t('GroupView_editGroup_Tooltip')} id={'editGroupTooltip'}>
                        <IconButton
                            styles={iconButtonStyles()}
                            iconProps={editGroupIconProps}
                            ariaDescription="editGroupTooltip"
                            onClick={(e) => {
                                e.stopPropagation();
                                updatePanelPurpose({ isOpen: true, mode: 'UPDATE', entityId: item.id });
                            }}
                        />
                    </TooltipHost>
                    <TooltipHost content={t('GroupView_deleteGroup_Tooltip')} id={'deleteGroupTooltip'}>
                        <IconButton
                            styles={iconButtonStyles()}
                            iconProps={deleteGroupIconProps}
                            ariaDescription="deleteGroupTooltip"
                            onClick={(e) => {
                                e.stopPropagation();
                                setSelectedGroup(item);
                                setIsDeleteConfirmDialogOpen(true);
                            }}
                        />
                    </TooltipHost>
                </>
            ),
        },
        {
            key: 'label',
            fieldName: 'label',
            name: t('GroupsTable_ColumnHeadline_Label'),
            minWidth: 150,
            maxWidth: 250,
            columnActionsMode: 0,
            onRender: (item: IGroup) => item.label,
        },
        {
            key: 'predictabilityRate',
            fieldName: 'PredictabilityRate',
            name: t('GroupsTable_ColumnHeadline_PredictabilityRate'),
            minWidth: 150,
            maxWidth: 250,
            columnActionsMode: 0,
            onRender: (item: IGroup) => {
                if (item.predictabilityRate == null) {
                    return '-';
                }
                return `${Math.trunc(item.predictabilityRate * 100).toString()}%`;
            },
        },
        {
            key: 'predictabilityThreshold',
            fieldName: 'PredictabilityThreshold',
            name: t('GroupsTable_ColumnHeadline_PredictabilityThreshold'),
            minWidth: 150,
            maxWidth: 250,
            columnActionsMode: 0,
            onRender: (item: IGroup) => {
                if (item.predictabilityThreshold == null) {
                    return '-';
                }
                return `${(item.predictabilityThreshold * 100).toFixed()}%`;
            },
        },
        {
            key: 'isAssigned',
            fieldName: 'IsAssigned',
            name: t('GroupsTable_ColumnHeadline_Is_Assigned'),
            minWidth: 150,
            maxWidth: 250,
            columnActionsMode: 0,
            onRender: (item: IGroup) => {
                if (item.predictabilityThreshold == null || item.predictabilityRate == null) {
                    return <Icon iconName="Cancel" />;
                }
                if (item.predictabilityRate >= item.predictabilityThreshold) {
                    return <Icon iconName="CheckMark" />;
                }
                return <Icon iconName="Cancel" />;
            },
        },
    ];

    return (
        <GroupsViewContainer>
            <ConfirmationDialog
                close={() => setIsDeleteConfirmDialogOpen(false)}
                isOpen={isDeleteConfirmDialogOpen}
                confirmButtonText={t('Delete')}
                text={t('ConfirmGroupDeletion_Text')}
                title={t('ConfirmGroupDeletion_Title')}
                confirmCallback={() => deleteGroup(selectedGroup?.id)}
                isDangerousAction
            />
            <TopContainer>
                <Text variant={'xxLarge'}>{t('GroupsView_Headline')}</Text>
                <PrimaryButton onClick={() => updatePanelPurpose({ isOpen: true, mode: 'CREATE' })}>{t('GroupsView_Button_CreateGroup')}</PrimaryButton>
            </TopContainer>
            <BottomContainer>
                <Stack styles={stackStyles} tokens={stackTokens}>
                    <Table columns={columns} data={groups} isBusy={isBusy} onRenderRow={onRenderGroupRow} />
                </Stack>
            </BottomContainer>
        </GroupsViewContainer>
    );
};
