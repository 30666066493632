import { LetterTypeEnum } from '../types/LetterTypeEnum';

/**
 * Format a letter type into the corresponding string.
 *
 * @param {LetterTypeEnum} letterState The letter type value.
 * @param {Function} t The function to access translations using keys.
 * @returns {string} The string representation of the letter type.
 */
export const formatLetterTypeToText = (letterState: LetterTypeEnum, t: (key: string) => string): string => {
    switch (letterState) {
        case LetterTypeEnum.Generic:
            return t('LettersTable_LetterType_Generic');
        case LetterTypeEnum.Invoice:
            return t('LettersTable_LetterType_Invoice');
        case LetterTypeEnum.FineDocument:
            return t('LettersTable_LetterType_FineDocument');
        default:
            return t('LettersTable_LetterType_Unknown');
    }
};
