import { DefaultButton, IButtonStyles, Icon, IIconProps, IIconStyles, ISearchBoxStyles, SearchBox, Stack, useTheme } from '@fluentui/react';
import React, { FunctionComponent, ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BusySpinnerOverlay } from '../busyIndicators/BusySpinnerOverlay';
import usePagedEntity from '../hooks/usePagedEntity';
import { useStoreState } from '../store/Store';
import { PaginationControls } from '../table/PaginationControls';
import { IUser } from '../types/IUser';
import { RoleEnum } from '../types/RoleEnum';

const Container = styled.div`
    display: flex;
    flex: 1;
    padding: 0 10px;
`;

const Wrapper = styled.div`
    width: 100%;
`;

const UserListContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
`;

const StyledUserCard = styled.div`
    position: relative;
    display: flex;
    max-width: 260px;
    flex-direction: column;
    margin: 5px;
    padding: 5px;
    justify-content: space-evenly;
`;

const StyledUserCardText = styled.div`
    font-size: 11px;
    line-height: 15px;
`;

interface IUserSearchPanelBody {
    /** The currently selected users. */
    selectedUsers: IUser[];
    /** Callback to update the currently selected users. */
    setSelectedUsers: (users: IUser[]) => void;
    /** Whether to offer the logged in user as option or not. */
    excludeSelf?: boolean;
}

/**
 * The panel body component to search and select users.
 *
 * @param {IUserSearchPanelBody} props The component properties.
 * @returns {ReactElement} The panel body to search select users.
 */
const UserSearchPanelBody: FunctionComponent<IUserSearchPanelBody> = (props): ReactElement => {
    /** Access to the global theme. */
    const theme = useTheme();
    /** Access to translations. */
    const { t } = useTranslation();

    /** The search term state. */
    const [searchTerm, setSearchTerm] = useState<string>();

    /** Global state representing which groups letters are being shown. */
    const groupToShow = useStoreState((state) => state.LettersModel.groupToShow);
    /** Global state of the logged in user. */
    const user = useStoreState((state) => state.UserModel.user);
    /** State if fetching of letters is in progress. */
    const isFetchingLetters = useStoreState((actions) => actions.LettersModel.isFetching);

    /** Query for users. */
    const usersQuery = usePagedEntity<IUser>({
        entityName: 'User',
    });
    /** Query for users in the selected group. */
    const usersForGroupQuery = usePagedEntity<IUser>({
        entityName: 'User',
        relativePagedEndpointPath: groupToShow ? `User/PagedForGroup/${groupToShow}` : undefined,
    });

    /** Use the correct query. */
    const usersToDisplay = groupToShow ? usersForGroupQuery : usersQuery;

    /** The busy state of this component. */
    const isBusy = useMemo(() => isFetchingLetters || usersToDisplay.isFetching, [usersToDisplay.isFetching, isFetchingLetters]);
    /** True if the logged in user is an admin. */
    const isAdmin = useMemo(() => (user?.userRoles ? user.userRoles[0].roleId === RoleEnum.Admin : false), [user]);

    /**
     * Handle what happens when the user selects a user.
     *
     * @param {IUser} user The user that is clicked.
     * @param {boolean} isChosen Whether the user is already linked to the letter or not.
     */
    const handleUserSelection = (user: IUser, isChosen: boolean) => {
        const tmpUsers = [...props.selectedUsers];
        if (!isChosen) {
            tmpUsers.push(user);
        } else {
            const index = tmpUsers.findIndex((lc) => lc && lc.id === user.id);
            tmpUsers.splice(index, 1);
        }
        if (tmpUsers.length > 1) {
            // Prevent the selection of more than one recipient.
            return;
        }
        props.setSelectedUsers([...tmpUsers]);
    };

    /**
     * Search for users.
     */
    const searchUser = () => {
        if (!isAdmin && groupToShow) {
            usersForGroupQuery.updateSearchValue(searchTerm ?? '');
        } else {
            usersQuery.updateSearchValue(searchTerm ?? '');
        }
    };

    /**
     * Button styles for the list elements.
     */
    const listElementUserStyles: IButtonStyles = {
        root: {
            padding: 0,
            maxWidth: 120,
            maxHeight: 240,
            fontSize: 12,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
        },
        icon: {
            color: theme.palette.themePrimary,
            fontSize: 15,
            margin: 0,
        },
        flexContainer: {
            maxWidth: 240,
            maxHeight: 120,
            justifyContent: 'center',
            flexDirection: 'column',
        },
        textContainer: {
            maxWidth: 240,
            maxHeight: 120,
        },
        label: {
            maxWidth: 240,
            maxHeight: 120,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    };

    /**
     * Button styles for the chosen list elements.
     */
    const chosenListElementStyles: IButtonStyles = {
        root: {
            padding: 0,
            maxWidth: 120,
            maxHeight: 240,
            fontSize: 12,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            borderStyle: 'solid',
            borderRadius: 4,
            borderWidth: 4,
            borderColor: theme.palette.themePrimary,
        },
        rootHovered: {
            padding: 0,
            maxWidth: 240,
            maxHeight: 120,
            fontSize: 12,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            borderStyle: 'solid',
            borderRadius: 4,
            borderWidth: 4,
            borderColor: theme.palette.themePrimary,
        },
        icon: {
            color: theme.palette.themePrimary,
            fontSize: 15,
            margin: 0,
        },
        flexContainer: {
            maxWidth: 240,
            maxHeight: 120,
            justifyContent: 'center',
            flexDirection: 'column',
        },
    };

    /**
     * Styles of the icon on editSlideUserMenu.
     */
    const activeUserStyles: IIconStyles = {
        root: {
            color: theme.palette.themeSecondary,
            position: 'absolute',
            top: 3,
            right: 3,
            borderRadius: 999999999,
            padding: 1,
            backgroundColor: theme.palette.white,
            zIndex: 10000,
        },
    };

    /**
     * Props of the icon for an active image on editSlideUserMenu.
     */
    const activeUserIconProps: IIconProps = {
        iconName: 'CheckMark',
    };

    /**
     * Render function of a user card.
     *
     * @param {IUser} user THe user to display.
     * @param {boolean} isChosen If the user should be displayed as active.
     * @returns {typeof(StyledUserCard)} A render for a single user.
     */
    const renderUser = (user: IUser, isChosen: boolean) => {
        return (
            <StyledUserCard key={`user-${user.id}`}>
                {isChosen && <Icon styles={activeUserStyles} iconName={activeUserIconProps.iconName} />}{' '}
                <DefaultButton
                    disabled={props.selectedUsers.length > 0 && !props.selectedUsers.some((u) => u.id === user.id)}
                    styles={isChosen ? chosenListElementStyles : listElementUserStyles}
                    label={user.displayName}
                    onClick={() => {
                        handleUserSelection(user, isChosen);
                    }}
                >
                    <StyledUserCardText>{user.displayName}</StyledUserCardText>
                </DefaultButton>
            </StyledUserCard>
        );
    };

    /**
     * Search box styles.
     */
    const searchStyles: ISearchBoxStyles = {
        root: {
            marginTop: 'auto',
            marginBottom: 'auto',
            marginLeft: 10,
            marginRight: 10,
            height: 36,
            borderRadius: 0,
        },
    };

    return (
        <Container>
            <Wrapper>
                <Stack>
                    <BusySpinnerOverlay isBusy={isBusy} />
                    <SearchBox
                        showIcon={true}
                        placeholder={t('SearchControl_Placeholder')}
                        onClear={() => {
                            setSearchTerm(undefined);
                            searchUser();
                        }}
                        onSearch={() => searchUser()}
                        inputMode={'text'}
                        onChange={(_, newValue) => {
                            setSearchTerm(newValue);
                            if (!newValue || newValue === '') {
                                usersToDisplay.updateSearchValue('');
                            }
                        }}
                        className="searchBox"
                        styles={searchStyles}
                        disabled={isBusy}
                    />
                    <UserListContainer>
                        {usersToDisplay.data
                            ?.filter((u) => (props.excludeSelf ? u.id !== user?.id : true))
                            .map((user: IUser) =>
                                user
                                    ? renderUser(
                                        user,
                                        props.selectedUsers?.some((slc) => slc && slc.id === user.id)
                                    )
                                    : null
                            )}
                    </UserListContainer>
                    {usersToDisplay.maxPages > 1 && (
                        <PaginationControls
                            currentPage={usersToDisplay.page}
                            availablePages={usersToDisplay.maxPages}
                            navigateToEnd={() => usersToDisplay.toEnd()}
                            navigateToStart={() => usersToDisplay.toStart()}
                            navigateBackward={() => usersToDisplay.previous()}
                            navigateForward={() => usersToDisplay.next()}
                            isBusy={usersToDisplay.isFetching}
                        />
                    )}
                </Stack>
            </Wrapper>
        </Container>
    );
};

export default UserSearchPanelBody;
