import React, { useEffect, FunctionComponent, MouseEvent, ReactElement, useMemo } from 'react';
import { IButtonStyles, IconButton, IIconProps, IIconStyles, INavLink, INavLinkGroup, INavStyles, ISeparatorStyles, Nav, Separator, useTheme } from '@fluentui/react';
import History from '../navigation/BrowserHistory';
import { useStoreState, useStoreActions } from '../store/Store';
import styled from 'styled-components/macro';
import { IThemed } from '../types/IThemed';
import { useTranslation } from 'react-i18next';
import { RoleEnum } from '../types/RoleEnum';
import { useMsal } from '@azure/msal-react';

const NavWrapper = styled.div<IThemed>`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.palette.themeSecondary};
    position: relative;
    padding-top: 20px;
`;

const SeparatorWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

/**
 * Interface for the properties of the navigation menu.
 */
export interface INavMenu {
    /**
     * The width of the navigation menu.
     */
    navMenuWidth?: number;
}

/**
 * The navigation menu component.
 *
 * @param {INavMenu} props Navigation menu properties.
 * @returns {ReactElement} Side navigation menu.
 */
export const NavMenu: FunctionComponent<INavMenu> = (props: INavMenu): ReactElement => {
    /**
     * The global fluent theme.
     */
    const theme = useTheme();

    /**
     * Access to translations.
     */
    const { t } = useTranslation();

    /**
     * Access to the msal instance.
     */
    const msal = useMsal();

    /**
     * Store state of the currently selected navigation key.
     */
    const selectedNavKey = useStoreState((state) => state.NavigationModel.selectedNavKey);

    /**
     * Store action to update the currently selected navigation key.
     */
    const updateSelectedNavKey = useStoreActions((actions) => actions.NavigationModel.updateSelectedNavKey);

    /**
     * Whether the nav menu is currently collapsed or not.
     */
    const isCollapsed = useStoreState((state) => state.NavigationModel.isCollapsed);

    /**
     * Action to update the current collapse state.
     */
    const updateIsCollapsed = useStoreActions((actions) => actions.NavigationModel.updateIsCollapsed);

    /**
     * The currently logged in user.
     */
    const user = useStoreState((state) => state.UserModel.user);

    /**
     * The key for the local storage collapsed state.
     */
    const collapsedStateStorageKey = 'isCollapsedState';

    /**
     * Loads the collapsed state on component load.
     */
    useEffect(() => {
        const storedCollapsedState = localStorage.getItem(collapsedStateStorageKey);
        if (storedCollapsedState != null && storedCollapsedState !== '') {
            const parsedCollapsedState = storedCollapsedState === 'true';
            updateIsCollapsed(parsedCollapsedState);
        }
    }, [updateIsCollapsed]);

    /**
     * Changes the local storage collapsed state on change.
     */
    useEffect(() => {
        if (isCollapsed != null) {
            localStorage.setItem(collapsedStateStorageKey, String(isCollapsed));
        }
    }, [isCollapsed]);

    /**
     * Updates the currently selected navigation key based on the URL path.
     */
    useEffect(() => {
        updateSelectedNavKey(History.location.pathname.substring(1));
    }, [selectedNavKey, updateSelectedNavKey]);

    /**
     * Styles of the navigation component.
     */
    const navStyles: Partial<INavStyles> = {
        root: {
            boxSizing: 'border-box',
            width: isCollapsed === false || isCollapsed == null ? 225 : 'unset',
            selectors: {
                '.ms-Button-flexContainer': {
                    display: 'flex',
                    justifyContent: isCollapsed ? 'center' : 'flex-start',
                    alignItems: 'center',
                    marginLeft: isCollapsed ? 0 : 3,
                },
            },
        },
        groupContent: {
            marginBottom: 0,
        },
        linkText: {
            color: theme.palette.white,
            display: isCollapsed ? 'none' : 'flex',
        },
        link: {
            padding: 5,
            borderLeftWidth: 0,
            selectors: {
                '&:hover': {
                    selectors: {
                        '.ms-Button-icon': {
                            color: `${theme.palette.white} !important`,
                        },
                    },
                },
                '&::after': {
                    borderLeftColor: theme.palette.white,
                    borderLeftWidth: 3,
                },
            },
        },
        compositeLink: {
            ':hover': {
                '.ms-Nav-link': {
                    backgroundColor: 'unset !important',
                },
            },
        },
        navItem: {
            selectors: {
                '.ms-Nav-compositeLink.is-selected': {
                    selectors: {
                        '.ms-Button': {
                            backgroundColor: 'unset !important',
                        },
                    },
                },
            },
        },
    };

    /**
     * Styles for the nav icons.
     */
    const navIconStyles: IIconStyles = useMemo(() => {
        return {
            root: {
                color: theme.palette.white,
            },
        };
    }, [theme.palette.white]);

    /**
     * Link groups of the top navigation component.
     */
    const navLinkGroupsTop: INavLinkGroup[] = [
        {
            links: [
                {
                    name: t('Navigation_Item_Home'),
                    url: '',
                    key: 'home',
                    target: '/home',
                    iconProps: {
                        iconName: 'Mail',
                        styles: navIconStyles,
                    },
                },
            ],
        },
    ];

    /**
     * Link groups of the bottom navigation component.
     */
    const navLinkGroupsBottom: INavLinkGroup[] = useMemo(() => {
        const links = [];        
        const isAdmin = user?.userRoles ? user.userRoles[0].roleId === RoleEnum.Admin : false;
        if (isAdmin) {
            links.push({
                name: t('Navigation_Users'),
                url: '',
                key: 'users',
                target: '/users',
                iconProps: {
                    iconName: 'Group',
                    styles: navIconStyles,
                },
            });
            links.push({
                name: t('Navigation_Groups'),
                url: '',
                key: 'groups',
                target: '/groups',
                iconProps: {
                    iconName: 'AddGroup',
                    styles: navIconStyles,
                },
            });
        }
        if (msal.instance.getActiveAccount()?.username.toLowerCase().endsWith('@roche.com')) {
            links.push({
                name: t('Navigation_UserRepresentations'),
                url: '',
                key: 'representations',
                target: '/representations',
                iconProps: {
                    iconName: 'TemporaryUser',
                    styles: navIconStyles,
                },
            });
        }
        return [
            {
                links: links,
            },
        ];
    }, [msal.instance, navIconStyles, t, user?.userRoles]);

    /**
     * Properties of the collapse icon.
     */
    const collapseIconProps: IIconProps = {
        iconName: isCollapsed ? 'DoubleChevronRight12' : 'DoubleChevronLeft12',
        styles: {
            root: {
                color: theme.palette.white,
            },
        },
    };

    /**
     * Styles for the nav collapse button.
     */
    const collapseButtonStyles: IButtonStyles = {
        root: {
            margin: isCollapsed ? 'auto 5px 12px 5px' : 'auto 12px 12px auto',
        },
        rootHovered: {
            backgroundColor: theme.palette.themeDarkAlt,
        },
        rootFocused: {
            backgroundColor: 'transparent',
        },
        rootPressed: {
            backgroundColor: theme.palette.themeDark,
        },
    };

    /**
     * Click handler for the nav menu links.
     *
     * @param {MouseEvent<HTMLElement>} _event Mouse click event.
     * @param {INavLink} link Clicked nav link.
     * @returns {void} Handled event.
     */
    const onLinkClick = (_event?: MouseEvent<HTMLElement>, link?: INavLink): void => {
        if (!link || !link.key || !link.target) {
            return;
        }
        updateSelectedNavKey(link.key);
        History.push(link.target);
    };

    /**
     * Styles of the separator line.
     */
    const separatorStyles: Partial<ISeparatorStyles> = {
        root: {
            width: isCollapsed ? '60%' : '90%',
        },
    };

    return (
        <NavWrapper palette={theme.palette}>
            <Nav onLinkClick={onLinkClick} selectedKey={selectedNavKey} styles={navStyles} groups={navLinkGroupsTop} />
            <SeparatorWrapper>
                <Separator styles={separatorStyles} />
            </SeparatorWrapper>
            <Nav onLinkClick={onLinkClick} selectedKey={selectedNavKey} styles={navStyles} groups={navLinkGroupsBottom} />
            <IconButton
                styles={collapseButtonStyles}
                onFocus={(e) => (e.target as HTMLButtonElement).blur()}
                iconProps={collapseIconProps}
                onClick={() => updateIsCollapsed(!isCollapsed)}
            />
        </NavWrapper>
    );
};
