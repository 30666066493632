import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import CSS from 'csstype';
import { downloadWithSasToken } from '../helper/ApiHelper';

const DocumentViewerContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    height: 100%;
`;
/**
 * Interface for the properties of the document viewer.
 */
interface IDocumentViewer extends IDocumentViewerStyles {
    /**
     * The label to use for buttons.
     */
    label?: string;
    /**
     * The file name to use for downloads.
     */
    fileName?: string;
    /**
     * The sasToken.
     */
    sasToken?: string;
}
/**
 * Interface for the style properties of the document viewer styles.
 */
export interface IDocumentViewerStyles {
    /**
     * The document styles.
     */
    styles?: CSS.Properties;
    /**
     * The document container styles.
     */
    containerStyles?: CSS.Properties;
}

/**
 * The document file viewer component.
 *
 * @param {IDocumentViewer} props The props for the document viewer.
 * @returns {DocumentViewerContainer} The document viewer.
 */
export const DocumentViewer: FunctionComponent<IDocumentViewer> = (props: IDocumentViewer) => {
    /** Whether the component is loading content or not. */
    const [isLoadingContent, setIsLoadingContent] = useState<boolean>(false);
    /** The file url to give into the individual view components. */
    const [fileUrl, setFileUrl] = useState<string>();
    /** The file name for which currently the file was loaded and temporarily stored in an object uri. */
    const [loadedFileName, setLoadedFileName] = useState<string>();

    /**
     * Initially get the file url.
     */
    useEffect(() => {
        const getWholeFileFromSasToken = async () => {
            try {
                setIsLoadingContent(true);
                // Create the blob client using the given SAS Token.
                if (props.sasToken && props.fileName !== loadedFileName) {
                    const file = await downloadWithSasToken(props.sasToken, 'LoadedFile');
                    setFileUrl(file);
                    setLoadedFileName(props.fileName);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoadingContent(false);
            }
        };
        if (!isLoadingContent) {
            getWholeFileFromSasToken();
        }
    }, [loadedFileName, props, props.sasToken, isLoadingContent]);

    return (
        <DocumentViewerContainer style={props.containerStyles}>
            <object data={fileUrl} title="PDF" type="application/pdf" width="100%" height="100%" style={props.styles}/>
        </DocumentViewerContainer>
    );
};
