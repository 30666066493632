import { SearchBox, ISearchBoxStyles, IButtonStyles, IconButton, useTheme } from '@fluentui/react';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../store/Store';

/**
 * Control for searching and specifying the type of search.
 *
 *  @returns {ReactElement} Search control.
 */
export const SearchControl = (): ReactElement => {
    /**
     * Access to translations.
     */
    const { t } = useTranslation();

    /**
     * Action to update the search string.
     */
    const updateSearchString = useStoreActions((actions) => actions.LettersModel.updateSearchTerm);

    /**
     * Action to update the current page.
     */
    const updateCurrentPage = useStoreActions((actions) => actions.LettersModel.updateCurrentPage);

    /**
     * Action to fetch the letters.
     */
    const fetchLetters = useStoreActions((actions) => actions.LettersModel.fetchLetters);

    /**
     *  Global state representing if assigned or collection letters are being shown.
     */
    const showType = useStoreState((state) => state.LettersModel.showType);

    /**
     * Global fluent UI theme.
     */
    const theme = useTheme();

    /** Whether this component is busy or not. */
    const [isBusy, setIsBusy] = useState<boolean>(false);

    /** The search term. */
    const [searchTerm, setSearchTerm] = useState<string>();

    /**
     * Search box styles.
     */
    const searchStyles: ISearchBoxStyles = {
        root: {
            marginTop: 'auto',
            marginBottom: 'auto',
            width: 260,
            height: 36,
        },
    };

    /**
     * Whether to show the search-type-dropdown and search button.
     */
    const showExtendedControls = searchTerm != null && searchTerm.length > 0;

    /**
     * Search button styles.
     */
    const searchButtonStyles: Partial<IButtonStyles> = {
        root: {
            marginTop: 'auto',
            marginBottom: 'auto',
            height: 36,
            borderStyle: 'solid',
            borderColor: theme.palette.neutralQuaternaryAlt,
            borderWidth: 1,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            width: 36,
            visibility: showExtendedControls ? 'unset' : 'hidden',
        },
        icon: {
            fontSize: 12,
        },
    };

    /**
     * Triggers a search with the given search term and type.
     *
     * @param {string} searchTerm The search query.
     */
    const search = async (searchTerm: string): Promise<void> => {
        if (!isSearchExecutable()) {
            onClear();
            return;
        }
        setIsBusy(true);
        try {
            updateCurrentPage(1);
            updateSearchString(searchTerm);
        } catch (error) {
            console.error(error);
        }
        setIsBusy(false);
    };

    /**
     * Handles the on clear event of the search box.
     */
    const onClear = () => {
        setSearchTerm(undefined);
        updateSearchString(undefined);
        updateCurrentPage(1);
        fetchLetters(showType);
    };

    /**
     * Checks whether the search term meets the minimum requirements and this component is not busy.
     *
     * @returns {boolean} Whether the search can be called.
     */
    const isSearchExecutable = (): boolean => {
        if (isBusy) {
            return false;
        }
        if (searchTerm == null || searchTerm.length < 3) {
            return false;
        }
        return true;
    };

    return (
        <>
            <SearchBox
                showIcon={true}
                placeholder={t('SearchControl_Placeholder')}
                onClear={onClear}
                onSearch={() => search(searchTerm ?? '')}
                inputMode={'text'}
                onChange={(_, newValue) => setSearchTerm(newValue)}
                className="searchBox"
                styles={searchStyles}
                disabled={isBusy}
            />
            <IconButton iconProps={{ iconName: 'Forward' }} onClick={() => searchTerm && search(searchTerm)} disabled={!isSearchExecutable()} styles={searchButtonStyles} />
        </>
    );
};
